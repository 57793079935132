import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const WORKCENTEREMPLOYER_URL = "WorkCenterEmployer";
export const GET_BY_SEARCH_URL = "GetBySearch";
export const GET_WORK_CENTER_EMPLOYEE_DOCUMENT_URL = "WorkCenterEmployer/get-work-center-employee-document-by-id";

export function createWorkCenterEmployer(data) {
  return axios.post(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL, data);
}

export function updateWorkCenterEmployer(data) {
  return axios.put(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL, data);
}

export async function listWorkCenterEmployers(data) {
  const response = await axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL, {
    params: data,
  });

  return response.data;
}

export function getWorkCenterEmployerById(id) {
  return axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/" + id);
}

export async function getMyWorkCenters() {
  const response = await axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/workcenters");

  return response.data;
}

export async function getEmployeeDocumentById(documentId) {
  const response = await axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/get-document-by-id", {
    params: { documentId },
    responseType: "blob",
  });

  return response.data;
}

export function getWorkCenterEmployerBySsn(searchTerm, contractApprovalId) {
  return axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/" + GET_BY_SEARCH_URL, {
    params: { searchTerm: searchTerm, contractApprovalId: contractApprovalId },
  });
}

export function getWorkCenterEmployeeDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_WORK_CENTER_EMPLOYEE_DOCUMENT_URL, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function inactiveWorkCenterEmployee(data) {
  return axios.put(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/inactive-work-center-employee", { id: data });
}

export async function listWorkCenterEmployersRENT(data) {
  const response = await axios.get(EXTERNAL_API_URL + WORKCENTEREMPLOYER_URL + "/ForRENT", {
    params: data,
  });

  return response.data;
}