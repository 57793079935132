import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import { getUnassignedWorkCenters } from "crud/rent.crud";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { ConditionGuard } from "guards/ConditionGuard";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import moment from "moment";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import GenericButton from "components/Buttons/GenericButton";
import { useDataset } from "hooks/useDataset";
import { useMemo, useState } from "react";
import { getKeyValueByCode } from "utils/datasets";
import { DATASET } from "utils/const";
import { useForm } from "react-hook-form";
import { addWorkCenterToMapRent } from "crud/rent.crud";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = [
  "Data de Registo",
  "Nome do Centro",
  "Província",
  "Munícipio",
  "Actividade Secundária",
  "Actividade Terciária",
];

export default function WorkCenterAddModal({ open, onClose }) {
  const { id } = useParams();
  const classes = useStylesGeneric();
  const queryClient = useQueryClient();

  const [addedIds, setAddedIds] = useState([]);

  const { handleSubmit, control, watch, reset } = useForm();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: ECONOMIC_ACTIVITIES_CLASSIFICATION } = useDataset({ name: DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION });

  const { data, isFetching } = useQuery(["get-unassigned-workcenters", id], () =>
    getUnassignedWorkCenters({
      id,
    })
  );

  const isEmpty = useMemo(() => !data?.data?.length, [data]);

  const onSubmit = async () => {
    // setValue("pageIndex", 1);
    // await refetch();
  };

  const { mutateAsync, isLoading } = useMutation(["add-workcenter-to-map-rent", id], addWorkCenterToMapRent, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-filtered-workcenters-snapshot", id]);

      onClose();
    },
  });

  async function handleAddWorkCenter() {
    await mutateAsync({
      id,
      workCenterIds: addedIds,
    });
  }

  function handleSelectItem(itemId) {
    if (addedIds.includes(itemId)) {
      setAddedIds(addedIds.filter((item) => item !== itemId));
    } else {
      setAddedIds([...addedIds, itemId]);
    }
  }

  function handleSelectAll(_, checked) {
    if (!checked) {
      setAddedIds([]);
    } else {
      setAddedIds(data?.data?.map((item) => item.id));
    }
  }

  const selectedAll = useMemo(() => addedIds.length === data?.data?.length, [addedIds, data]);

  return (
    <GenericDialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PageTitleHeader
          title="Adicionar Centro de Trabalho"
          style={{
            marginLeft: "-22px",
          }}
        />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gridGap={32}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormInputV2 name="name" label="Nome do Centro" placeholder="Insira o nome do centro" control={control} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormDropdown name="provinceId" label="Província" control={control} options={PROVINCES} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormDropdown
                name="municipalityId"
                label="Município"
                control={control}
                options={MUNICIPALITIES}
                disabled={!provinceId}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormDropdown
                name="secundaryActivityId"
                label="Actividade Secundária"
                control={control}
                options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormDropdown
                name="tertiaryActivityId"
                label="Actividade Secundária"
                control={control}
                options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
              />
            </Grid>

            <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
              <GenericButton type="button" color="ghost" onClick={() => reset()}>
                Limpar Filtros
              </GenericButton>
              <GenericButton color="primary">Pesquisar</GenericButton>
            </Grid>
          </Grid>

          <Box>
            <TableContainer>
              <ConditionGuard condition={!isFetching} fallback={<TableBackdrop open />}>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  <TableHead>
                    <TableRow>
                      {tableHead.map((prop, key) => (
                        <TableCell
                          key={key}
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                          }}
                        >
                          {prop}
                        </TableCell>
                      ))}
                      <TableCell
                        key="detailsCol"
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead +
                            " " +
                            classes.tableCellHeadStickyRight,
                        }}
                      >
                        <Tooltip title="Selecionar todos">
                          <FormControlLabel
                            style={{ margin: 0 }}
                            onChange={handleSelectAll}
                            control={<Checkbox color="primary" checked={selectedAll} />}
                            labelPlacement="start"
                            className={classes.formControlLabelNoMargin}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data?.map((prop, key) => (
                      <TableRow key={key}>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {moment(prop.created).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.province}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipality}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.secundaryActivity}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.tertiaryActivity}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                          <Box alignItems="center" justifyContent="center" display="flex">
                            <FormControlLabel
                              onChange={() => handleSelectItem(prop.id)}
                              control={<Checkbox checked={addedIds.includes(prop.id)} color="primary" />}
                              style={{ margin: 0 }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Box>
              </ConditionGuard>
            </TableContainer>

            {!isFetching && isEmpty && (
              <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                Não foram encontrados resultados para esta pesquisa.
              </Box>
            )}

            <Pagination
              className={classes.alignPagination}
              count={data?.totalPages}
              page={1}
              onChange={(_, value) => {
                // setValue("pageIndex", value);
                // refetch();
              }}
              color="primary"
            />
          </Box>
        </Box>
      </form>

      <Box display="flex" justifyContent="flex-end" gridGap={16} marginTop={4}>
        <GenericButton color="tertiary" onClick={onClose}>
          Cancelar
        </GenericButton>
        <ConditionGuard
          condition={!isEmpty}
          fallback={
            <GenericButton color="primary" disabled>
              Adicionar
            </GenericButton>
          }
        >
          <GenericButton onClick={handleAddWorkCenter} color="primary" loading={isLoading}>
            Adicionar
          </GenericButton>
        </ConditionGuard>
      </Box>
    </GenericDialog>
  );
}
