import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, FormGroup, Grid, Typography } from "@material-ui/core";
import { errorMessages } from "resources/resources";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { DATASET } from "utils/const";
import FormDatePicker from "components/Forms/FormDatePicker";
import { useDataset } from "hooks/useDataset";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID } from "utils/const";
import _ from "lodash";
import { ConditionGuard } from "guards/ConditionGuard";
import { getContractApprovalWorkCenterEmployeeById } from "crud/contractApprovalWorkCenterEmployee.crud";
import moment from "moment";
import PreviewDocument from "components/PreviewDocument";
import { getEmployeeDocumentById } from "crud/workCentersEmployer.crud";
import { deleteContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";
import { useDispatch, useSelector } from "react-redux";
import { DIALOG } from "utils/actionsConsts";
import { listContractApprovalWorkCenterEmployeeAction } from "redux/actions/contractApprovalWorkCenterEmployeeAction";
import { getKeyValueByCode } from "utils/datasets";
import { getContract } from "crud/contractApprovalWorkCenterEmployee.crud";
import DropzoneFieldV2 from "components/FormFields/DropzoneFieldV2";
import { updateContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";
import { jsonToFormData } from "utils/formData";
import useDownloadDocument from "hooks/useDownloadDocument";
import { getContractApprovalCertificate } from "crud/contractApprovalWorkCenterEmployee.crud";
import PreviewObservations from "components/PreviewObservations";
import { DOCUMENT_AND_IMAGE_TYPES, CONTRACT_APPROVAL_STATUS } from "utils/const";
import { CONTRACT_APPROVAL_EMPLOYEE_STATUS } from "utils/const";

const ContractApprovalWorkerDetailsDialog = ({
  openDialog,
  handleCloseDetailsDialog,
  workerId,
  isDraft,
  requestNumber,
  stateKeyValue,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const [{ state }, handleDownload] = useDownloadDocument(getContractApprovalCertificate);

  const { new_contract_approval } = useSelector((state) => state.contractApprovalState) || {};

  const methods = useForm({
    defaultValues: {},
    shouldFocusError: false,
  });

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = methods.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: MARITAL_STATUS } = useDataset({ name: DATASET.MARITAL_STATUS });
  const { data: GENDER } = useDataset({ name: DATASET.GENDER });
  const { data: ACADEMIC_LEVEL } = useDataset({ name: DATASET.ACADEMIC_LEVEL });
  const { data: NATIONALITIES } = useDataset({ name: DATASET.NATIONALITIES });
  const { data: CONTRACT_TYPE } = useDataset({ name: DATASET.CONTRACT_TYPE });
  const { data: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES } = useDataset({ name: DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES });
  const { data: COMPANY_ACTIVITY } = useDataset({ name: DATASET.COMPANY_ACTIVITY });

  const queryClient = useQueryClient();

  const { isLoading: isLoadingEmployeeInfo, data } = useQuery(
    ["getWorkCenterEmployerById"],
    () => getContractApprovalWorkCenterEmployeeById(workerId),
    {
      cacheTime: 0,
      onSuccess: ({ data }) => {
        methods.reset({
          ..._.omit(data),
          name: data?.workCenterEmployee?.name,
          ssn: data?.workCenterEmployee?.ssn,
          nif: data?.workCenterEmployee?.nif,
          address: data?.workCenterEmployee?.address,
          provinceId: data?.workCenterEmployee?.provinceId,
          municipalityId: data?.workCenterEmployee?.municipalityId,
          nationalityId: data?.workCenterEmployee?.nationalityId,
          dateOfBirth: moment(data?.workCenterEmployee?.dateOfBirth),
          genderId: data?.workCenterEmployee?.genderId,
          maritalStatusId: data?.workCenterEmployee?.maritalStatusId,
          academicLevelId: data?.workCenterEmployee?.academicLevelId,
          documentTypeId: data?.workCenterEmployee?.documentTypeId,
          identityDocumentNumber: data?.workCenterEmployee?.identityDocumentNumber,
          identityDocumentIssueDate: moment(data?.workCenterEmployee?.identityDocumentIssueDate),
          identityDocumentExpiryDate: moment(data?.workCenterEmployee?.identityDocumentExpiryDate),
          mainCompanyActivity: data?.workCenterEmployee?.mainCompanyActivity,
          contractType: data?.contractTypeId,
          contractStartDate: moment(data?.contractStartDate),
          contractEndDate: moment(data?.contractEndDate),
          baseSalary: data?.workCenterEmployee?.baseSalary,
          documents: [],
          workVisaNumber: data?.workCenterEmployee?.workVisaNumber,
        });
      },
    }
  );

  const removeWorker = async () => {
    if (!workerId) {
      throw new Error("O id do trabalhador não está definido.");
    }
    await removeWorkerAsync(workerId);
    await dispatch(listContractApprovalWorkCenterEmployeeAction(new_contract_approval?.id));
    handleCloseDetailsDialog();
  };

  const { mutateAsync: removeWorkerAsync } = useMutation({
    mutationFn: async () => {
      if (!workerId) {
        throw new Error("O id do trabalhador não está definido.");
      }
      return await deleteContractApprovalWorkCenterEmployee(workerId);
    },
    mutationKey: ["deleteContractApprovalWorkCenterEmployee"],
    onSuccess: async () => {
      await dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            buttonLabel: "Ok",
            message: "Trabalhador removido com sucesso.",
          },
        },
      });

      queryClient.invalidateQueries(["listEmployee", new_contract_approval?.id || requestNumber]);
    },
    onError: (error) => {
      console.error("Mutation failed", error);
    },
  });

  const handleEditSuccess = async () => {
    setIsEditing(false);
    await dispatch(listContractApprovalWorkCenterEmployeeAction(new_contract_approval?.id));
    handleCloseDetailsDialog();
    await queryClient.refetchQueries({
      queryKey: ["listEmployee", new_contract_approval?.id || requestNumber],
      exact: true,
    });

    await queryClient.refetchQueries({
      queryKey: ["contractApprovalDetails", new_contract_approval?.id || requestNumber],
      exact: true,
    });
  };

  const { mutateAsync: updateWorkerAsync } = useMutation({
    mutationFn: async () => {
      if (!workerId) {
        throw new Error("O id do trabalhador não está definido.");
      }
      const contractDetails = {
        contractTypeId: methods.getValues("contractType"),
        contractStartDate: moment(methods.getValues("contractStartDate")).toISOString(),
        contractEndDate: moment(methods.getValues("contractEndDate")).toISOString(),
        contractDocument: methods.getValues("workerContract") || null,
      };
      return await updateContractApprovalWorkCenterEmployee(
        jsonToFormData({
          Id: workerId,
          Worker: contractDetails,
          ContractApprovalRequestId: new_contract_approval?.id || requestNumber,
        })
      );
    },
    mutationKey: ["updateContractApprovalWorkCenterEmployee"],
    onSuccess: () => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            buttonLabel: "Ok",
            message:
              workerData?.contractApprovalWorkCenterEmployeeStatusKeyValue === CONTRACT_APPROVAL_EMPLOYEE_STATUS.DENIED
                ? "Correcções efectuadas com sucesso."
                : "Trabalhador editado com sucesso.",
            okAction: handleEditSuccess,
          },
        },
      });
    },
    onError: (error) => {
      console.error("Mutation failed", error);
    },
  });

  const workerData = data?.data;

  const workerContract = [
    {
      documentId: workerData?.id,
      documentName: "Contrato de Trabalho",
      documentTypeId: workerData?.contractTypeId,
    },
  ];

  const getDocumentName = (documentTypeId) => {
    switch (documentTypeId) {
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT:
        return "Passaporte";
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA:
        return "Visto";
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD:
        return "Cartão de Residente";
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.IDENTITY_CARD:
        return "Bilhete de Identidade";
      default:
        return "";
    }
  };

  const workerDocuments = workerData?.workCenterEmployee?.documents?.map((document) => ({
    documentId: document.documentId,
    documentName: getDocumentName(document.documentTypeId),
    documentTypeId: document.documentTypeId,
  }));

  useEffect(() => {
    if (
      workerData?.contractApprovalWorkCenterEmployeeStatusKeyValue === CONTRACT_APPROVAL_EMPLOYEE_STATUS.DENIED &&
      stateKeyValue !== CONTRACT_APPROVAL_STATUS.CONCLUDED
    ) {
      setIsEditing(true);
    }
  }, [workerData, stateKeyValue]);

  const contractType = methods.watch("contractType");

  const isIndefiniteContract = useMemo(() => {
    const contratoIndeterminadoKeyValue = getKeyValueByCode(CONTRACT_TYPE, contractType);
    return contratoIndeterminadoKeyValue === "TempoIndeterminado";
  }, [CONTRACT_TYPE, contractType]);

  useEffect(() => {
    if (isIndefiniteContract) {
      methods.setValue("contractEndDate", null);
    }
  }, [isIndefiniteContract, methods]);

  return (
    <FormProvider {...methods}>
      <form>
        <GenericDialog open={openDialog} onClose={handleCloseDetailsDialog} maxWidth="lg">
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <PageTitleHeader
              title="Dados do Trabalhador"
              style={{
                marginLeft: "-22px",
              }}
            />
            <ConditionGuard condition={isDraft}>
              <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? "Cancelar" : "Editar"}
              </GenericButton>
            </ConditionGuard>
          </Box>
          <ConditionGuard
            condition={!isLoadingEmployeeInfo}
            fallback={
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          >
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Pessoais
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="name"
                  label="Nome do trabalhador"
                  placeholder="Insira o nome do trabalhador"
                  required
                  control={methods.control}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="nif"
                  label="NIF"
                  placeholder="Insira o NIF"
                  required
                  control={methods.control}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="ssn"
                  label="NISS"
                  placeholder="Insira o NISS"
                  required
                  control={methods.control}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="address"
                  label="Morada"
                  placeholder="Insira a morada do trabalhador"
                  required
                  control={methods.control}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Província"
                  name="provinceId"
                  options={PROVINCES}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Município"
                  name="municipalityId"
                  options={MUNICIPALITIES}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  label="Nacionalidade"
                  control={methods.control}
                  name="nationalityId"
                  options={NATIONALITIES}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="dateOfBirth"
                  label="Data de nascimento"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Género"
                  placeholder="Seleccione o género"
                  control={methods.control}
                  name="genderId"
                  options={GENDER}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Estado civil"
                  placeholder="Seleccione o estado civil"
                  control={methods.control}
                  name="maritalStatusId"
                  options={MARITAL_STATUS}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Nível académico"
                  placeholder="Seleccione o nível académico"
                  control={methods.control}
                  name="academicLevelId"
                  options={ACADEMIC_LEVEL}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Documento de identificação"
                  placeholder="Seleccione o documento de identificação"
                  control={methods.control}
                  name="documentTypeId"
                  options={COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="identityDocumentNumber"
                  label="N.º do documento de identificação"
                  placeholder="Insira n.º de documento"
                  type="number"
                  control={methods.control}
                  disabled={true}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentIssueDate"
                  label="Data de emissão"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  endDatename="endDate"
                  isRequired={true}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentExpiryDate"
                  label="Data de validade"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={false}
                  minDateToday={true}
                  endDatename="endDate"
                  isRequired={true}
                  disabled={true}
                />
              </Grid>
              <ConditionGuard condition={workerData?.workCenterEmployee?.contractTypeId === 5}>
                <Grid item xs={12} md={3}>
                  <FormInputV2
                    name="workVisaNumber"
                    label="Nº Visto de Trabalho"
                    placeholder="Nº Visto de Trabalho"
                    required
                    control={methods.control}
                    disabled={true}
                  />
                </Grid>
              </ConditionGuard>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Informações sobre a actividade
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Actividade Principal da Empresa"
                  placeholder="Actividade Principal da Empresa"
                  control={methods.control}
                  name="mainCompanyActivity"
                  options={COMPANY_ACTIVITY}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Tipo de Contrato"
                  placeholder="Tipo de Contrato"
                  control={methods.control}
                  name="contractType"
                  options={CONTRACT_TYPE}
                  required
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="contractStartDate"
                  label="Data de início de contrato"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled={!isEditing}
                />
              </Grid>
              <ConditionGuard condition={!isIndefiniteContract}>
                <Grid item xs={12} md={3}>
                  <FormDatePicker
                    name="contractEndDate"
                    label="Data de fim de contrato"
                    control={methods.control}
                    rules={{ required: false }}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    trigger={methods.trigger}
                    maxDateToday={false}
                    minDateToday={true}
                    isRequired={!isIndefiniteContract}
                    disabled={isIndefiniteContract || !isEditing}
                  />
                </Grid>
              </ConditionGuard>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="baseSalary"
                  label="Salário expresso no contrato"
                  placeholder="Salário expresso no contrato"
                  required
                  control={methods.control}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Documentos
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "2rem",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <ConditionGuard condition={isEditing}>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <DropzoneFieldV2
                        name={"workerContract"}
                        inputLabel={"Substituir documento"}
                        label={"Contrato de trabalho"}
                        required={false}
                        accept={DOCUMENT_AND_IMAGE_TYPES}
                      />
                    </FormGroup>
                  </Grid>
                </ConditionGuard>
                <ConditionGuard condition={!isEditing}>
                  <PreviewDocument title="Contrato de Trabalho" documents={workerContract} promise={getContract} />
                </ConditionGuard>
                <ConditionGuard condition={workerDocuments?.length > 0}>
                  <PreviewDocument
                    title="Documento de Identificação"
                    documents={workerDocuments}
                    promise={getEmployeeDocumentById}
                  />
                </ConditionGuard>
              </Grid>
              <ConditionGuard condition={!isDraft}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{ color: "#002e9e", marginTop: "1rem" }}>
                    Observações
                  </Typography>
                </Grid>
                <PreviewObservations observations={workerData?.observations} />
              </ConditionGuard>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={handleCloseDetailsDialog}>
                  Fechar
                </GenericButton>
                <ConditionGuard condition={isDraft && !isEditing}>
                  <GenericButton typeSubmit={false} size="medium" color="tertiaryRed" onClick={removeWorker}>
                    Remover Trabalhador
                  </GenericButton>
                </ConditionGuard>
                <ConditionGuard condition={workerData?.contractApprovalWorkCenterEmployeeStatusID === 2}>
                  <GenericButton
                    size="medium"
                    color="primary"
                    onClick={methods.handleSubmit(() => handleDownload(workerData?.certificateNumber))}
                    loading={methods.formState.isSubmitting || state.get(workerData?.certificateNumber)}
                  >
                    Exportar Certificado
                  </GenericButton>
                </ConditionGuard>
                <ConditionGuard condition={isEditing}>
                  <GenericButton
                    size="medium"
                    color="primary"
                    onClick={methods.handleSubmit(updateWorkerAsync)}
                    loading={methods.formState.isSubmitting}
                  >
                    {workerData?.contractApprovalWorkCenterEmployeeStatusID === 3 ? "Guardar Correcções" : "Guardar"}
                  </GenericButton>
                </ConditionGuard>
              </Grid>
            </Grid>
          </ConditionGuard>
        </GenericDialog>
      </form>
    </FormProvider>
  );
};

export default ContractApprovalWorkerDetailsDialog;
