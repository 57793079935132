import GenericButton from "components/Buttons/GenericButton";
import StepContainer from "../step-container";
import FilterSection from "views/admin/UserArea/UserColective/WorkCentersEmployees/FilterSection";
import { connect } from "react-redux";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import ResultSection from "views/admin/UserArea/UserColective/WorkCentersEmployees/ResultSection";
import { Typography } from "@material-ui/core";
import { createWorkCenterEmployerAction } from "redux/actions/workCentersEmployerAction";
import { listWorkCenterEmployersAction } from "redux/actions/workCentersEmployerAction";
import { getWorkCenterEmployerByIdAction } from "redux/actions/workCentersEmployerAction";
import { updateWorkCenterEmployerAction } from "redux/actions/workCentersEmployerAction";
import React, { useEffect } from "react";
import WorkCenterEmployerDialog from "views/admin/UserArea/UserColective/WorkCentersEmployees/WorkCentersEmployeesModal";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

function EmployeesInfoTab(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedWorkCenterEmployer, setSelectedWorkCenterEmployer] = React.useState(null);
  const [isCreating, setIsCreating] = React.useState(false);

  const handleOpenDialog = (workCenterEmployer) => {
    setSelectedWorkCenterEmployer(workCenterEmployer);
    setIsCreating(isCreating);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWorkCenterEmployer(null);
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries("listWorkCenterEmployers");
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      ssn: "",
      startDate: null,
      endDate: null,
      workCenterId: "",
      professionalCategoryId: "",
      employmentStatusId: "",

      pageIndex: 1,
      pageSize: 15,
    },
  });

  return (
    <StepContainer
      title="Dados de Trabalhadores"
      action={
        <GenericButton onClick={() => handleOpenDialog()} color="tertiary" size="small">
          Criar Trabalhador
        </GenericButton>
      }
    >
      <FormProvider {...form}>
        <form id="form-3">
          <FilterSection FromRENT={true}/>

          <Typography
            style={{
              fontWeight: 600,
            }}
            component="p"
            variant="body2"
          >
            Na tabela em baixo estão listados todos os trabalhadores que integram os registos da área de
            utilizador e serão integrados no relatório RENT. <br />
            Poderá editar as informações entrando no detalhe de cada trabalhador. Após gravar ou adicionar o
            trabalhador, a lista de trabalhadores no menu utilizadores será actualizada.
          </Typography>

          <ResultSection handleOpenDialog={handleOpenDialog} />
        </form>
      </FormProvider>

      {openDialog && (
        <WorkCenterEmployerDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          isCreating={isCreating}
          selectedWorkCenterEmployer={selectedWorkCenterEmployer}
        />
      )}
    </StepContainer>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  createWorkCenterEmployer: (data) => dispatch(createWorkCenterEmployerAction(data)),
  getFilteredWorkCenterEmployer: (data) => dispatch(listWorkCenterEmployersAction(data)),
  getWorkCenterEmployerById: (id) => dispatch(getWorkCenterEmployerByIdAction(id)),
  updateWorkCenterEmployer: (data) => dispatch(updateWorkCenterEmployerAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesInfoTab);
