import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import PreviewData from "components/FormFields/PreviewData";
import DropzoneFieldV2 from "components/FormFields/DropzoneFieldV2";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getPaymentInfo } from "crud/rent.crud";
import { ConditionGuard } from "guards/ConditionGuard";
import CenterSpinner from "components/Spinners/CenterSpinner";
import { formatCurrency } from "utils/string_utils";
import { submitPayment } from "crud/rent.crud";
import { COMMON_MIME_TYPES } from "utils/const";
import { jsonToFormData } from "utils/formData";
import useMessage from "hooks/useMessage";

const PaymentModal = ({ handleClosePaymentModal, isOpenPaymentModal }) => {
  const queryClient = useQueryClient();

  const { showSuccessMessage } = useMessage();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();

  const { data, isFetching } = useQuery(["getRentPaymentInfo"], () => getPaymentInfo(id));

  const { mutateAsync } = useMutation(["submitPayment"], (params) => submitPayment(params), {
    onSuccess: () => {
      showSuccessMessage("Comprovativo de pagamento enviado com sucesso.", {
        okAction: () => {
          queryClient.invalidateQueries(["getMapRentById"]);

          handleClosePaymentModal();
        },
      });
    },
  });

  const onSubmit = async ({ documents }) => {
    await mutateAsync(jsonToFormData({ id, documents }));
  };

  return (
    <GenericDialog open={isOpenPaymentModal} onClose={handleClosePaymentModal} fullWidth maxWidth="md">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "-22px",
        }}
      >
        <PageTitleHeader title={"Informações de Pagamento"} />
      </Box>

      <Typography
        variant="body1"
        style={{
          marginBottom: "1rem",
        }}
      >
        Para emissão do certificado RENT terá de realizar e anexar o comprovativo.
      </Typography>

      <Typography
        variant="body1"
        style={{
          marginBottom: "1rem",
        }}
      >
        Caso pretenda realizar o pagamento mais tarde, poderá voltar a aceder a esta janela através dos detalhes do
        Mapa.
      </Typography>

      <ConditionGuard condition={!isFetching} fallback={<CenterSpinner />}>
        <form id="payment-modal-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Dimensão da Empresa" subtitle={`${data?.dimension} trabalhadores`} />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Referência para pagamento">
              {({ title }) => (
                <>
                  <Typography variant="subtitle1" style={{ fontWeight: 300, fontSize: "16px" }}>
                    {title}
                  </Typography>

                  <ul>
                    {data?.references?.map(({ name, reference }) => (
                      <li key={reference}>
                        {name}: <strong>{reference}</strong>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </PreviewData>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Valor do Pagamento" subtitle={formatCurrency(data?.paymentValue)} />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <DropzoneFieldV2
                label="Adicionar comprovativo de pagamento"
                name="documents"
                control={control}
                required
                multiple
                accept={COMMON_MIME_TYPES}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={handleClosePaymentModal}>
                Cancelar
              </GenericButton>
              <GenericButton loading={isSubmitting} typeSubmit size="medium" color="primary">
                Enviar
              </GenericButton>
            </Grid>
          </Grid>
        </form>
      </ConditionGuard>
    </GenericDialog>
  );
};

export default PaymentModal;
