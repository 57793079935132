import { makeStyles, Typography } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import StepContainer from "views/admin/RENT/Create/Steps/step-container";
import EmployeesTable from "./employee-table";
import { useModal } from "hooks/useModal";
import EmployeesTableFilter from "./employee-table-filter";
import { EmployeeModal } from "./Modal/employee-modal";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default function EmployeesInfoTab() {
  const form = useForm();

  const classes = useStyles();

  const { isOpen, modalData, openModal, closeModal } = useModal();

  return (
    <>
      <StepContainer
        title="Dados de Trabalhadores"
      >
        <FormProvider {...form}>
          <form classes={classes.form} id="form-2">
            <EmployeesTableFilter />

            <Typography
              style={{
                fontWeight: 600,
              }}
              component="p"
              variant="body2"
            >
              Na tabela em baixo estão listados todos os trabalhadores que foram submetidos na criação do relatório
              RENT. Poderá editar as informações entrando no detalhe de cada trabalhador. Após gravar ou adicionar o
              trabalhador, a lista de trabalhadores no menu utilizadores será actualizada.
            </Typography>

            <EmployeesTable handleOpenDetails={openModal} />
          </form>
        </FormProvider>
      </StepContainer>

      {isOpen && <EmployeeModal isOpen={isOpen} closeModal={closeModal} modalData={modalData} />}
    </>
  );
}
