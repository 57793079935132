import { useModal } from "hooks/useModal";
import { WorkCenterDialogDetails } from "./WorkCenterDialogDetails";
import { ConfirmationWorkCenterTable } from "../../Edit/Steps/Confirmation/confirmation-workcenter-table";

export default function WorkCentersList() {
  const modal = useModal();

  return (
    <>
      <ConfirmationWorkCenterTable detailsEnabled onOpenDetails={modal.openModal} />

      {modal.isOpen && <WorkCenterDialogDetails {...modal} />}
    </>
  );
}
