import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { useDataset } from "hooks/useDataset";
import { DATASET } from "utils/const";
import { getKeyValueByCode } from "utils/datasets";
import { getWorkCenterDetails } from "crud/rent.crud";

export const WorkCenterDialogDetails = ({ isOpen, closeModal, modalData }) => {
  const form = useForm();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });
  const { data: ECONOMIC_ACTIVITIES_CLASSIFICATION } = useDataset({ name: DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION });

  const provinceId = form.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const id = useMemo(() => modalData, [modalData]);

  useQuery(["rent", "getWorkCenterDetails", id], () => getWorkCenterDetails(id), {
    enabled: !!id,
    onSuccess: (data) => {
      form.reset(data);
    },
  });

  return (
    <FormProvider {...form}>
      <GenericDialog open={isOpen} onClose={closeModal} fullWidth={true} maxWidth="xl">
        <PageTitleHeader title="Detalhes do Centro de Trabalho" />

        <form id="workcenter-form">
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormInputV2
                name="name"
                label="Nome do centro"
                placeholder="Insira o nome do centro"
                required
                control={form.control}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputV2
                name="address"
                label="Morada"
                placeholder="Insira a morada do centro"
                required
                control={form.control}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                disabled
                name="provinceId"
                label="Província"
                required
                control={form.control}
                options={PROVINCES}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                name="municipalityId"
                label="Município"
                required
                control={form.control}
                options={MUNICIPALITIES}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInputV2
                name="postalCode"
                label="Caixa postal"
                required
                placeholder="Insira a caixa postal do centro"
                control={form.control}
                disabled
                rules={{
                  pattern: {
                    value: /^\d{5}$/,
                    message: "A caixa postal deve ter 5 dígitos",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInputV2
                name="phoneNumber"
                label="Telefone"
                placeholder="Insira o telefone do centro"
                numberType
                control={form.control}
                disabled
                rules={{
                  pattern: {
                    value: /^\d{9}$/,
                    message: "O telefone deve ter 9 dígitos",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputV2
                name="email"
                label="E-mail"
                placeholder="Insira o e-mail do centro"
                type="email"
                control={form.control}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PageTitleHeader title="Atividade" />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                name="secondaryActivityId"
                label="Actividade Secundária"
                required
                control={form.control}
                options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                name="tertiaryActivityId"
                label="Actividade Terciária"
                required
                control={form.control}
                options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInputV2
                name="numberOfWorkers"
                label="Nº de trabalhadores"
                placeholder="Insira o número de trabalhadores"
                control={form.control}
                type="number"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInputV2
                name="numberOfInstruments"
                label="Nº de instrumento"
                placeholder="Insira o número de instrumentos"
                control={form.control}
                type="number"
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={closeModal}>
                Fechar
              </GenericButton>
            </Grid>
          </Grid>
        </form>
      </GenericDialog>
    </FormProvider>
  );
};
