import React from "react";
import { connect, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

// @material-ui/core components
import PostAddIcon from "@material-ui/icons/PostAdd";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";

import { labels } from "resources/resources";
import ServicesBeforeLogin from "components/LandingPage/Components/ServicesBeforeLogin";
import ServicesAfterLogin from "components/LandingPage/Components/ServicesAfterLogin";
import { USER_TYPE } from "utils/const";
import { CONTRACT_APPROVAL } from "utils/actionsConsts";
import { CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE } from "utils/actionsConsts";

function Services(props) {
  const intl = useIntl();

  const dispatch = useDispatch();

  const clearContractApprovalState = () => {
    dispatch({
      type: CONTRACT_APPROVAL.CLEAR_CONTRACT_APPROVAL_REQUEST,
    });
    dispatch({
      type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CLEAR_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_REQUEST,
    });
  };

  const servicesList = [
    {
      userType: USER_TYPE.COLLECTIVE,
      title: intl.formatMessage(labels.Label_HomePage_Services_ImACompanyAndIWant),
      services: [
        {
          id: "add-job-offer",
          title: intl.formatMessage(labels.Label_HomePage_Services_Company_CreateJobAndInternshipOffers),
          icon: <PostAddIcon />,
          path: "/opportunities-create",
          userType: USER_TYPE.COLLECTIVE,
          hasToLogin: true,
          externalLink: false,
        },
        {
          id: "ratify-agreement",
          title: intl.formatMessage(labels.Label_HomePage_Services_Company_RatifyAgreement),
          icon: <DoneOutlineOutlinedIcon />,
          path: "/contract-approval/create",
          userType: USER_TYPE.COLLECTIVE,
          hasToLogin: true,
          externalLink: false,
          onClick: clearContractApprovalState,
        },
        {
          id: "licensing-centers",
          title: intl.formatMessage(labels.Label_HomePage_Services_Company_LicensingCenters),
          icon: <PeopleOutlineIcon />,
          path: "/information-training-centers-licensing",
          userType: USER_TYPE.COLLECTIVE,
          hasToLogin: false,
          externalLink: false,
        },
      ],
    },
    {
      userType: USER_TYPE.INDIVIDUAL,
      title: intl.formatMessage(labels.Label_HomePage_Services_ImACitizenAndIWant),
      services: [
        {
          id: "employment",
          title: intl.formatMessage(labels.Label_HomePage_Services_Citizen_SeeJobAndInternshipOffers),
          icon: <ListAltIcon />,
          path: "/available-opportunities",
          userType: USER_TYPE.INDIVIDUAL,
          hasToLogin: true,
          externalLink: false,
        },
        {
          id: "professionalFormation",
          title: intl.formatMessage(labels.Label_HomePage_Services_Citizen_SeeProfessionalFormation),
          icon: <CardMembershipIcon />,
          path: "/formacao-redirect",
          userType: USER_TYPE.INDIVIDUAL,
          hasToLogin: true,
          externalLink: false,
        },
        {
          id: "see-applications",
          title: intl.formatMessage(labels.Label_HomePage_Services_Citizen_SeeProfessionalWallet),
          icon: <WorkOutlineIcon />,
          path: "http://procarteira.inefop.gov.ao/",
          userType: USER_TYPE.INDIVIDUAL,
          hasToLogin: false,
          externalLink: true,
        },
      ],
    },
  ];

  return (
    <>
      {!props.isLoggedIn ? (
        <ServicesBeforeLogin
          servicesList={servicesList}
          clearContractApprovalState={clearContractApprovalState} 
        />
      ) : (
        <ServicesAfterLogin
          userType={props.userType}
          servicesList={servicesList}
          clearContractApprovalState={clearContractApprovalState} 
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(Services);
