import { useMemo } from "react";

export function ConditionGuard({ children, condition = true, fallback = null }) {
  const memoizedCondition = useMemo(() => condition, [condition]);

  if (fallback && !memoizedCondition) {
    return fallback;
  }

  return memoizedCondition ? children : null;
}
