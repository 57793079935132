import { Box, Grid } from "@material-ui/core";
import FormDropzoneField from "components/FormFields/FormDropzoneField";
import PreviewDocument from "components/PreviewDocument";
import { getEmployeeDocumentById } from "crud/workCentersEmployer.crud";
import { ConditionGuard } from "guards/ConditionGuard";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { DOCUMENT_AND_IMAGE_TYPES } from "utils/const";

export default function EmployeesAreaDocuments({
  requestFormDocuments,
  isEditing,
  isCreating,
  isRequired = true,
}) {
  const { control, watch } = useFormContext();

  const hasDocuments = useMemo(() => requestFormDocuments?.length > 0, [requestFormDocuments]);

  const documents = watch("documents");
  const appliedDocuments = useMemo(() => documents || [], [documents]);

  const documentFieldSize = useMemo(() => {
    const numDocs = requestFormDocuments?.length;

    if (12 % numDocs === 0) return 12 / numDocs;

    return 4;
  }, [requestFormDocuments]);

  return (
    <>
      <ConditionGuard condition={appliedDocuments.length && !isEditing}>
        <Box marginBottom={3}>
          <PreviewDocument title="Documentos Anexados" documents={appliedDocuments} promise={getEmployeeDocumentById} />
        </Box>
      </ConditionGuard>

      <ConditionGuard condition={hasDocuments && (isEditing || isCreating)}>
        <Grid container>
          {requestFormDocuments?.map(({ id, name, keyValue }) => (
            <Grid key={id} item xs={12} md={documentFieldSize}>
              <FormDropzoneField
                accept={DOCUMENT_AND_IMAGE_TYPES}
                control={control}
                label={name}
                name={keyValue}
                required={isRequired}
                inputLabel={isCreating ? "Adicionar documento" : "Substituir documento"}
              />
            </Grid>
          ))}
        </Grid>
      </ConditionGuard>
    </>
  );
}
