import React, { useMemo } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import useDownloadDocument from "hooks/useDownloadDocument";
import DocumentList from "./document-list";

const useStyle = makeStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",

    "& button": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  documents: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      gap: 8,
    },
  },
}));

export default function PreviewDocument({
  title = "Documentos",
  documents = [],
  flexDirection = "column",
  justifyContent = "center",
  promise,
  children,
  ...rest
}) {
  const classes = useStyle();

  const [{ state, error }, handleDownload] = useDownloadDocument(promise);

  const hasDocuments = useMemo(() => documents && documents.length, [documents]);

  function onClick(documentId, documentName) {
    handleDownload(documentId, documentName);
  }

  function onDownload(file) {
    const url = URL.createObjectURL(file);

    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <Box
      {...rest}
      display="flex"
      className={classes.root}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
    >
      <Typography variant="subtitle1" style={{ fontWeight: 300, fontSize: "16px" }}>
        {title}
      </Typography>

      <Box className={classes.documents} gridGap={16} display="flex" flexDirection="row" justifyContent="flex-start">
        {!children && (
          <DocumentList
            hasDocuments={hasDocuments}
            promise={promise}
            documents={documents}
            onClick={onClick}
            state={state}
            onDownload={onDownload}
            error={error}
          />
        )}

        {children && children({ documents, onClick, onDownload, state, error })}

        {!hasDocuments && (
          <Typography variant="body1" style={{ fontWeight: 600, fontSize: "14px" }}>
            Sem documentos
          </Typography>
        )}
      </Box>
    </Box>
  );
}
