import React, { useCallback, useEffect, useMemo, useState } from "react";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import LocationHeader from "components/Headers/LocationHeader";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import ContractApprovalWorkerDialog from "../components/ContractApprovalWorkerDialog";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { listContractApprovalWorkCenterEmployeeAction } from "redux/actions/contractApprovalWorkCenterEmployeeAction";
import { getWorkCenterEmployeeDocumentAction } from "redux/actions/workCentersEmployerAction";
import ContractApprovalWorkerDetailsDialog from "../components/ContractApprovalWorkerDetailsDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteContractApproval } from "crud/contractApproval.crud";
import { DIALOG } from "utils/actionsConsts";
import { ConditionGuard } from "guards/ConditionGuard";
import FormDropdown from "components/FormFields/FormDropdown";
import { useForm } from "react-hook-form";
import { useDataset } from "hooks/useDataset";
import { DATASET } from "utils/const";
import { listContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";
import ContractApprovalDetailsTable from "../components/ContractApprovalDetailsTable";
import { ContractApprovalRequestDetailsCard } from "../components/ContractApprovalRequestDetailsCard";
import { getKeyValueByCode } from "utils/datasets";
import { submitContractApproval } from "crud/contractApproval.crud";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ContractApprovalCreate = (props) => {
  const classes = useStylesGeneric();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);

  const { new_contract_approval } = useSelector((state) => state.contractApprovalState) || {};

  const { control, watch, getValues, handleSubmit, formState } = useForm();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);
  const queryClient = useQueryClient();

  const { data: EMPLOYMENT_CENTERS } = useDataset(
    { name: DATASET.EMPLOYMENT_CENTERS, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setShowSearchModal(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    queryClient.refetchQueries({
      queryKey: ["listEmployee", new_contract_approval?.id ?? ""],
      exact: true,
    });
  };

  const handleOpenDetailsDialog = (id) => {
    setSelectedId(id);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setSelectedId(null);
  };

  useEffect(() => {
    if (!new_contract_approval) {
      handleOpenDialog();
    }
  }, [new_contract_approval]);

  const dispatch = useDispatch();

  const fetchContractApprovalWorkersList = useCallback(() => {
    return listContractApprovalWorkCenterEmployee(new_contract_approval?.id ?? "");
  }, [new_contract_approval]);

  const { data: listEmployee, isLoading } = useQuery({
    queryKey: ["listEmployee", new_contract_approval?.id],
    queryFn: fetchContractApprovalWorkersList,
  });

  useEffect(() => {
    if (!!new_contract_approval) {
      fetchContractApprovalWorkersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_contract_approval]);

  const { mutateAsync: submitRequest } = useMutation(
    ["submitContractApprovalRequest"],
    async () =>
      submitContractApproval({
        id: new_contract_approval?.id,
        provinceId: getValues().provinceId,
        employmentCenterId: getValues().employmentCenterId,
      }),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: true,
              buttonLabel: "Ok",
              onClose: "/admin/contract-approval/list",
              message: "Pedido de homologação submetido com sucesso.",
            },
          },
        });
      },
      onError: (error) => {
        let errorMessage = "Ocurreu um erro ao submeter o pedido.";
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          errorMessage = Object.values(errors).flat().join(". ");
        }
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: errorMessage,
            },
          },
        });
      },
    }
  );

  const goBack = () => {
    props.history.push("/admin/contract-approval/list");
  };

  const redirect = true;

  const { mutateAsync: handleDeleteAsync } = useMutation(
    ["createWorkCenterEmployer"],
    async (id) => deleteContractApproval(id),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: redirect,
              buttonLabel: "Ok",
              onClose: redirect ? "/admin/contract-approval/list" : null,
              message: "Pedido eliminado com sucesso.",
            },
          },
        });
      },
      onError: (error) => {
        console.log(error);
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
            },
          },
        });
      },
    }
  );

  const deleteRequest = async () => {
    if (!new_contract_approval?.id) {
      throw new Error("O id do pedido não está definido.");
    }
    await handleDeleteAsync(new_contract_approval?.id);
  };
  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <Grid item xs={12} md={12}>
        <LocationHeader section="Homologação de contratos" />
      </Grid>
      <Grid item xs={12} md={9}>
        <PageTitleHeader title={"Criar Pedido de Homologação de contratos"} />
      </Grid>

      <ContractApprovalRequestDetailsCard contractApprovalRequest={new_contract_approval} isDraft={true} />

      <Grid item xs={12} md={12}>
        <Card className={classes.cardRoot}>
          <form onSubmit={handleSubmit(submitContractApproval)}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ color: "#002e9e", marginBottom: "20px" }}>
                  Centro de Emprego
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormDropdown
                  control={control}
                  label="Província Centro de Emprego"
                  name="provinceId"
                  options={PROVINCES}
                  mappingTo="code"
                  error={!!formState.errors.provinceId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormDropdown
                  control={control}
                  label="Centro de Emprego"
                  name="employmentCenterId"
                  options={EMPLOYMENT_CENTERS}
                  required
                  mappingTo="code"
                  disabled={false || !getValues("provinceId")}
                  error={!!formState.errors.provinceId}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <GenericButton
          typeSubmit={false}
          color="tertiary"
          size="large"
          onClick={() => handleOpenDialog()}
          classes={classes.TableCardHeaderButton}
        >
          Adicionar Trabalhador
        </GenericButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box
          classes={{ root: classes.tableResultBoxRoot }}
          sx={{
            mt: 3,
          }}
        >
          <ContractApprovalDetailsTable
            page={page}
            handleChangePage={handleChangePage}
            handleOpenDialog={handleOpenDetailsDialog}
            listEmployee={listEmployee}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h5">
          Para adicionar trabalhador ao pedido de homologação de contrato, deverá realizar o seu registo prévio no Meu
          Perfil na secção Trabalhadores. Para registar trabalhador{" "}
          <Link
            style={{
              textDecoration: "underline",
            }}
            to={{
              pathname: "/admin/user-profile",
              state: { tabType: "WorkCentersEmployees" },
            }}
          >
            clique aqui
          </Link>
          .
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <ConditionGuard condition={!!new_contract_approval}>
          <GenericButton size="medium" color="tertiaryRed" onClick={() => deleteRequest()}>
            {"Eliminar Pedido"}
          </GenericButton>
        </ConditionGuard>
        <GenericButton size="medium" color="tertiary" onClick={() => goBack()}>
          {"Voltar á Página Inicial"}
        </GenericButton>
        <ConditionGuard condition={!!new_contract_approval}>
          <GenericButton size="medium" color="primary" onClick={() => handleSubmit(submitRequest())}>
            {"Submeter Pedido"}
          </GenericButton>
        </ConditionGuard>
      </Grid>
      {openDialog && (
        <ContractApprovalWorkerDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          showSearchModal={showSearchModal}
        />
      )}

      {openDetailsDialog && (
        <ContractApprovalWorkerDetailsDialog
          openDialog={openDetailsDialog}
          handleCloseDetailsDialog={handleCloseDetailsDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          workerId={selectedId}
          isDraft={true}
          listWorkers={listEmployee}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  listContractApprovalWorkCenterEmployee: (id) => dispatch(listContractApprovalWorkCenterEmployeeAction(id)),
  getWorkCenterEmployeeDocument: (docId, docName) => dispatch(getWorkCenterEmployeeDocumentAction(docId, docName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractApprovalCreate);
