import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { ConditionGuard } from "guards/ConditionGuard";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { CardActions, IconButton, Tooltip } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { TABLE_STATE_CHIP_COLOR } from "utils/const";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ResultSection = () => {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const history = useHistory();

  const tableHead = ["Data de Submissão", "Nº do Mapa RENT", "Estado"];

  const { setValue, watch } = useFormContext();

  const page = watch("page");

  const { data, isLoading, refetch } = useQuery(["getFilteredMapRent"], {
    select: (data) => ({
      totalPages: data?.totalPages || 0,
      totalRecords: data?.totalCount || 0,
      values: data?.data || [],
    }),
  });

  const mapChipColors = (stateKey) => {
    switch (stateKey) {
      case "Approved":
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case "Rejected":
        return TABLE_STATE_CHIP_COLOR.RED;
      case "AwaitingPayment":
      case "PaymentToApprove":
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case "UnderReview":
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  // TODO: Implement handleOpenDetails in other US
  const handleOpenDetails = (id) => {
    history.push(`/admin/rent/details/${id}`);
  };

  function handleChangePage(_, value) {
    setValue("pageIndex", value);

    refetch();
  }

  return (
    <>
      <CardTableContainer title="Lista de Mapas RENT submetido" count={data?.totalRecords}>
        <TableContainer>
          <ConditionGuard condition={!isLoading} fallback={<TableBackdrop open />}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                  >
                    Detalhes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.values?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(prop.created).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <TableStateChip state={mapChipColors(prop.stateKeyValue)} message={prop.stateDescription} />
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title="Detalhes">
                        <IconButton onClick={() => handleOpenDetails(prop.name)}>
                          <Box
                            component={ArrowForwardIos}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </ConditionGuard>
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

        <Pagination
          className={classes.alignPagination}
          count={data?.totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </CardTableContainer>

      {!isLoading && data?.values?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ResultSection);
