import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import { setTargetPathAfterLoginAction } from "redux/actions/auth/loginAction";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/auth.js";
import NeedHelp from "components/LandingPage/Sections/NeedHelp";

const useStyles = makeStyles(componentStyles);

const Auth = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const mainContent = React.useRef(null);

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (
    props.authState.loggedIn !== undefined &&
    props.authState.loggedIn &&
    !props.authState.mustConfirmPhone
  ) {
    if (
      props.authState?.targetPathAfterLogin &&
      props.authState?.targetPathAfterLoginUserType ===
        props.authState?.userType
    ) {
       const targetPathAfterLogin = props.authState?.targetPathAfterLogin;

      props.setTargetPathAfterLogin(null, null);

      return <Redirect to={targetPathAfterLogin} />;
    } else {
      return <Redirect to={"/admin/home"} />;
    }
  } else {
    return (
      <>
        <div className="main-content" ref={mainContent}>
          <AuthNavbar />

          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/home" />
          </Switch>
        </div>
        <NeedHelp />
        <AuthFooter />
      </>
    );
  }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
