
import { useModal } from "hooks/useModal";
import { EmployeeDialogDetails } from "./EmployeeDialogDetails";
import ConfirmationEmployeesTable from "../../Edit/Steps/Confirmation/confirmation-employee-table";

export default function EmployeeList() {
  const modal = useModal();

  return (
    <>
      <ConfirmationEmployeesTable detailsEnabled onOpenDetails={modal.openModal} />

      {modal.isOpen && <EmployeeDialogDetails {...modal} />}
    </>
  );
}
