import { useQuery } from "@tanstack/react-query";
import { getDataSetDependentByName } from "crud/datasets.crud";
import { useMemo } from "react";

/**
 * Custom hook to fetch and cache a dependent dataset.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.name - The name of the dataset.
 * @param {Array} [params.parentKeyValue=[]] - The parent key values to filter the dataset.
 * @param {Object} options - The options for the query.
 * @returns {Object} The query result containing the dataset.
 */
export const useDependentDataset = ({ name, parentKeyValues = [] }, options) => {
  const queryKey = useMemo(() => ["dataset-dependent", name, ...parentKeyValues], [name, parentKeyValues]);

  return useQuery(
    queryKey,
    async () => {
      const response = await getDataSetDependentByName(name, parentKeyValues);

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7 * 24 * 60 * 60 * 1000, // Cache the data for 7 days
      select: (data) => {
        if (!data) return [];

        return data.map((item) => ({
          code: item.id,
          label: item.value,
          keyValue: item.keyValue,
        }));
      },
      ...options,
    }
  );
};
