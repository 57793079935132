import { makeStyles, Typography } from "@material-ui/core";
import GenericButton from "components/Buttons/GenericButton";
import { FormProvider, useForm } from "react-hook-form";
import StepContainer from "views/admin/RENT/Create/Steps/step-container";
import WorkCentersTable from "./work-centers-table";
import WorkCentersFilterTable from "./work-centers-table-filter";
import { useModal } from "hooks/useModal";
import WorkCenterDetailModal from "./Modal/work-center-detail-modal";
import WorkCenterAddModal from "./Modal/work-center-add-modal";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default function WorkCentersInfoTab() {
  const form = useForm();

  const classes = useStyles();

  const { isOpen, modalData, openModal, closeModal } = useModal();
  const { isOpen: addModalOpened, openModal: openAddModal, closeModal: closeAddModal } = useModal();

  return (
    <>
      <StepContainer
        title="Dados de Centros"
        action={
          <GenericButton color="tertiary" size="small" onClick={openAddModal}>
            Adicionar Centro
          </GenericButton>
        }
      >
        <FormProvider {...form}>
          <form classes={classes.form} id="form-2">
            <WorkCentersFilterTable />

            <Typography
              style={{
                fontWeight: 600,
              }}
              component="p"
              variant="body2"
            >
              Na tabela em baixo estão listados todos os centros de trabalho que foram submetidos na criação do
              relatório RENT. Poderá editar as informações entrando no detalhe de cada centro de trabalho. Após gravar
              ou adicionar o centro de trabalho, a lista de centros de trabalho no menu utilizadores será actualizada.
            </Typography>

            <WorkCentersTable handleOpenDetails={openModal} />
          </form>
        </FormProvider>
      </StepContainer>

      <WorkCenterDetailModal open={isOpen} data={modalData} onClose={closeModal} />

      {addModalOpened && <WorkCenterAddModal open={addModalOpened} onClose={closeAddModal} />}
    </>
  );
}
