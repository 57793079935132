import React from "react";

import { Box } from "@material-ui/core";
import GenericButton from "components/Buttons/GenericButton";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function NavigationButtons({ nextLabel = "Seguinte", handleBack, canGoBack }) {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Box gridGap={12} display="flex" alignItems="center" justifyContent="flex-end">
      <GenericButton component={Link} to="/admin/rent/list" id="back-button" color="tertiaryRed">
        Cancelar
      </GenericButton>
      <GenericButton id="back-button" disabled={!canGoBack || isSubmitting} onClick={handleBack} color="tertiary">
        Voltar
      </GenericButton>
      <GenericButton loading={isSubmitting} id="next-button" typeSubmit color="primary">
        {nextLabel}
      </GenericButton>
    </Box>
  );
}
