import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableStateChip from "components/Cards/Tables/TableStateChip";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { TABLE_STATE_CHIP_COLOR } from "utils/const";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { CONTRACT_APPROVAL_EMPLOYEE_STATUS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ContractApprovalDetailsTable({listEmployee, isLoading, handleOpenDialog}) {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const tableHead = [
    "Nome do Trabalhador",
    "Província",
    "Município",
    "Nacionalidade",
    "Tipo de Contrato",
    "Estado",
  ];

  const mapStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case CONTRACT_APPROVAL_EMPLOYEE_STATUS.APPROVED:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case CONTRACT_APPROVAL_EMPLOYEE_STATUS.CANCELED:
      case CONTRACT_APPROVAL_EMPLOYEE_STATUS.DENIED:
        return TABLE_STATE_CHIP_COLOR.RED;
      case CONTRACT_APPROVAL_EMPLOYEE_STATUS.AWAITING_APPROVAL:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      default:
        return <></>;
    }
  };

  return (
    <>
      {listEmployee?.data?.data?.length > 0 && (
        <CardTableContainer
        title={"Lista de trabalhadores para homologação de contratos de trabalho"}
        count={listEmployee?.data?.totalCount}
        >
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                  >
                    Detalhes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listEmployee?.data?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.provinceDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipalityDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.nationalityDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.contractTypeDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <TableStateChip
                        state={mapStateToTableChipColors(prop.statusKeyValue)}
                        message={prop.statusDescription}
                      />
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title="Detalhes">
                        <IconButton aria-label="details" onClick={() => handleOpenDialog(prop.id)}>
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
            <TableBackdrop open={isLoading} />
          </TableContainer>

          <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

          <Pagination
            className={classes.alignPagination}
            count={listEmployee?.totalPages}
            page={listEmployee.currentPage}
            onChange={listEmployee?.handleChangePage}
            color="primary"
          ></Pagination>
        </CardTableContainer>
      )}
      {listEmployee?.data?.data?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ContractApprovalDetailsTable);