import { Box, Typography } from "@material-ui/core";

export default function StepContainer({ children, title, action }) {
  return (
    <Box display="flex" flexDirection="column" gridGap={16} mt={6}>
      <Box display="flex" alignItems="center" gridGap={24}>
        <Typography variant="h1" color="primary">
          {title}
        </Typography>

        {action}
      </Box>

      {children}
    </Box>
  );
}
