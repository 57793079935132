import { React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GenericButton from "components/Buttons/GenericButton";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import { DATASET, PAGINATION } from "utils/const";
import notificationsMap from "mappers/notificationsMap";
import FormSelect from "components/FormFields/FormSelect";
import { getNotificationsAction } from "redux/actions/notificationsAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getDatasetStateByName } from "utils/datasets";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [filter, setFilter] = useState({
    search: "",
    origin: undefined,
  });

  useEffect(() => {
    if (props.datasetsState[DATASET.ORIGIN] === undefined || props.datasetsState[DATASET.ORIGIN] === [])
      props.getDataSetByName(DATASET.ORIGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const cleanFilter = () => {
    setValue("search", "");
    setValue("origin", undefined);


  };

  const onSubmit = (data) => {
    if (isValid) {
      let filter = notificationsMap(data);
      filter["lastXNotifications"] = 1000;
      filter["message"] = data.search;
      filter["originId"] = data.origin?.code ?? null;
      filter["onlyUnread"] = false;
      filter["pageIndex"] = 1;
      filter["pageSize"] = PAGINATION.PAGE_SIZE;
      setFilter(filter);
      if (props.page === 1) props.getNotifications(filter);
      else props.handleChangePage(1);
    }
  };

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.getNotifications(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          "Pesquisa de Notificações"
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>Pesquisar</FormLabel>
                  <Controller
                    name="search"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("search", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["search"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>Origem</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="origin"
                    selectOptions={getDatasetStateByName(props, DATASET.ORIGIN)}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"origin"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.notificationState?.notifications_Loading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
  getNotifications: (message, origin, onlyUnread, maxResult) =>
    dispatch(getNotificationsAction(message, origin, onlyUnread, maxResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
