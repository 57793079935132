import {
  getDataSetByName,
  getChildrenByPropertyId,
  getGeographicData,
  getGeographicDataByProvince,
  getNationalities,
  getAllPrograms,
  getSubprogramByProgramId,
  getDataSetByIndex,
  getShapePrograms,
  getShapeSubsites,
  getUserApplicationsAwaitingTraining,
} from "crud/datasets.crud";
import { DATASET_ACTIONS } from "utils/actionsConsts";
import { DATASET } from "utils/const";

export const clearDataSetByNameAction = (name) => async (dispatch) => {
  dispatch({
    type: DATASET_ACTIONS.CLEAR_DATASET_BY_NAME,
    result: true,
    dataSetName: name,
  });
};
export const getDataSetByNameAction = (name, parentKeyValue) => async (dispatch) => {
  try {
    const res = await getDataSetByName(name, parentKeyValue);

    const payload = res.data.map((child) => {
      return {
        code: child.id,
        label: child.value,
        keyValue: child.keyValue,
      };
    });

    dispatch({
      type: DATASET_ACTIONS.GET_DATASET_BY_NAME,
      result: true,
      payload,
      dataSetName: name,
    });

    return payload;
  } catch (error) {
    dispatch({
      type: DATASET_ACTIONS.GET_DATASET_BY_NAME,
      result: false,
      payload: [],
      dataSetName: name,
    });
  }
};

export const getDataSetByIndexAction = (index) => async (dispatch) => {
  getDataSetByIndex(index)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_INDEX,
        result: true,
        payload: res.data.children.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: index,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_INDEX,
        result: false,
        payload: [],
        dataSetName: index,
      });
    });
};

export const getChildrenByPropertyIdAction = (name, id) => async (dispatch) => {
  getChildrenByPropertyId(id)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_PROPERTY_ID,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_PROPERTY_ID,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getGeographicDataAction = (name) => async (dispatch) => {
  getGeographicData()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_GEOGRAPHIC_DATA,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.provinceId,
            label: child.province,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_GEOGRAPHIC_DATA,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getGeographicDataByProvinceAction = (name, province) => async (dispatch) => {
  getGeographicDataByProvince(province)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_GEOGRAPHIC_DATA_BY_PROVINCE,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.municipalityId,
            label: child.municipality,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_GEOGRAPHIC_DATA_BY_PROVINCE,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getNationalitiesAction = (name) => async (dispatch) => {
  getNationalities()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_NATIONALITIES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_NATIONALITIES,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getAllProgramsAction = (governmentProgramId) => async (dispatch) => {
  dispatch({
    type: DATASET_ACTIONS.DATASETS_LOADING,
    dataSetName: DATASET.PROGRAMS,
    payload: true,
  });
  getAllPrograms(governmentProgramId)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_ALL_PROGRAMS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
            governmentProgramId: child.governmentProgramId,
          };
        }),
        dataSetName: DATASET.PROGRAMS,
      });
      dispatch({
        type: DATASET_ACTIONS.DATASETS_LOADING,
        dataSetName: DATASET.PROGRAMS,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_ALL_PROGRAMS,
        result: false,
        payload: [],
        dataSetName: DATASET.PROGRAMS,
      });
      dispatch({
        type: DATASET_ACTIONS.DATASETS_LOADING,
        dataSetName: DATASET.PROGRAMS,
        payload: false,
      });
    });
};

export const getSubprogramByProgramIdAction = (name, program, onlyActive) => async (dispatch) => {
  dispatch({
    type: DATASET_ACTIONS.DATASETS_LOADING,
    dataSetName: name,
    payload: true,
  });
  getSubprogramByProgramId(program, onlyActive)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM_ID,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
      dispatch({
        type: DATASET_ACTIONS.DATASETS_LOADING,
        dataSetName: name,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM_ID,
        result: false,
        payload: [],
        dataSetName: name,
      });
      dispatch({
        type: DATASET_ACTIONS.DATASETS_LOADING,
        dataSetName: name,
        payload: false,
      });
    });
};

export const getShapeProgramsAction = (target, province, name) => async (dispatch) => {
  getShapePrograms(target, province)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_PROGRAMS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
            externalId: child.externalJobId,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_PROGRAMS,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getShapeSubsitesAction = (offer, province, name) => async (dispatch) => {
  getShapeSubsites(offer, province)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_SUBSITES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_SUBSITES,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getUserApplicationsAwaitingTrainingAction = (name) => async (dispatch) => {
  getUserApplicationsAwaitingTraining()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_USER_APPLICATIONS_AWAITING_TRAINING,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.applicationCode + " - " + child.subProgramName,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_USER_APPLICATIONS_AWAITING_TRAINING,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export default getDataSetByNameAction;
