import { React } from "react";

import { makeStyles } from "@material-ui/core/styles";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import { Card, Grid, Typography } from "@material-ui/core";
import PreviewObservations from "components/PreviewObservations";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export const ContractApprovalRequestObservations = ({ observations }) => {
  const classes = useStylesGeneric();
  return (
    <Grid item xs={12} md={12}>
      <Card className={classes.cardRoot}>
        <Typography variant="h2" className={classes.cardTitle} color="primary">
          Observações
        </Typography>
        <Grid container>
          <PreviewObservations observations={observations?.observations} />
        </Grid>
      </Card>
    </Grid>
  );
};
