import React, { useCallback, useMemo, useState } from "react";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import LocationHeader from "components/Headers/LocationHeader";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import ContractApprovalWorkerDetailsDialog from "../components/ContractApprovalWorkerDetailsDialog";
import { connect, useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getContractApprovalDetails } from "crud/contractApproval.crud";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { deleteContractApproval } from "crud/contractApproval.crud";
import { DIALOG } from "utils/actionsConsts";
import { ConditionGuard } from "guards/ConditionGuard";
import PaymentModal from "../components/PaymentModal";
import ContractApprovalDetailsTable from "../components/ContractApprovalDetailsTable";
import { listContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";
import { useForm } from "react-hook-form";
import FormDropdown from "components/FormFields/FormDropdown";
import { useDataset } from "hooks/useDataset";
import { DATASET } from "utils/const";
import { ContractApprovalRequestDetailsCard } from "../components/ContractApprovalRequestDetailsCard";
import { getKeyValueByCode } from "utils/datasets";
import { submitContractApproval } from "crud/contractApproval.crud";
import { ContractApprovalRequestObservations } from "../components/ContractApprovalRequestObservations";
import ContractApprovalWorkerDialog from "../components/ContractApprovalWorkerDialog";
import { CONTRACT_APPROVAL_STATUS } from "utils/const";
import { CONTRACT_TYPES_KEYVALUES } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ContractApprovalDetails = (props) => {
  const classes = useStylesGeneric();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [showSearchModal, setShowSearchModal] = React.useState(false);

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const redirect = true;
  const queryClient = useQueryClient();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });
  const { control, watch, getValues, handleSubmit } = useForm();

  const provinceId = watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: EMPLOYMENT_CENTERS } = useDataset(
    { name: DATASET.EMPLOYMENT_CENTERS, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
    setShowSearchModal(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    queryClient.refetchQueries({
      queryKey: ["listEmployee", id],
      exact: true,
    });
  };

  const handleOpenDialog = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedId(null);
  };

  const handleClosePaymentModal = () => {
    setIsOpenPaymentModal(false);
  };

  const handlePaymentInformation = () => {
    setIsOpenPaymentModal(true);
  };

  const goBack = () => {
    props.history.push("/admin/contract-approval/list");
  };

  const fetchContractApprovalDetails = useCallback(() => {
    return getContractApprovalDetails(id ?? "");
  }, [id]);

  const { data: contractApprovalDetails } = useQuery({
    queryKey: ["contractApprovalDetails", id],
    queryFn: fetchContractApprovalDetails,
  });

  const { mutateAsync: submitRequest } = useMutation(
    ["submitContractApprovalRequest"],
    async () =>
      submitContractApproval({
        id: id,
        provinceId: getValues().provinceId,
        employmentCenterId: getValues().employmentCenterId,
      }),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: true,
              buttonLabel: "Ok",
              onClose: "/admin/contract-approval/list",
              message: "Pedido de homologação submetido com sucesso.",
            },
          },
        });
      },
      onError: (error) => {
        let errorMessage = "Ocurreu um erro ao eliminar o trabalhador.";
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          errorMessage = Object.values(errors).flat().join("\n");
        }
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: errorMessage,
            },
          },
        });
      },
    }
  );

  const { mutateAsync: handleDeleteAsync } = useMutation(
    ["createWorkCenterEmployer"],
    async (id) => deleteContractApproval(id),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: redirect,
              buttonLabel: "Ok",
              onClose: redirect ? "/admin/contract-approval/list" : null,
              message: "Pedido eliminado com sucesso.",
            },
          },
        });
      },
      onError: (error) => {
        console.log(error);

        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
            },
          },
        });
      },
    }
  );

  const deleteRequest = async () => {
    if (!id) {
      throw new Error("O id do pedido não está definido.");
    }
    await handleDeleteAsync(id);
  };

  const fetchContractApprovalWorkersList = useCallback(() => {
    return listContractApprovalWorkCenterEmployee(id ?? "");
  }, [id]);

  const { data: listEmployee, isLoading } = useQuery({
    queryKey: ["listEmployee", id],
    queryFn: fetchContractApprovalWorkersList,
  });

  const showAddPaymentDataButton = listEmployee?.data?.data?.some((worker) => worker?.contractTypeKeyValue === CONTRACT_TYPES_KEYVALUES.ESTRANGEIROS_NAO_RESIDENTES);

  const isDraft = contractApprovalDetails?.contractApprovalStatusKeyValue === CONTRACT_APPROVAL_STATUS.CREATED;

  const awaitingValidation = contractApprovalDetails?.contractApprovalStatusKeyValue === CONTRACT_APPROVAL_STATUS.AWAITING_VALIDATION;

  const awaitingPaymentData = contractApprovalDetails?.contractApprovalStatusKeyValue === CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT_DATA;

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <Grid item xs={12} md={12}>
        <LocationHeader section="Homologação de contratos" />
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box>
          <PageTitleHeader title={"Detalhes Pedido de Homologação"} />
        </Box>
        <ConditionGuard condition={!isDraft && showAddPaymentDataButton}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "37px",
            }}
          >
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              size="large"
              onClick={() => handlePaymentInformation()}
              classes={classes.TableCardHeaderButton}
              disabled={awaitingPaymentData}
            >
              Informações de Pagamento
            </GenericButton>
          </Box>
        </ConditionGuard>
      </Box>

      <ContractApprovalRequestDetailsCard contractApprovalRequest={contractApprovalDetails} isDraft={isDraft} />

      <ConditionGuard condition={isDraft}>
        <Grid item xs={12} md={12}>
          <Card className={classes.cardRoot}>
            <form onSubmit={handleSubmit(submitRequest)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h2" style={{ color: "#002e9e", marginBottom: "20px" }}>
                    Centro de Emprego
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormDropdown
                    control={control}
                    label="Província Centro de Emprego"
                    name="provinceId"
                    options={PROVINCES}
                    mappingTo="code"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormDropdown
                    control={control}
                    label="Centro de Emprego"
                    name="employmentCenterId"
                    options={EMPLOYMENT_CENTERS}
                    required
                    mappingTo="code"
                    disabled={false || !getValues("provinceId")}
                  />
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </ConditionGuard>
      <ConditionGuard condition={isDraft}>
        <Grid item xs={12} md={12}>
          <GenericButton
            typeSubmit={false}
            color="tertiary"
            size="large"
            onClick={() => handleOpenCreateDialog()}
            classes={classes.TableCardHeaderButton}
          >
            Adicionar Trabalhador
          </GenericButton>
        </Grid>{" "}
      </ConditionGuard>
      <Grid item xs={12} md={12}>
        <Box
          classes={{ root: classes.tableResultBoxRoot }}
          sx={{
            mt: 3,
          }}
        >
          <ContractApprovalDetailsTable
            page={page}
            handleChangePage={handleChangePage}
            handleOpenDialog={handleOpenDialog}
            listEmployee={listEmployee}
            isLoading={isLoading}
          />
        </Box>
      </Grid>

      <ConditionGuard condition={!isDraft}>
        <ContractApprovalRequestObservations observations={contractApprovalDetails} />
      </ConditionGuard>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <GenericButton size="medium" color="tertiary" onClick={() => goBack()}>
          {"Voltar á Página Inicial"}
        </GenericButton>
        <ConditionGuard condition={isDraft}>
          <GenericButton size="medium" color="tertiaryRed" onClick={() => deleteRequest()}>
            {"Eliminar Pedido"}
          </GenericButton>
          <GenericButton size="medium" color="primary" onClick={() => submitRequest()}>
            {"Submeter Pedido"}
          </GenericButton>
        </ConditionGuard>
      </Grid>
      {openDialog && (
        <ContractApprovalWorkerDetailsDialog
          openDialog={openDialog}
          handleCloseDetailsDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          workerId={selectedId}
          isDraft={isDraft}
          requestNumber={id}
          stateKeyValue={contractApprovalDetails?.contractApprovalStatusKeyValue}
        />
      )}

      {openCreateDialog && (
        <ContractApprovalWorkerDialog
          openDialog={openCreateDialog}
          handleCloseDialog={handleCloseCreateDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          showSearchModal={showSearchModal}
        />
      )}

      {isOpenPaymentModal && (
        <PaymentModal
          isOpenPaymentModal={isOpenPaymentModal}
          handleClosePaymentModal={handleClosePaymentModal}
          awaitingValidation={awaitingValidation}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractApprovalDetails);
