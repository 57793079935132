import React, { useMemo } from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import FormInput from "components/Forms/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { DATASET } from "utils/const";
import FormDatePicker from "components/Forms/FormDatePicker";
import moment from "moment";
import { useDataset } from "hooks/useDataset";
import { useQuery } from "@tanstack/react-query";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { getKeyValueByCode } from "utils/datasets";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID } from "utils/const";
import { ConditionGuard } from "guards/ConditionGuard";
import { getEmployeeDetails } from "crud/rent.crud";
import useTokenHelper from "hooks/useTokenHelper";
import { getMyWorkCenters } from "crud/workCentersEmployer.crud";
import { getEmployeeDocumentById } from "crud/workCentersEmployer.crud";
import PreviewDocument from "components/PreviewDocument";

export const EmployeeDialogDetails = ({ isOpen, closeModal, modalData }) => {
  const methods = useForm();

  const id = useMemo(() => modalData, [modalData]);

  const { isFetching } = useQuery(["rent", "getEmployeeDetails", id], () => getEmployeeDetails(id), {
    enabled: !!id,
    onSuccess: (data) => {
      methods.reset({
        ...data,
        dateOfBirth: moment(data?.dateOfBirth),
        identityDocumentIssueDate: moment(data?.identityDocumentIssueDate),
        identityDocumentExpiryDate: moment(data?.identityDocumentExpiryDate),
        addmissionDate: moment(data?.addmissionDate),
      });
    },
  });

  const tokenHelper = useTokenHelper();

  const { data: WORK_CENTERS } = useQuery(["my-workcenters"], () => getMyWorkCenters(), {
    enabled: !!tokenHelper?.getUserId(),
    select: (data) => {
      if (!data) return [];

      return data.map((item) => ({
        code: item.id,
        label: item.value,
      }));
    },
  });

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = methods.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: MARITAL_STATUS } = useDataset({ name: DATASET.MARITAL_STATUS });
  const { data: GENDER } = useDataset({ name: DATASET.GENDER });
  const { data: ACADEMIC_LEVEL } = useDataset({ name: DATASET.ACADEMIC_LEVEL });
  const { data: NATIONALITIES } = useDataset({ name: DATASET.NATIONALITIES });
  const { data: PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.EMPLOYMENT_PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_STATUS } = useDataset({ name: DATASET.EMPLOYMENT_STATUS });
  const { data: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES } = useDataset({ name: DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES });

  const documentTypeId = methods.watch("documentTypeId");

  const documentWatcher = methods.watch("documents");
  const documents = useMemo(
    () =>
      documentWatcher?.map((doc) => ({
        documentId: doc.id,
        ...doc,
      })) || [],
    [documentWatcher]
  );

  const isPassport = documentTypeId === COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT;

  return (
    <FormProvider {...methods}>
      <form>
        <GenericDialog open={isOpen} onClose={closeModal} maxWidth="lg">
          <PageTitleHeader title={"Detalhes do Trabalhador"} />

          <Box>
            <Grid item xs={12} md={12}>
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                Ao adicionar um novo registo de trabalhador, o sistema irá actualizar na área de utilizador a lista de
                trabalhadores registados da sua empresa.
              </Typography>
            </Grid>
          </Box>

          <ConditionGuard
            condition={!isFetching}
            fallback={
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          >
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Pessoais
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="name"
                  label="Nome do trabalhador"
                  placeholder="Insira o nome do trabalhador"
                  required
                  control={methods.control}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="nif"
                  label="NIF"
                  placeholder="Insira o NIF"
                  required
                  control={methods.control}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="ssn"
                  label="NISS"
                  placeholder="Insira o NISS"
                  required
                  control={methods.control}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="address"
                  label="Morada"
                  placeholder="Insira a morada do trabalhador"
                  required
                  control={methods.control}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Província"
                  name="provinceId"
                  options={PROVINCES}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Município"
                  name="municipalityId"
                  options={MUNICIPALITIES}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  label="Nacionalidade"
                  control={methods.control}
                  name="nationalityId"
                  options={NATIONALITIES}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="dateOfBirth"
                  label="Data de nascimento"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Género"
                  placeholder="Seleccione o género"
                  control={methods.control}
                  name="genderId"
                  options={GENDER}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Estado civil"
                  placeholder="Seleccione o estado civil"
                  control={methods.control}
                  name="maritalStatusId"
                  options={MARITAL_STATUS}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Nível académico"
                  placeholder="Seleccione o nível académico"
                  control={methods.control}
                  name="academicLevelId"
                  options={ACADEMIC_LEVEL}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Documento de identificação"
                  placeholder="Seleccione o documento de identificação"
                  control={methods.control}
                  name="documentTypeId"
                  options={COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="identityDocumentNumber"
                  label="N.º do documento de identificação"
                  placeholder="Insira n.º de documento"
                  type="number"
                  control={methods.control}
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentIssueDate"
                  label="Data de emissão"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  endDatename="endDate"
                  isRequired={true}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentExpiryDate"
                  label="Data de validade"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={false}
                  minDateToday={true}
                  endDatename="endDate"
                  isRequired={true}
                  disabled
                />
              </Grid>

              <ConditionGuard condition={isPassport}>
                <Grid item xs={12} md={3}>
                  <FormInputV2
                    name="workVisaNumber"
                    label="Nº Visto de Trabalho"
                    placeholder="Insira nº do Visto de Trabalho"
                    control={methods.control}
                    disabled
                    required
                  />
                </Grid>
              </ConditionGuard>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  marginBottom: "1rem",
                }}
              >
                <PreviewDocument title="Documentos Anexados" documents={documents} promise={getEmployeeDocumentById} />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Profissionais
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="addmissionDate"
                  label="Data de admissão"
                  control={methods.control}
                  rules={{ required: true }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Centro de trabalho"
                  placeholder="Seleccione o centro de trabalho"
                  control={methods.control}
                  name="workCenterId"
                  options={WORK_CENTERS}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Situação profissional"
                  placeholder="Seleccione a situação profissional"
                  control={methods.control}
                  name="employmentStatusId"
                  options={EMPLOYMENT_STATUS}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Categoria profissional"
                  placeholder="Seleccione a categoria profissional"
                  control={methods.control}
                  name="professionalCategoryId"
                  options={EMPLOYMENT_PROFESSIONAL_CATEGORY}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão primária"
                  placeholder="Seleccione a profissão primária"
                  control={methods.control}
                  name="primaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão secundária"
                  placeholder="Seleccione a profissão secundária"
                  control={methods.control}
                  name="secondaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão terciária"
                  placeholder="Seleccione a profissão terciária"
                  control={methods.control}
                  name="tertiaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="baseSalary"
                  label="Salário Expresso no Contrato"
                  placeholder="Insira o salário base"
                  control={methods.control}
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Outros dados
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormInput
                  name="normalWorkingHours"
                  label="Horas normais"
                  placeholder="Insira as horas normais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="weeklyWorkingHours"
                  label="Horas semanais"
                  placeholder="Insira as horas semanais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="regularProvision"
                  label="Prestação Regular"
                  placeholder="Insira a prestação regular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="irregularProvision"
                  label="Prestação Irregular"
                  placeholder="Insira a prestação irregular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={closeModal}>
                  Fechar
                </GenericButton>
              </Grid>
            </Grid>
          </ConditionGuard>
        </GenericDialog>
      </form>
    </FormProvider>
  );
};
