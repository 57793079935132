export function jsonToFormData(json, formData = new FormData(), parentKey = "") {
    Object.keys(json).forEach((key) => {
      let constructedKey = parentKey ? `${parentKey}.${key}` : key;
  
      if (Array.isArray(json[key])) {
        const isArrayOfFiles = json[key].some((value) => value instanceof File);
  
        if (isArrayOfFiles) {
          json[key].forEach((value) => {
            if (value instanceof File) {
              formData.append(`${constructedKey}`, value, value.name);
            }
          });
        } else {
          json[key].forEach((value, index) => {
            if (typeof value === "object" && value !== null) {
              jsonToFormData(value, formData, `${constructedKey}[${index}]`);
            } else {
              formData.append(`${constructedKey}[${index}]`, value);
            }
          });
        }
      } else if (json[key] instanceof Date) {
        formData.append(constructedKey, json[key].toISOString());
      } else if (typeof json[key] === "object" && json[key] !== null) {
        jsonToFormData(json[key], formData, constructedKey);
      } else {
        formData.append(constructedKey, json[key]);
      }
    });
  
    return formData;
  }
  