import { React } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { ConditionGuard } from "guards/ConditionGuard";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { CardActions, IconButton, Tooltip } from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function EmployeesTable({ handleOpenDetails }) {
  const theme = useTheme();

  const classes = useStylesGeneric();

  const { id } = useParams();

  const tableHead = [
    "Data de Registo",
    "Nome do Trabalhador",
    "NISS",
    "Centro de Trabalho",
    "Situação Profissional",
    "Categoria Profissional",
  ];

  const { setValue, watch } = useFormContext();

  const page = watch("pageIndex");

  const { data, isFetching, refetch } = useQuery(["get-filtered-employees-snapshot", id], {
    select: (response) => {
      if (!response) return {};

      return {
        data: response.data,
        totalCount: response.totalCount,
        totalPages: response.totalPages,
      };
    },
  });

  return (
    <>
      <CardTableContainer title="Lista de trabalhadores submetidos" count={data?.totalCount}>
        <TableContainer>
          <ConditionGuard condition={!isFetching} fallback={<TableBackdrop open />}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                  >
                    Detalhes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(prop.created).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.ssn}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.workCenter}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.employmentStatus}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.professionalCategory}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title={"Detalhes"}>
                        <IconButton onClick={() => handleOpenDetails(prop.snapshotId)}>
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </ConditionGuard>
        </TableContainer>

        {!isFetching && data?.data?.length === 0 && (
          <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
        )}

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

        <Pagination
          className={classes.alignPagination}
          count={data?.totalPages}
          page={page}
          onChange={(_, value) => {
            setValue("pageIndex", value);

            refetch();
          }}
          color="primary"
        />
      </CardTableContainer>
    </>
  );
}
