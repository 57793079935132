import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Toolbar from "@material-ui/core/Toolbar";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons components
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// core components
import NotificationsDropdown from "components/Dropdowns/NotificationsDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import AdminNavbarMenuDropdown from "components/Dropdowns/AdminNavbarMenuDropdown.js";
import GenericDialog from "components/Dialogs/GenericDialog";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS, INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";
import { ArrayUtils } from "utils/array_utils";
import { getGovernmentProgramsAction } from "redux/actions/governmentProgramsAction";
import { dataForApplicationCreationAction } from "redux/actions/applicationsAction";
import { labels, titles } from "resources/resources";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
import SearchBarNavbar from "components/Navbars/Sections/SearchbarNavbar";
import { CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE } from "utils/actionsConsts";
import { CONTRACT_APPROVAL } from "utils/actionsConsts";

import componentStyles from "assets/theme/components/navbars/admin-navbar.js";
import { useQuery } from "@tanstack/react-query";
import { getCheckRENTSubmission } from "crud/rent.crud";
import { ConditionGuard } from "guards/ConditionGuard";

const useStyles = makeStyles(componentStyles);
function AdminNavbar({ ...props }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [openGovernmentProgramsMenu, setOpenGovernmentProgramsMenu] = useState(false);
  const anchorRefGovernmentProgramsMenu = useRef(null);
  const [openEmploymentAndInternshipMenu, setOpenEmploymentAndInternshipMenu] = useState(false);
  const anchorRefEmploymentAndInternshipMenu = useRef(null);
  const [openInfoDissemination, setOpenInfoDissemination] = useState(false);
  const anchorRefInfoDissemination = useRef(null);
  const [openInstitutionalInformation, setOpenInstitutionalInformation] = useState(false);
  const anchorRefInstitutionalInformation = useRef(null);
  const [openInformation, setOpenInformation] = useState(false);
  const anchorRefInformation = useRef(null);
  const [openHelp, setOpenHelp] = useState(false);
  const anchorRefHelp = useRef(null);
  const [openCompanyServicesMenu, setOpenCompanyServicesMenu] = useState(false);
  const anchorRefCompanyServicesMenu = useRef(null);

  const dropdownRefCompanyServices = useRef(null);
  const [isMenuDropDownOpenCompanyServices, setMenuDropDownOpenCompanyServices] = useState(false);
  const dropdownRefCitizenServices = useRef(null);
  const [isMenuDropDownOpenCitizenServices, setMenuDropDownOpenCitizenServices] = useState(false);
  const dropdownRefEmploymentServices = useRef(null);
  const [isMenuDropDownOpenEmploymentServices, setMenuDropDownOpenEmploymentServices] = useState(false);
  const dropdownRefEntrepreneurshipServices = useRef(null);
  const [isMenuDropDownOpenEntrepreneurshipServices, setMenuDropDownOpenEntrepreneurshipServices] = useState(false);
  const dropdownRefProfessionalTraining = useRef(null);
  const [isMenuDropDownOpenProfessionalTraining, setMenuDropDownOpenProfessionalTraining] = useState(false);
  const dropdownRefRentService = useRef(null);
  const [isMenuDropDownOpenRentService, setMenuDropDownOpenRentService] = useState(false);

  useEffect(() => {
    if (ArrayUtils.NullOrEmpty(props?.governmentProgramsState?.getGovernmentPrograms_result))
      props.governmentPrograms();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.governmentProgramsState.getGovernmentPrograms_result]);

  const handleCloseGovernmentProgramsMenu = (event) => {
    if (anchorRefGovernmentProgramsMenu.current && anchorRefGovernmentProgramsMenu.current.contains(event.target)) {
      return;
    }
    setOpenGovernmentProgramsMenu(false);
  };

  const handleToggleGovernmentProgramsMenu = () => {
    setOpenGovernmentProgramsMenu((openGovernmentProgramsMenu) => !openGovernmentProgramsMenu);
  };

  const handleCloseEmploymentAndInternshipMenu = (event) => {
    if (
      anchorRefEmploymentAndInternshipMenu.current &&
      anchorRefEmploymentAndInternshipMenu.current.contains(event.target)
    ) {
      return;
    }
    setOpenEmploymentAndInternshipMenu(false);
  };

  const handleToggleEmploymentAndInternshipMenu = () => {
    setOpenEmploymentAndInternshipMenu((openEmploymentAndInternshipMenu) => !openEmploymentAndInternshipMenu);
  };

  const handleCloseCompanyServicesMenu = (event) => {
    if (anchorRefCompanyServicesMenu.current && anchorRefCompanyServicesMenu.current.contains(event.target)) {
      return;
    }
    setOpenCompanyServicesMenu(false);
  };

  const handleToggleCompanyServicesMenu = () => {
    setOpenCompanyServicesMenu((openCompanyServicesMenu) => !openCompanyServicesMenu);
  };

  const clearContractApprovalState = () => {
    dispatch({
      type: CONTRACT_APPROVAL.CLEAR_CONTRACT_APPROVAL_REQUEST,
    });
    dispatch({
      type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CLEAR_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_REQUEST,
    });
  };

  const handleCloseInfoDissemination = (event) => {
    if (anchorRefInfoDissemination.current && anchorRefInfoDissemination.current.contains(event.target)) {
      return;
    }
    setOpenInfoDissemination(false);
  };

  const handleToggleInfoDissemination = () => {
    setOpenInfoDissemination((prevOpenInfoDissemination) => !prevOpenInfoDissemination);
  };

  const handleCloseInstitutionalInformation = (event) => {
    if (anchorRefInstitutionalInformation.current && anchorRefInstitutionalInformation.current.contains(event.target)) {
      return;
    }
    setOpenInstitutionalInformation(false);
  };

  const handleToggleInstitutionalInformation = () => {
    setOpenInstitutionalInformation((prevOpenInstitutionalInformation) => !prevOpenInstitutionalInformation);
  };

  const handleCloseInformation = (event) => {
    if (anchorRefInformation.current && anchorRefInformation.current.contains(event.target)) {
      return;
    }
    setOpenInformation(false);
  };

  const handleToggleInformation = () => {
    setOpenInformation((prevOpenInformation) => !prevOpenInformation);
  };

  const handleCloseHelp = (event) => {
    if (anchorRefHelp.current && anchorRefHelp.current.contains(event.target)) {
      return;
    }
    setOpenHelp(false);
  };

  const handleToggleHelp = () => {
    setOpenHelp((prevOpenHelp) => !prevOpenHelp);
  };

  const changeData = (program) => {
    props.dataForApplicationCreation({
      governmentProgramId: program,
      programId: null,
    });
  };

  const closeHoverMenuCompanyServices = () => {
    setMenuDropDownOpenCompanyServices(false);
  };

  useOnHoverOutside(dropdownRefCompanyServices, closeHoverMenuCompanyServices);

  const closeHoverMenuCitizenServices = () => {
    setMenuDropDownOpenCitizenServices(false);
  };

  useOnHoverOutside(dropdownRefCitizenServices, closeHoverMenuCitizenServices);

  const closeHoverMenuEmploymentServices = () => {
    setMenuDropDownOpenEmploymentServices(false);
  };

  useOnHoverOutside(dropdownRefEmploymentServices, closeHoverMenuEmploymentServices);

  const closeHoverMenuEntrepreneurshipServices = () => {
    setMenuDropDownOpenEntrepreneurshipServices(false);
  };

  useOnHoverOutside(dropdownRefEntrepreneurshipServices, closeHoverMenuEntrepreneurshipServices);

  const closeHoverMenuProfessionalTraining = () => {
    setMenuDropDownOpenProfessionalTraining(false);
  };

  useOnHoverOutside(dropdownRefProfessionalTraining, closeHoverMenuProfessionalTraining);

  const closeHoverMenuRentService = () => {
    setMenuDropDownOpenRentService(false);
  };

  useOnHoverOutside(dropdownRefRentService, closeHoverMenuRentService);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const { data: canSubmitRENT } = useQuery(["getCheckRENTSubmission"], () => getCheckRENTSubmission());

  return (
    <>
      <AppBar elevation={0} classes={{ root: classes.appBarRoot }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1rem",
            margin: "0 1.5rem",
          }}
          className={classes.upperContainerRoot}
        >
          <Link to="/admin/home">
            <Box
              component="img"
              alt="..."
              className={classes.logo}
              src={require("assets/img/brand/INEFOP_azul.png").default}
            />
          </Link>
          <Box display="flex" alignItems="center">
            <NotificationsDropdown />
            <Box display="flex" justifyContent="center" alignItems="center">
              <UserDropdown />
            </Box>
          </Box>
        </Box>
        <Toolbar disableGutters>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom="1rem">
              {/* Navbar Menu for lower resolutions */}
              <Hidden lgUp implementation="css">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <AdminNavbarMenuDropdown />
                </Box>
              </Hidden>

              {/* The following items use the Hidden component to not be displayed on lower resolutions,
              instead the AdminNavbarMenuDropdown will display all the items. */}

              {/* INEFOP */}
              <Hidden mdDown implementation="css">
                <Box>
                  <ListItem
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    ref={anchorRefInstitutionalInformation}
                    aria-controls={openInstitutionalInformation ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleInstitutionalInformation}
                  >
                    {intl.formatMessage(labels.Label_Menu_INEFOP)}
                    <ListItemIcon className={classes.dropIcon}>
                      <ExpandMoreIcon />
                    </ListItemIcon>
                    <Popper
                      open={openInstitutionalInformation}
                      anchorEl={anchorRefInstitutionalInformation.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleCloseInstitutionalInformation}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem
                                  component={Link}
                                  to={`/admin/institution-overview`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOverview)}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={`/admin/institution-history`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionHistory)}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={`/admin/institution-mission-vision-values`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionMissionVisionAndValues)}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={`/admin/institution-organic-status`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganicStatus)}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={`/admin/institution-organization-chart`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganizationChart)}
                                </MenuItem>
                                <MenuItem
                                  component={Link}
                                  to={`/admin/director-biography`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_DirectorBiography)}
                                </MenuItem>
                                <MenuItem
                                  component={Link}
                                  to={`/admin/institute-holders`}
                                  className={classes.dropItems}
                                  onClick={handleCloseInstitutionalInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_INEFOP_InstituteHolders)}
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ListItem>
                </Box>
              </Hidden>

              {/* Apoios */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.GOVERNMENT_PROGRAMS) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefGovernmentProgramsMenu}
                      aria-controls={openGovernmentProgramsMenu ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleGovernmentProgramsMenu}
                    >
                      Apoios
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openGovernmentProgramsMenu}
                        anchorEl={anchorRefGovernmentProgramsMenu.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseGovernmentProgramsMenu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.APPLICATIONS_CREATE_ITEM
                                  ) && [
                                    props?.governmentProgramsState?.getGovernmentPrograms_result?.map(
                                      (program, key) => (
                                        <MenuItem
                                          key={key}
                                          component={Link}
                                          to={"/admin/application-create"}
                                          className={classes.dropItems}
                                          onClick={(e) => {
                                            handleCloseGovernmentProgramsMenu(e);
                                            changeData(program.id);
                                          }}
                                        >
                                          {program.value}
                                        </MenuItem>
                                      )
                                    ),
                                  ]}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Emprego e estágio */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_MENU
                  ) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefEmploymentAndInternshipMenu}
                      aria-controls={openEmploymentAndInternshipMenu ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleEmploymentAndInternshipMenu}
                    >
                      {" "}
                      Emprego e Estágio
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openEmploymentAndInternshipMenu}
                        anchorEl={anchorRefEmploymentAndInternshipMenu.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseEmploymentAndInternshipMenu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_OPPORTUNITIES_LIST_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/opportunities"
                                      className={classes.dropItems}
                                      onClick={handleCloseEmploymentAndInternshipMenu}
                                    >
                                      Lista de Ofertas
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_APPLICATIONS_LIST_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/user-colective-opportunity-applications"
                                      className={classes.dropItems}
                                      onClick={handleCloseEmploymentAndInternshipMenu}
                                    >
                                      Lista de Candidaturas
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_SINGULAR_MENU
                  ) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefEmploymentAndInternshipMenu}
                      aria-controls={openEmploymentAndInternshipMenu ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleEmploymentAndInternshipMenu}
                    >
                      Emprego e Estágio
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openEmploymentAndInternshipMenu}
                        anchorEl={anchorRefEmploymentAndInternshipMenu.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseEmploymentAndInternshipMenu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.OPPORTUNITY_USER_SINGULAR_OPPORTUNITIES_LIST_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/available-opportunities"
                                      className={classes.dropItems}
                                      onClick={handleCloseEmploymentAndInternshipMenu}
                                    >
                                      Lista de Ofertas
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Formação Profissional */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_TRAINING_MENU) && (
                    <ListItem
                      component="a"
                      href="/formacao/"
                      classes={{
                        root: classes.listItemRoot,
                      }}
                    >
                      {" "}
                      Formação Profissional
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Informação */}
              <Hidden mdDown implementation="css">
                <Box>
                  <ListItem
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    ref={anchorRefInformation}
                    aria-controls={openInformation ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleInformation}
                  >
                    {intl.formatMessage(labels.Label_Menu_Information)}

                    <ListItemIcon className={classes.dropIcon}>
                      <ExpandMoreIcon />
                    </ListItemIcon>
                    <Popper
                      open={openInformation}
                      anchorEl={anchorRefInformation.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleCloseInformation}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {/* Submenu Group: Company Services */}
                                {checkOperationPermission(
                                  props.authState.operationsList,
                                  OPERATIONS.OPPORTUNITY_USER_COLECTIVE_MENU
                                ) && (
                                  <div ref={dropdownRefCompanyServices}>
                                    <MenuItem
                                      className={classes.dropItems}
                                      onMouseOver={() => setMenuDropDownOpenCompanyServices(true)}
                                      onClick={handleCloseInformation}
                                    >
                                      {intl.formatMessage(labels.Label_Menu_Information_CompanyServices)}
                                      {isMenuDropDownOpenCompanyServices ? (
                                        <NavigateBeforeIcon className={classes.expandIcon} />
                                      ) : (
                                        <NavigateNextIcon className={classes.expandIcon} />
                                      )}
                                    </MenuItem>

                                    {isMenuDropDownOpenCompanyServices && (
                                      <Paper className={classes.submenuGroup}>
                                        <ClickAwayListener onClickAway={handleCloseInformation}>
                                          <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/opportunities-create`}
                                              className={classes.dropItems}
                                              onClick={handleCloseInformation}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities
                                              )}
                                            </MenuItem>
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/ratify-agreement`}
                                              className={classes.dropItems}
                                              onClick={handleCloseInformation}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CompanyServices_RatifyAgreement
                                              )}
                                            </MenuItem>
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/information-training-centers-licensing`}
                                              className={classes.dropItems}
                                              onClick={handleCloseInformation}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CompanyServices_LicensingCenters
                                              )}
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    )}
                                  </div>
                                )}

                                {/* Submenu Group: Citizen Services */}
                                {checkOperationPermission(
                                  props.authState.operationsList,
                                  OPERATIONS.OPPORTUNITY_USER_SINGULAR_MENU
                                ) && (
                                  <div ref={dropdownRefCitizenServices}>
                                    <MenuItem
                                      className={classes.dropItems}
                                      onMouseOver={() => setMenuDropDownOpenCitizenServices(true)}
                                      onClick={handleCloseInformation}
                                    >
                                      {intl.formatMessage(labels.Label_Menu_Information_CitizenServices)}
                                      {isMenuDropDownOpenCitizenServices ? (
                                        <NavigateBeforeIcon className={classes.expandIcon} />
                                      ) : (
                                        <NavigateNextIcon className={classes.expandIcon} />
                                      )}
                                    </MenuItem>

                                    {isMenuDropDownOpenCitizenServices && (
                                      <Paper className={classes.submenuGroup}>
                                        <ClickAwayListener onClickAway={handleCloseInformation}>
                                          <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/available-opportunities`}
                                              className={classes.dropItems}
                                              onClick={handleCloseInformation}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities
                                              )}
                                            </MenuItem>
                                            <MenuItem
                                              component={Link}
                                              to={"#"}
                                              className={classes.dropItems}
                                              onClick={(event) => {
                                                window.location.href = "/formacao";
                                                handleCloseInformation(event);
                                              }}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CitizenServices_SeeProfessionalTraining
                                              )}
                                            </MenuItem>
                                            <MenuItem
                                              component={Link}
                                              to={"#"}
                                              className={classes.dropItems}
                                              onClick={(event) => {
                                                window.open(
                                                  "http://procarteira.inefop.gov.ao/",
                                                  "_blank",
                                                  "noreferrer"
                                                );
                                                handleCloseInformation(event);
                                              }}
                                            >
                                              {intl.formatMessage(
                                                titles.Title_Menu_Information_CitizenServices_SeeProfessionalWallet
                                              )}
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    )}
                                  </div>
                                )}

                                {/* Submenu Group: Employment Services */}
                                <div ref={dropdownRefEmploymentServices}>
                                  <MenuItem
                                    className={classes.dropItems}
                                    onMouseOver={() => setMenuDropDownOpenEmploymentServices(true)}
                                    onClick={handleCloseInformation}
                                  >
                                    {intl.formatMessage(labels.Label_Menu_Information_EmploymentServices)}
                                    {isMenuDropDownOpenEmploymentServices ? (
                                      <NavigateBeforeIcon className={classes.expandIcon} />
                                    ) : (
                                      <NavigateNextIcon className={classes.expandIcon} />
                                    )}
                                  </MenuItem>

                                  {isMenuDropDownOpenEmploymentServices && (
                                    <Paper className={classes.submenuGroup} style={{ top: "34px" }}>
                                      <ClickAwayListener onClickAway={handleCloseInformation}>
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={handleListKeyDown}
                                        >
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-employment-centers`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_EmploymentCenters)}
                                          </MenuItem>
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-employment-centers-network`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_EmploymentCentersNetwork)}
                                          </MenuItem>
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-foreign-workers-contract-registration`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(
                                              labels.Label_Menu_Information_ForeignWorkersContractRegistration
                                            )}
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  )}
                                </div>

                                {/* Submenu Group: Entrepreneurship Services */}
                                <div ref={dropdownRefEntrepreneurshipServices}>
                                  <MenuItem
                                    className={classes.dropItems}
                                    onMouseOver={() => setMenuDropDownOpenEntrepreneurshipServices(true)}
                                    onClick={handleCloseInformation}
                                  >
                                    {intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipServices)}
                                    {isMenuDropDownOpenEntrepreneurshipServices ? (
                                      <NavigateBeforeIcon className={classes.expandIcon} />
                                    ) : (
                                      <NavigateNextIcon className={classes.expandIcon} />
                                    )}
                                  </MenuItem>

                                  {isMenuDropDownOpenEntrepreneurshipServices && (
                                    <Paper className={classes.submenuGroup} style={{ top: "68px" }}>
                                      <ClickAwayListener onClickAway={handleCloseInformation}>
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={handleListKeyDown}
                                        >
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-entrepreneurship-centers`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCenters)}
                                          </MenuItem>
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-entrepreneurship-centers-network`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(
                                              labels.Label_Menu_Information_EntrepreneurshipCentersNetwork
                                            )}
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  )}
                                </div>

                                {/* Submenu Group: Professional Training */}
                                <div ref={dropdownRefProfessionalTraining}>
                                  <MenuItem
                                    className={classes.dropItems}
                                    onMouseOver={() => setMenuDropDownOpenProfessionalTraining(true)}
                                    onClick={handleCloseInformation}
                                  >
                                    {intl.formatMessage(labels.Label_Menu_Information_ProfessionalTraining)}
                                    {isMenuDropDownOpenProfessionalTraining ? (
                                      <NavigateBeforeIcon className={classes.expandIcon} />
                                    ) : (
                                      <NavigateNextIcon className={classes.expandIcon} />
                                    )}
                                  </MenuItem>

                                  {isMenuDropDownOpenProfessionalTraining && (
                                    <Paper className={classes.submenuGroup} style={{ top: "102px" }}>
                                      <ClickAwayListener onClickAway={handleCloseInformation}>
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={handleListKeyDown}
                                        >
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-professional-wallet`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_ProfessionalWallet)}
                                          </MenuItem>
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-formation-offer`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_FormationOffer)}
                                          </MenuItem>
                                          <MenuItem
                                            component={Link}
                                            to={`/admin/information-training-centers-licensing`}
                                            className={classes.dropItems}
                                            onClick={handleCloseInformation}
                                          >
                                            {intl.formatMessage(labels.Label_Menu_Information_TrainingCentersLicensing)}
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  )}
                                </div>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ListItem>
                </Box>
              </Hidden>

              {/* Projetos Estruturantes */}
              <Hidden mdDown implementation="css">
                <Box>
                  <ListItem
                    to="/admin/structuringProjects"
                    component={Link}
                    classes={{
                      root: classes.listItemRoot,
                    }}
                  >
                    {" "}
                    {intl.formatMessage(labels.Label_Menu_StructuringProjects)}
                  </ListItem>
                </Box>
              </Hidden>

              {/* Publicações */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.INFORMATION_DISSEMINATION_MENU
                  ) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefInfoDissemination}
                      aria-controls={openInfoDissemination ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleInfoDissemination}
                    >
                      {intl.formatMessage(labels.Label_Menu_Posts)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openInfoDissemination}
                        anchorEl={anchorRefInfoDissemination.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseInfoDissemination}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.INFORMATION_DISSEMINATION_BOARD_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to={`/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS}`}
                                      className={classes.dropItems}
                                      onClick={handleCloseInfoDissemination}
                                    >
                                      {intl.formatMessage(labels.Label_Menu_Posts_News)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.INFORMATION_DISSEMINATION_LEGISLATION_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to={`/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION}`}
                                      className={classes.dropItems}
                                      onClick={handleCloseInfoDissemination}
                                    >
                                      {intl.formatMessage(labels.Label_Menu_Posts_Legislations)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.INFORMATION_DISSEMINATION_AGENDA_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to={`/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE}`}
                                      className={classes.dropItems}
                                      onClick={handleCloseInfoDissemination}
                                    >
                                      {intl.formatMessage(labels.Label_Menu_Posts_Schedule)}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Serviços Para Empresas */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_MENU
                  ) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefCompanyServicesMenu}
                      aria-controls={openCompanyServicesMenu ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleCompanyServicesMenu}
                    >
                      Serviços Para Empresas
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openCompanyServicesMenu}
                        anchorEl={anchorRefCompanyServicesMenu.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseCompanyServicesMenu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {/* Submenu Group: Contract Approval */}
                                  <div ref={dropdownRefCompanyServices}>
                                    <MenuItem
                                      className={classes.dropItems}
                                      onMouseOver={() => setMenuDropDownOpenCompanyServices(true)}
                                      onClick={handleCloseCompanyServicesMenu}
                                    >
                                      Homologação de contratos
                                      {isMenuDropDownOpenCompanyServices ? (
                                        <NavigateBeforeIcon className={classes.expandIcon} />
                                      ) : (
                                        <NavigateNextIcon className={classes.expandIcon} />
                                      )}
                                    </MenuItem>

                                    {isMenuDropDownOpenCompanyServices && (
                                      <Paper
                                        className={classes.submenuGroup}
                                        style={{
                                          left: "220px",
                                        }}
                                      >
                                        <ClickAwayListener onClickAway={handleCloseCompanyServicesMenu}>
                                          <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/contract-approval/create`}
                                              className={classes.dropItems}
                                              onClick={(e) => {
                                                handleCloseCompanyServicesMenu(e);
                                                clearContractApprovalState();
                                              }}
                                            >
                                              Criar Pedido de Homologação
                                            </MenuItem>
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/contract-approval/list`}
                                              className={classes.dropItems}
                                              onClick={handleCloseCompanyServicesMenu}
                                            >
                                              Lista de Pedidos Homologação
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    )}
                                  </div>

                                  <div ref={dropdownRefRentService}>
                                    <MenuItem
                                      className={classes.dropItems}
                                      onMouseOver={() => setMenuDropDownOpenRentService(true)}
                                      onClick={handleCloseCompanyServicesMenu}
                                    >
                                      RENT
                                      {isMenuDropDownOpenRentService ? (
                                        <NavigateBeforeIcon className={classes.expandIcon} />
                                      ) : (
                                        <NavigateNextIcon className={classes.expandIcon} />
                                      )}
                                    </MenuItem>

                                    {isMenuDropDownOpenRentService && (
                                      <Paper
                                        className={classes.submenuGroup}
                                        style={{
                                          left: "220px",
                                        }}
                                      >
                                        <ClickAwayListener onClickAway={handleCloseCompanyServicesMenu}>
                                          <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem
                                              component={Link}
                                              to={`/admin/rent/list`}
                                              className={classes.dropItems}
                                              onClick={handleCloseCompanyServicesMenu}
                                            >
                                              Lista de Mapas RENT
                                            </MenuItem>
                                            <ConditionGuard condition={canSubmitRENT?.canSubmit}>
                                              <MenuItem
                                                component={Link}
                                                to={`/admin/rent/create`}
                                                className={classes.dropItems}
                                                onClick={handleCloseCompanyServicesMenu}
                                              >
                                                Criar Mapa RENT
                                              </MenuItem>
                                            </ConditionGuard>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    )}
                                  </div>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Bolsa Nacional de Formadores */}
              {/* <Hidden mdDown implementation="css">
                <Box>
                  <ListItem
                    to="/admin/national-scholarship-of-trainers"
                    component={Link}
                    classes={{
                      root: classes.listItemRoot,
                    }}
                  >
                    {intl.formatMessage(labels.Label_Menu_Help_NationalScholarshipTrainers)}
                  </ListItem>
                </Box>
              </Hidden> */}

              {/* Contactos */}
              <Hidden mdDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.CONTACTS_MENU) && (
                    <ListItem
                      to="/admin/contacts"
                      component={Link}
                      classes={{
                        root: classes.listItemRoot,
                      }}
                    >
                      {" "}
                      Contactos
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Ajuda */}
              <Hidden mdDown implementation="css">
                <Box>
                  <ListItem
                    classes={{ root: classes.listItemRoot }}
                    ref={anchorRefHelp}
                    aria-controls={openHelp ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleHelp}
                  >
                    {intl.formatMessage(labels.Label_Menu_Help)}
                    <ListItemIcon className={classes.dropIcon}>
                      <ExpandMoreIcon />
                    </ListItemIcon>
                    <Popper open={openHelp} anchorEl={anchorRefHelp.current} role={undefined} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleCloseHelp}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem
                                  component={Link}
                                  to={`/admin/frequently-asked-questions`}
                                  className={classes.dropItems}
                                  onClick={handleCloseHelp}
                                >
                                  {intl.formatMessage(labels.Label_Menu_Help_FAQs)}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={`/admin/useful-links`}
                                  className={classes.dropItems}
                                  onClick={handleCloseHelp}
                                >
                                  {intl.formatMessage(labels.Label_Menu_Help_UsefulLinks)}
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ListItem>
                </Box>
              </Hidden>

              {/* Barra de Pesquisa */}
              <Box display="flex" justifyContent="center" alignItems="center" marginRight="auto">
                <div className={classes.navbarDiv}>
                  <SearchBarNavbar />
                </div>
              </Box>
            </Box>
          </Container>
          {/* Generic dialog START*/}
          <GenericDialog />
          {/* Generic dialog END*/}
        </Toolbar>
      </AppBar>
    </>
  );
}

AdminNavbar.defaultProps = {
  openSidebarResponsive: () => {},
};

AdminNavbar.propTypes = {
  // use this to make the Sidebar open on responsive mode
  openSidebarResponsive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  governmentPrograms: () => dispatch(getGovernmentProgramsAction()),
  dataForApplicationCreation: (data) => dispatch(dataForApplicationCreationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
