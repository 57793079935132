import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { DIALOG } from "utils/actionsConsts";

export default function useMessage() {
  const dispatch = useDispatch();

  const showSuccessMessage = useCallback(
    (
      message,
      { goBack = false, buttonLabel = "Ok", ...rest } = {
        goBack: false,
        buttonLabel: "Ok",
      }
    ) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack,
            buttonLabel,
            message,
            ...rest,
          },
        },
      });
    },
    [dispatch]
  );

  const showErrorMessage = useCallback(
    (
      message,
      { goBack = false, buttonLabel = "Ok", ...rest } = {
        goBack: false,
        buttonLabel: "Ok",
      }
    ) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            goBack,
            buttonLabel,
            message,
            ...rest,
          },
        },
      });
    },
    [dispatch]
  );

  return { showSuccessMessage, showErrorMessage };
}
