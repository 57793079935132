import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export async function createMapRent(data) {
  const response = await axios.post(`${EXTERNAL_API_URL}rent`, data);

  return response.data;
}

export async function getFilteredMapRent(data) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent`, {
    params: data,
  });

  return response.data;
}

export async function getMapRentById(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/details`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function getPaymentInfo(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-payment-info`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function submitPayment(data) {
  const response = await axios.post(`${EXTERNAL_API_URL}rent/submit-payment-info`, data);

  return response.data;
}

export async function getPaymentDocuments(documentId) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-payment-document`, {
    params: {
      documentId,
    },
    responseType: "blob",
  });

  return response.data;
}

export async function getWorkCenterDetails(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-workcenter-details`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function getEmployeeDetails(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-employee-details`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function getRentCertificateInfo(processNumber) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-rent-certificate-info`, {
    params: {
      processNumber,
    },
  });

  return response.data;
}

export async function getRentCertificateInfoFromToken(id) {
  const response = await axios.get(EXTERNAL_API_URL + "rent/get-rent-certificate-info-from-token", {
    params: { CertificateNumberToken: id },
  });

  return response.data;
}

export async function getRentCertificateDocument(alternateKey) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-rent-certificate`, {
    params: {
      alternateKey,
    },
    responseType: "blob",
  });

  return response.data;
}

export async function getRentCertificateDocumentFromToken(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-rent-certificate-from-token`, {
    params: {
      CertificateNumberToken: id,
    },
    responseType: "blob",
  });

  return response.data;
}

export async function getRentCertificateDocumentById(documentId) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-rent-certificate`, {
    params: {
      documentId,
    },
    responseType: "blob",
  });

  return response.data;
}

export async function getRENTGetDetailsByName(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-map-rent-details-by-name`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function getFilteredWorkcentersSnapshot(params) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-filtered-workcenters-snapshot`, {
    params,
  });

  return response.data;
}

export async function getFilteredEmployeeSnapshot(params) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-filtered-employees-snapshot`, {
    params,
  });

  return response.data;
}

export async function getWorkCenterSnapshotDetails(id) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-workcenter-snapshot-details`, {
    params: {
      id,
    },
  });

  return response.data;
}

export async function editWorkCenterSnapshot(data) {
  const response = await axios.put(`${EXTERNAL_API_URL}rent/edit-workcenter-snapshot`, data);

  return response.data;
}

export async function getUnassignedWorkCenters(params) {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/get-unassigned-workcenters`, {
    params,
  });

  return response.data;
}

export async function addWorkCenterToMapRent(data) {
  const response = await axios.post(`${EXTERNAL_API_URL}rent/add-workcenter-to-map-rent`, data);

  return response.data;
}

export async function updateMapRent(data) {
  const response = await axios.put(`${EXTERNAL_API_URL}rent/update-map-rent`, data);

  return response.data;
}

export async function getCheckRENTSubmission() {
  const response = await axios.get(`${EXTERNAL_API_URL}rent/check-rent-submission`);

  return response.data;
}

export async function editEmployeeSnapshot(data) {
  const response = await axios.put(`${EXTERNAL_API_URL}rent/edit-employee-snapshot`, data);

  return response.data;
}
