import React from "react";

import { makeStyles, Tooltip } from "@material-ui/core";
import { DescriptionOutlined, ErrorOutline } from "@material-ui/icons";
import GenericButton from "components/Buttons/GenericButton";

const useStyle = makeStyles(() => ({
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
  },
}));

export default function DocumentList({ hasDocuments, promise, documents, onClick, state, onDownload, error }) {
  const classes = useStyle();

  return (
    <>
      {hasDocuments && promise
        ? documents.map(({ documentId, documentName }) => (
            <GenericButton
              onClick={() => onClick(documentId, documentName)}
              key={documentId}
              startIcon={<DescriptionOutlined />}
              color="tertiary"
              loading={state.get(documentId)}
              className={classes.item}
              endIcon={
                error.get(documentId) && (
                  <Tooltip title="Ocorreu um erro ao descarregar o documento">
                    <ErrorOutline color="error" />
                  </Tooltip>
                )
              }
            >
              {documentName}
            </GenericButton>
          ))
        : null}

      {hasDocuments && !promise
        ? documents.map((item, key) => {
            const file = item instanceof File ? item : item.file;

            return (
              <GenericButton
                className={classes.item}
                onClick={() => onDownload(file)}
                key={key}
                startIcon={<DescriptionOutlined />}
                color="tertiary"
              >
                {file.name}
              </GenericButton>
            );
          })
        : null}
    </>
  );
}
