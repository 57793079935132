import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useDataset } from "hooks/useDataset";
import { listContractApproval } from "crud/contractApproval.crud";
import FormDropdown from "components/FormFields/FormDropdown";

import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { DATASET } from "utils/const";
import FormDatePickerV2 from "components/FormFields/FormDatePickerV2";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const FilterSection = () => {
  const classes = useStylesGeneric();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    trigger,
    reset,
  } = useFormContext();

  const { data: contractApprovalStatus } = useDataset({ name: DATASET.CONTRACT_APPROVAL_STATUS });
  const { data: contractType } = useDataset({ name: DATASET.CONTRACT_TYPE });

  const { refetch } = useQuery(
    ["listContractApproval"],
    () =>
      listContractApproval({
        ...getValues(),
        startDate: getValues("startDate") ? getValues("startDate")?.format() : null,
        endDate: getValues("endDate") ? getValues("endDate")?.format() : null,
      }),
    {
      enabled: false,
    }
  );

  const onSubmit = async () => {
    await refetch();
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <Grid container>
            <Box>Pesquisa de Pedidos de Homologação de contratos</Box>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form id="filter-contractApprovals" onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Nº. do Pedido</FormLabel>
                  <Controller
                    name="requestNumber"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Insira o nº. do pedido"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormDatePickerV2
                  name="startDate"
                  label="Data de Submissão (Desde)"
                  control={control}
                  maxDateToday={true}
                  minDateToday={false}
                  endDateFieldName="endDate"
                  errors={errors}
                  classes={classes}
                  trigger={trigger}
                  getValues={getValues}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormDatePickerV2
                  name="endDate"
                  label="Data de Submissão (Até)"
                  control={control}
                  maxDateToday={true}
                  minDateToday={false}
                  startDateFieldName="startDate"
                  errors={errors}
                  classes={classes}
                  trigger={trigger}
                  getValues={getValues}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Nome do Trabalhador</FormLabel>
                  <Controller
                    name="workerName"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Insira o nome do trabalhador"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>NISS</FormLabel>
                  <Controller
                    name="ssn"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Insira o NISS do trabalhador"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>NIF</FormLabel>
                  <Controller
                    name="nif"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Insira o NIF do trabalhador"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="contractTypeId"
                  label="Tipo de Contrato"
                  mappingTo="code"
                  control={control}
                  options={contractType}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="contractApprovalStatusId"
                  label="Estado"
                  mappingTo="code"
                  control={control}
                  options={contractApprovalStatus}
                />
              </Grid>

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton onClick={() => reset()} color="ghost">
                  Limpar Filtros
                </GenericButton>
                <GenericButton form="filter-contractApprovals" onClick={handleSubmit(onSubmit)} color="primary">
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FilterSection;
