import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { FormHelperText, FormGroup, FormLabel, Box, OutlinedInput, InputAdornment } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DateRange } from "@material-ui/icons";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages, errorMessages } from "resources/resources";
import { REGEX_PATTERNS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function FormDatePicker({
  name,
  label,
  control,
  rules,
  getValues,
  trigger,
  maxDateToday,
  minDateToday,
  startDateFieldName,
  endDateFieldName,
  isRequired,
  disabled,
  placeholder,
  dateFormat = "DD/MM/YYYY",
}) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  const isValidDate = (current) => {
    const selectedDate = current.toDate();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for accurate comparison

    if (maxDateToday && !(selectedDate <= currentDate)) return false;

    if (minDateToday && !(selectedDate >= currentDate)) return false;

    if (
      startDateFieldName &&
      getValues(startDateFieldName)?._isAMomentObject &&
      !(getValues(startDateFieldName) <= selectedDate)
    )
      return false;

    if (
      endDateFieldName &&
      getValues(endDateFieldName)?._isAMomentObject &&
      !(selectedDate <= getValues(endDateFieldName))
    )
      return false;

    return true;
  };

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        rules={{
          ...rules,
          required: {
            value: isRequired,
            message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
          },
          validate: {
            isValidDateFormat: (value) => {
              return !value || value._isAMomentObject || intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
            },
            maxDate: (value) => {
              return (
                !maxDateToday ||
                !value ||
                value <= moment().startOf("day") ||
                intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
              );
            },
            minDate: (value) => {
              return (
                !minDateToday ||
                !value ||
                value >= moment().startOf("day") ||
                intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToTodayField)
              );
            },
            isLessThanEndDate: (value) => {
              return (
                !endDateFieldName ||
                !getValues(endDateFieldName) ||
                !value ||
                (value !== "" &&
                  value._isAMomentObject &&
                  getValues(endDateFieldName)._isAMomentObject &&
                  value <= getValues(endDateFieldName)) ||
                intl.formatMessage(messages.Message_Generic_DateFromLessThanOrEqualToDateToField)
              );
            },
            isGreaterThanStartDate: (value) => {
              return (
                !startDateFieldName ||
                !getValues(startDateFieldName) ||
                !value ||
                (value !== "" &&
                  value._isAMomentObject &&
                  getValues(startDateFieldName)._isAMomentObject &&
                  value >= getValues(startDateFieldName)) ||
                intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToDateFromField)
              );
            },
          },
        }}
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <>
            <ReactDatetime
              {...field}
              utc
              onChange={(e) => {
                onChange(e);
                if (startDateFieldName && getValues(startDateFieldName)) trigger(startDateFieldName);

                if (endDateFieldName && getValues(endDateFieldName)) trigger(endDateFieldName);

                trigger(name);
              }}
              locale="pt"
              dateFormat={dateFormat}
              timeFormat={false}
              isValidDate={isValidDate}
              closeOnSelect={true}
              closeOnTab={true}
              closeOnClickOutside={true}
              inputProps={{
                placeholder: placeholder ?? dateFormat,
                className: "",
                disabled,
              }}
              renderInput={(dateInputProps) => (
                <OutlinedInput
                  {...dateInputProps}
                  onClick={!disabled ? dateInputProps.onClick : undefined}
                  onFocus={!disabled ? dateInputProps.onFocus : undefined}
                  onKeyDown={!disabled ? dateInputProps.onKeyDown : undefined}
                  onChange={(e) => {
                    handleValidDate(e.target.value, dateInputProps.onChange);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component={DateRange} marginRight=".5rem" />
                    </InputAdornment>
                  }
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: !!error,
                    }),
                  }}
                  fullWidth
                />
              )}
            />
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {error?.message}
            </FormHelperText>
          </>
        )}
      />
    </FormGroup>
  );
}
