import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";

//import custom components
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { DATASET } from "utils/const";
import { useDataset } from "hooks/useDataset";
import { getKeyValueByCode } from "utils/datasets";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { useQuery } from "@tanstack/react-query";
import { listWorkCenters } from "crud/workCenters.crud";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const FilterSection = () => {
  const classes = useStylesGeneric();

  const { handleSubmit, setValue, reset, control, watch, getValues } = useFormContext();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: ECONOMIC_ACTIVITIES_CLASSIFICATION } = useDataset({ name: DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION });

  const { refetch, isLoading } = useQuery(["listWorkCenters"], () => listWorkCenters(getValues()), {
    staleTime: 0,
    refetchOnMount: true,
  });

  const onSubmit = async () => {
    setValue("pageIndex", 1);

    await refetch();
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <Grid container>
            <Box>Pesquisa de Centros de Trabalho</Box>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormInputV2
                  name="name"
                  label="Nome do Centro"
                  placeholder="Insira o nome do centro"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown name="provinceId" label="Província" control={control} options={PROVINCES} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="municipalityId"
                  label="Município"
                  control={control}
                  options={MUNICIPALITIES}
                  disabled={!provinceId}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="secundaryActivityId"
                  label="Actividade Secundária"
                  control={control}
                  options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormDropdown
                  name="tertiaryActivityId"
                  label="Actividade Terciária"
                  control={control}
                  options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                />
              </Grid>

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton type="button" color="ghost" onClick={() => reset()}>
                  Limpar Filtros
                </GenericButton>
                <GenericButton form="filter-employees" onClick={handleSubmit(onSubmit)} color="primary" loading={isLoading}>
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FilterSection;
