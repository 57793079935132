import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { FormProvider, useForm } from "react-hook-form";
import { DATASET } from "utils/const";
import FormDatePicker from "components/Forms/FormDatePicker";
import moment from "moment";
import { jsonToFormData } from "utils/formData";
import { useDataset } from "hooks/useDataset";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { getKeyValueByCode } from "utils/datasets";
import EmployeesAreaDocuments from "./EmployeesAreaDocuments";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID } from "utils/const";
import { createWorkCenterEmployer } from "crud/workCentersEmployer.crud";
import { useDispatch } from "react-redux";
import { DIALOG } from "utils/actionsConsts";
import { getWorkCenterEmployerById } from "crud/workCentersEmployer.crud";
import _ from "lodash";
import { ConditionGuard } from "guards/ConditionGuard";
import { updateWorkCenterEmployer } from "crud/workCentersEmployer.crud";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES } from "utils/const";
import { inactiveWorkCenterEmployee } from "crud/workCentersEmployer.crud";
import { INPUT_MASKS } from "utils/const";
import FormMaskedInput from "components/FormFields/FormMaskedInput";
import HelpIcon from "@material-ui/icons/Help";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesUserProfile = makeStyles(componentStylesUserProfile);

const WorkCenterEmployerDialog = ({ redirect = false, openDialog, handleCloseDialog, selectedWorkCenterEmployer }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };

  const methods = useForm({
    defaultValues: {},
    shouldFocusError: false,
  });

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const { data: employee, isLoading: isLoadingEmployeeInfo } = useQuery(
    ["getWorkCenterEmployerById"],
    () => getWorkCenterEmployerById(selectedWorkCenterEmployer),
    {
      cacheTime: 0, // Fetch new data on every render
      enabled: !!selectedWorkCenterEmployer,
      select: (response) => response?.data ?? {},
      onSuccess: (data) => {
        methods.reset({
          ..._.omit(
            data,
            "workCenterDescription",
            "employeeStatusDescription",
            "professionalCategoryDescription",
            "mainCompanyActivity"
          ),
          dateOfBirth: moment(data?.dateOfBirth),
          identityDocumentIssueDate: moment(data?.identityDocumentIssueDate),
          identityDocumentExpiryDate: moment(data?.identityDocumentExpiryDate),
          addmissionDate: moment(data?.addmissionDate),
          employmentStatusId: data?.employeeStatusId,
        });
      },
    }
  );

  const { mutateAsync: createWorkCenterEmployerAsync } = useMutation(
    ["createWorkCenterEmployer"],
    (values) => createWorkCenterEmployer(values),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: redirect,
              buttonLabel: "Ok",
              onClose: redirect ? "/admin/user-profile" : null,
              message: "Trabalhador adicionado com sucesso.",
            },
          },
        });

        queryClient.invalidateQueries({
          queryKey: ["listWorkCenterEmployers"],
        });
      },
      onError: (error) => {
        console.error(error);
        let errorMessage = "Ocurreu um erro ao criar o trabalhador.";
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          errorMessage = Object.values(errors).flat().join(". ");
        }
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              buttonLabel: "Ok",
              message: errorMessage,
            },
          },
        });
      },
    }
  );

  const { mutateAsync: updateWorkCenterEmployerAsync } = useMutation(
    ["updateWorkCenterEmployer"],
    (values) => updateWorkCenterEmployer(values),
    {
      onSuccess: () => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              goBack: redirect,
              buttonLabel: "Ok",
              onClose: redirect ? "/admin/user-profile" : null,
              message: "Trabalhador actualizado com sucesso.",
            },
          },
        });

        queryClient.invalidateQueries({
          queryKey: ["listWorkCenterEmployers"],
        });
      },
      onError: (error) => {
        console.log(error);

        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
            },
          },
        });
      },
    }
  );

  const { data: WORK_CENTERS } = useQuery(["my-workcenters"], {
    select: (data) => {
      if (!data) return [];

      return data.map((item) => ({
        code: item.id,
        label: item.value,
      }));
    },
  });

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = methods.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: MARITAL_STATUS } = useDataset({ name: DATASET.MARITAL_STATUS });
  const { data: GENDER } = useDataset({ name: DATASET.GENDER });
  const { data: ACADEMIC_LEVEL } = useDataset({ name: DATASET.ACADEMIC_LEVEL });
  const { data: NATIONALITIES } = useDataset({ name: DATASET.NATIONALITIES });
  const { data: PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.EMPLOYMENT_PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_STATUS } = useDataset({ name: DATASET.EMPLOYMENT_STATUS });
  const { data: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES } = useDataset({ name: DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES });

  const nationalityId = methods.watch("nationalityId");

  const angola = NATIONALITIES?.find((n) => n.keyValue === "Nationalities_06");

  const isAngola = nationalityId === (angola?.code ?? null);

  useEffect(() => {
    if (!selectedWorkCenterEmployer) {
      setIsCreating(true);
    }
  }, [selectedWorkCenterEmployer]);

  const onSubmit = async (data) => {
    try {
      const documentTypeId = data?.documentTypeId;

      let documents = [];
      let employeeDocuments = [];

      if (documentTypeId !== employee?.documentTypeId) {
        const requiredDocuments = requestFormDocuments.map((doc) => doc.keyValue);

        let hasError = false;
        requiredDocuments.forEach((docKey) => {
          if (!data[docKey] || data[docKey].length === 0) {
            methods.setError(docKey, {
              type: "required",
              message: `O campo ${docKey} é obrigatório.`,
            });
            hasError = true;
          }
        });

        if (hasError) return;
      }

      const getId = (id) => {
        return data?.documents?.find((item) => item.documentTypeId === id)?.documentId;
      };

      switch (documentTypeId) {
        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT]) {
            employeeDocuments.push(
              {
                documentIndex: 0,
                documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT,
                documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT) ?? null,
              },
              {
                documentIndex: 1,
                documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA,
                documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA) ?? null,
              }
            );

            const [passport] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT];
            const [visa] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.VISA];

            documents.push(passport, visa);
          }
          break;
        }

        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.RESIDENT_CARD]) {
            employeeDocuments.push({
              documentIndex: 0,
              documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD,
              documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD) ?? null,
            });

            const [file] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES?.RESIDENT_CARD];

            documents.push(file);
          }
          break;
        }
        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.OTHERS]) {
            employeeDocuments.push({
              documentIndex: 0,
              documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS,
              documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS) ?? null,
            });

            const [file] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES?.OTHERS];

            documents.push(file);
          }
          break;
        }

        default:
          break;
      }

      const worker = jsonToFormData({
        data: {
          ...data,
          id: selectedWorkCenterEmployer,
          dateOfBirth: moment.utc(data?.dateOfBirth).format(),
          identityDocumentIssueDate: moment.utc(data?.identityDocumentIssueDate).format(),
          identityDocumentExpiryDate: moment.utc(data?.identityDocumentExpiryDate).format(),
          addmissionDate: moment.utc(data?.addmissionDate).format(),
          employeeDocuments,
          workVisaNumber: documentTypeId === COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT ? data?.workVisaNumber : "",
          secondaryFunctionId: data?.secondaryFunctionId ?? null,
          tertiaryFunctionId: data?.tertiaryFunctionId ?? null,
          provinceId: data?.provinceId ?? null,
          municipalityId: data?.municipalityId ?? null,
          address: data?.address ?? "",
        },
        documents,
      });

      if (isEditing) {
        await updateWorkCenterEmployerAsync(worker);
      } else {
        await createWorkCenterEmployerAsync(worker);
      }

      handleCloseDialog();
    } catch (error) {
      console.log(error);
    }
  };

  const documentTypeId = methods.watch("documentTypeId");

  const isPassport = documentTypeId === COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT;

  const requestFormDocuments = useMemo(() => {
    switch (documentTypeId) {
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT,
            name: "Passaporte",
          },
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.VISA,
            name: "Visto",
          },
        ];
      }
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.RESIDENT_CARD,
            name: "Cartão de Residente",
          },
        ];
      }
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.OTHERS,
            name: "Documento de Identificação",
          },
        ];
      }
      default:
        return [];
    }
  }, [documentTypeId]);

  const handleSuccess = async () => {
    await dispatch({
      type: DIALOG.SHOW,
      payload: {
        showGenericDialog: true,
        genericDialogData: {
          type: DIALOG.ONE_BUTTON_TYPE,
          isSuccess: true,
          buttonLabel: "Ok",
          message: "Trabalhador removido com sucesso.",
        },
      },
    });

    await queryClient.refetchQueries({
      queryKey: ["listWorkCenterEmployers"],
      exact: true,
    });

    handleCloseDialog();
  };

  const { mutateAsync: inactiveWorkCenterEmployeeMutation, isLoading: inactiveWorkCenterEmployeeLoading } = useMutation(
    {
      mutationFn: async () => {
        if (!selectedWorkCenterEmployer) {
          throw new Error("O id do centro de trabalho não está definido.");
        }
        return await inactiveWorkCenterEmployee(selectedWorkCenterEmployer);
      },
      mutationKey: ["inactiveWorkCenterEmployee"],
      onSuccess: handleSuccess,
      onError: (error) => {
        let errorMessage = "Ocurreu um erro ao eliminar o trabalhador.";
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          errorMessage = Object.values(errors).flat().join(". ");
        }
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              buttonLabel: "Ok",
              message: errorMessage,
            },
          },
        });
      },
    }
  );

  return (
    <FormProvider {...methods}>
      <form>
        <GenericDialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PageTitleHeader
              title={isCreating ? "Adicionar Trabalhador" : isEditing ? "Editar Trabalhador" : "Trabalhador"}
              style={{
                marginLeft: "-22px",
              }}
            />
            {!isCreating && !isEditing && (
              <Box
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <GenericButton
                  typeSubmit={false}
                  size="medium"
                  color="tertiary"
                  onClick={() => setIsEditing(!isEditing)}
                  style={{
                    marginBottom: "37px",
                  }}
                >
                  Editar
                </GenericButton>
                <GenericButton
                  typeSubmit={false}
                  size="medium"
                  color="tertiaryRed"
                  onClick={() => inactiveWorkCenterEmployeeMutation()}
                  style={{
                    marginBottom: "37px",
                  }}
                  loading={inactiveWorkCenterEmployeeLoading}
                >
                  Eliminar Trabalhador
                </GenericButton>
              </Box>
            )}
          </Box>
          <Box>
            <Grid item xs={12} md={12}>
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                Ao adicionar um novo registo de trabalhador, o sistema irá actualizar na área de utilizador a lista de
                trabalhadores registados da sua empresa.
              </Typography>
            </Grid>
          </Box>
          <ConditionGuard
            condition={!isLoadingEmployeeInfo || isCreating}
            fallback={
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          >
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Pessoais
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="name"
                  label="Nome do Trabalhador"
                  placeholder="Insira o nome do trabalhador"
                  required
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="nif"
                  label="NIF"
                  placeholder="Insira o NIF"
                  required
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="ssn"
                  label={
                    <>
                      NISS
                      <Tooltip title="Caso o trabalhador não esteja inscrito no INSS, colocar o nº Visto Trabalho no campo NISS.">
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </>
                  }                  placeholder="Insira o NISS"
                  required
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="address"
                  label={`Morada${!isAngola ? " (Opcional)" : ""}`}
                  placeholder="Insira a morada do trabalhador"
                  required={isAngola}
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label={`Província${!isAngola ? " (Opcional)" : ""}`}
                  name="provinceId"
                  options={PROVINCES}
                  required={isAngola}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label={`Município${!isAngola ? " (Opcional)" : ""}`}
                  name="municipalityId"
                  options={MUNICIPALITIES}
                  required={isAngola}
                  disabled={(!isCreating && !isEditing) || !provinceId}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  label="Nacionalidade"
                  control={methods.control}
                  name="nationalityId"
                  options={NATIONALITIES}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="dateOfBirth"
                  label="Data de Nascimento"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Género"
                  placeholder="Seleccione o género"
                  control={methods.control}
                  name="genderId"
                  options={GENDER}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Estado Civil"
                  placeholder="Seleccione o estado civil"
                  control={methods.control}
                  name="maritalStatusId"
                  options={MARITAL_STATUS}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Nível Académico"
                  placeholder="Seleccione o nível académico"
                  control={methods.control}
                  name="academicLevelId"
                  options={ACADEMIC_LEVEL}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Documento de Identificação"
                  placeholder="Seleccione o documento de identificação"
                  control={methods.control}
                  name="documentTypeId"
                  options={COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  name="identityDocumentNumber"
                  label="N.º Documento de Identificação"
                  placeholder="Insira n.º de documento"
                  mask={INPUT_MASKS.APLHA_NUMERIC}
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentIssueDate"
                  label="Data de Emissão"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  endDatename="endDate"
                  isRequired={true}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="identityDocumentExpiryDate"
                  label="Data de Validade"
                  control={methods.control}
                  rules={{ required: false }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={false}
                  minDateToday={true}
                  endDatename="endDate"
                  isRequired={true}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>

              <ConditionGuard condition={isPassport}>
                <Grid item xs={12} md={3}>
                  <FormInputV2
                    name="workVisaNumber"
                    label="Nº Visto de Trabalho"
                    placeholder="Insira nº do Visto de Trabalho"
                    control={methods.control}
                    disabled={!isCreating && !isEditing}
                    required
                  />
                </Grid>
              </ConditionGuard>

              <Grid item xs={12} md={12}>
                <EmployeesAreaDocuments
                  requestFormDocuments={requestFormDocuments}
                  isEditing={isEditing}
                  isCreating={isCreating}
                  isRequired={isEditing ? false : true}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Profissionais
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormDatePicker
                  name="addmissionDate"
                  label="Data de Admissão"
                  control={methods.control}
                  rules={{ required: true }}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  trigger={methods.trigger}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Centro de Trabalho"
                  placeholder="Seleccione o centro de trabalho"
                  control={methods.control}
                  name="workCenterId"
                  options={WORK_CENTERS}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Situação Profissional"
                  placeholder="Seleccione a situação profissional"
                  control={methods.control}
                  name="employmentStatusId"
                  options={EMPLOYMENT_STATUS}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Categoria Profissional"
                  placeholder="Seleccione a Categoria Profissional"
                  control={methods.control}
                  name="professionalCategoryId"
                  options={EMPLOYMENT_PROFESSIONAL_CATEGORY}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Primária"
                  placeholder="Seleccione a profissão primária"
                  control={methods.control}
                  name="primaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  required
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Secundária (Opcional)"
                  placeholder="Seleccione a profissão secundária"
                  control={methods.control}
                  name="secondaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Terciária (Opcional)"
                  placeholder="Seleccione a profissão terciária"
                  control={methods.control}
                  name="tertiaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  name="baseSalary"
                  label="Salário Expresso no Contrato"
                  placeholder="Insira o salário base"
                  required
                  control={methods.control}
                  disabled={!isCreating && !isEditing}
                  mask={INPUT_MASKS.MONETARY}
                  rules={{
                    validate: {
                      minimumSalary: (value) => {
                        const numericValue = parseInt(value.replace(/[^0-9]/g, ""));
                        if (numericValue < 70000) {
                          return "Valor deverá ser igual ou superior ao salário mínimo";
                        }
                        return true;
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Outros Dados
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="normalWorkingHours"
                  label="Horas Normais (Opcional)"
                  placeholder="Insira as horas normais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="weeklyWorkingHours"
                  label="Horas Semanais (Opcional)"
                  placeholder="Insira as horas semanais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="regularProvision"
                  label="Prestação Regular (Opcional)"
                  placeholder="Insira a prestação regular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="irregularProvision"
                  label="Prestação Irregular (Opcional)"
                  placeholder="Insira a prestação irregular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  disabled={!isCreating && !isEditing}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton
                  typeSubmit={false}
                  size="medium"
                  color="tertiary"
                  onClick={isEditing ? () => setIsEditing(false) : handleCloseDialog}
                >
                  {isCreating || isEditing ? "Cancelar" : "Fechar"}
                </GenericButton>
                {(isCreating || isEditing) && (
                  <GenericButton
                    size="medium"
                    color="primary"
                    onClick={methods.handleSubmit(onSubmit)}
                    loading={methods.formState.isSubmitting}
                  >
                    {isCreating ? "Adicionar Trabalhador" : "Editar Trabalhador"}
                  </GenericButton>
                )}
              </Grid>
            </Grid>
          </ConditionGuard>
        </GenericDialog>
      </form>
    </FormProvider>
  );
};

export default WorkCenterEmployerDialog;
