import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiStepper from "@material-ui/core/Stepper";
import { StepLabel, Step, StepConnector } from "@material-ui/core";
import Check from "@material-ui/icons/Check";

const QontoConnector = withStyles((theme) => ({
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: "#E9EBEC80",
    },

    step: {
      "& .MuiStepLabel-root": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
      },

      "& .MuiStepLabel-root.Mui-disabled": {
        "& .MuiStepLabel-iconContainer": {
          backgroundColor: theme.palette.gray[400],
          "& svg": {
            fill: theme.palette.gray[700],
          },
        },
      },

      "& .MuiStepLabel-iconContainer": {
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        padding: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,

        "& svg": {
          fill: theme.palette.background.default,
          width: "14px",
          height: "14px",
        },
      },

      "& .MuiStepLabel-labelContainer": {
        "& > span": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          fontWeight: 700,
          fontSize: "16px",
        },

        "& small": {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
  };
});

export default function Stepper({ children, steps, activeStep, showIndex = false, ...props }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MuiStepper
        className={classes.root}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        {...props}
      >
        {steps.map(({ title, subtitle }, key) => (
          <Step className={classes.step} key={key}>
            <StepLabel className={classes.stepLabel} StepIconComponent={Check}>
              {showIndex && key + 1}. {title}
              <small>{subtitle}</small>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>

      {children && children(activeStep)}
    </React.Fragment>
  );
}
