import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import { errorMessages } from "resources/resources";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import FormInput from "components/Forms/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWorkCenterSnapshotDetails, editWorkCenterSnapshot } from "crud/rent.crud";
import FormDropdown from "components/FormFields/FormDropdown";
import { useDataset } from "hooks/useDataset";
import { DATASET } from "utils/const";
import { getKeyValueByCode } from "utils/datasets";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ConditionGuard } from "guards/ConditionGuard";
import CenterSpinner from "components/Spinners/CenterSpinner";
import useMessage from "hooks/useMessage";

const WorkCenterDetailModal = ({ open, onClose, data: snapshotId }) => {
  const { showSuccessMessage, showErrorMessage } = useMessage();

  const methods = useForm({});

  const intl = useIntl();

  const queryClient = useQueryClient();
  const { id: rentId } = useParams();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });
  const { data: ECONOMIC_ACTIVITIES_CLASSIFICATION } = useDataset({ name: DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION });

  const provinceId = methods.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { isFetching } = useQuery(
    ["get-workcenter-snapshot-details", snapshotId],
    async () => getWorkCenterSnapshotDetails(snapshotId),
    {
      enabled: open && !!snapshotId,
      onSuccess: (data) => {
        methods.reset(data);
      },
    }
  );

  const workCenterIdWatcher = methods.watch("id");
  const workCenterId = useMemo(() => workCenterIdWatcher, [workCenterIdWatcher]);

  const { mutateAsync } = useMutation(
    ["edit-workcenter-snapshot", workCenterId],
    (data) => editWorkCenterSnapshot(data),
    {
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: ["get-filtered-workcenters-snapshot", rentId] });

        showSuccessMessage(
          "Centro de trabalho editado com sucesso.", { okAction: () => { onClose(); } }
        );
      },
      onError: () => {
        showErrorMessage("Ocorreu um erro ao editar o centro de trabalho");
      },
    }
  );

  const onSubmit = async (formData) => {
    await mutateAsync({
      ...formData,
      snapshotId,
      phoneNumber: String(formData?.phoneNumber),
    });
  };

  return (
    <FormProvider {...methods}>
      <GenericDialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PageTitleHeader
            title="Editar Centro de Trabalho"
            style={{
              marginLeft: "-22px",
            }}
          />
        </Box>

        <ConditionGuard condition={!isFetching} fallback={<CenterSpinner />}>
          <form id="workcenter-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="name"
                  label="Nome do centro"
                  placeholder="Insira o nome do centro"
                  required
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="address"
                  label="Morada"
                  placeholder="Insira a morada do centro"
                  required
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  name="provinceId"
                  label="Província"
                  required
                  control={methods.control}
                  options={PROVINCES}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  name="municipalityId"
                  label="Município"
                  required
                  control={methods.control}
                  options={MUNICIPALITIES}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="postalCode"
                  label="Caixa postal (Opcional)"
                  placeholder="Insira a caixa postal do centro"
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    pattern: {
                      value: /^\d{5}$/,
                      message: "A caixa postal deve ter 5 dígitos",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="phoneNumber"
                  label="Telefone"
                  placeholder="Insira o telefone do centro"
                  numberType
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    pattern: {
                      value: /^\d{9}$/,
                      message: "O telefone deve ter 9 dígitos",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="Insira o e-mail do centro"
                  type="email"
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: intl.formatMessage({ id: 'ErrorMessage_InvalidEmail', defaultMessage: 'Por favor, insira um e-mail válido.' }),
                  },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PageTitleHeader title="Actividade" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  name="secondaryActivityId"
                  label="Actividade Secundária"
                  required
                  control={methods.control}
                  options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  name="tertiaryActivityId"
                  label="Actividade Terciária"
                  required
                  control={methods.control}
                  options={ECONOMIC_ACTIVITIES_CLASSIFICATION}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="workerCount"
                  label="Nº de trabalhadores"
                  placeholder="Insira o número de trabalhadores"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  name="instrumentCount"
                  label="Nº de instrumento"
                  placeholder="Insira o número de instrumentos"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={onClose}>
                  Cancelar
                </GenericButton>
                <GenericButton
                  onClick={methods.handleSubmit(onSubmit)}
                  loading={methods.formState.isSubmitting}
                  size="medium"
                  color="primary"
                >
                  Editar Centro de Trabalho
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </ConditionGuard>
      </GenericDialog>
    </FormProvider>
  );
};

export default WorkCenterDetailModal;
