import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import GenericButton from "components/Buttons/GenericButton";
import PreviewData from "components/FormFields/PreviewData";
import LocationHeader from "components/Headers/LocationHeader";
import { getMapRentById } from "crud/rent.crud";
import { ConditionGuard } from "guards/ConditionGuard";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatCurrency, formatPercentage } from "utils/string_utils";

import { MAP_RENT_STATES } from "utils/const";
import { useMemo, useState } from "react";
import PaymentModal from "./PaymentModal";
import PreviewDocument from "components/PreviewDocument";
import { getPaymentDocuments } from "crud/rent.crud";
import useDownloadDocument from "hooks/useDownloadDocument";
import { getRentCertificateDocumentById } from "crud/rent.crud";
import PreviewObservations from "components/PreviewObservations";
import EmployeeList from "./EmployeeList";
import WorkCentersList from "./WorkCentersList";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    gap: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),

    "& .MuiCard-root": {
      marginBottom: 0,
    },
  },
  contentHeader: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    flex: 1,
    backgroundColor: "#F9F9FA",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function RENTDetails() {
  const { id } = useParams();

  const classes = useStyles();

  const { data, isFetching } = useQuery(["getMapRentById"], () => getMapRentById(id), {
    enabled: !!id,
  });

  const [{ state }, handleDownloadRentCertificate] = useDownloadDocument(getRentCertificateDocumentById);

  const [modalOpen, setModalOpen] = useState(false);

  const form = useForm();

  const history = useHistory();

  const employmentCenter = useMemo(() => data?.employmentCenter ?? {}, [data]);

  const employmentCenterDescription = useMemo(() => {
    if (!employmentCenter) return "";

    return `${employmentCenter.province} / ${employmentCenter.description}`;
  }, [employmentCenter]);

  function handleEditMap() {
    history.push(`/admin/rent/edit/${id}`);
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <LocationHeader
        iconButton={false}
        section="Detalhes"
        subsection="Lista de Mapas RENT"
        subsectionLink="/admin/rent/list"
      />

      <header className={classes.header}>
        <Typography
          style={{
            textTransform: "uppercase",
          }}
          color="primary"
          variant="h1"
        >
          Detalhes do Mapa RENT
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center" gridGap={12}>
          <ConditionGuard condition={data?.stateKeyValue === MAP_RENT_STATES.AWAITING_PAYMENT}>
            <GenericButton onClick={() => setModalOpen(true)} color="tertiary" size="small">
              Informações de Pagamento
            </GenericButton>
          </ConditionGuard>

          <ConditionGuard condition={data?.stateKeyValue === MAP_RENT_STATES.REJECTED}>
            <GenericButton onClick={handleEditMap} color="tertiary" size="small">
              Editar Mapa RENT
            </GenericButton>
          </ConditionGuard>

          <ConditionGuard condition={data?.stateKeyValue === MAP_RENT_STATES.APPROVED}>
            <GenericButton
              loading={state.get(data?.certificate?.id)}
              color="tertiary"
              size="small"
              onClick={() => handleDownloadRentCertificate(data?.certificate?.id, data?.certificate?.documentName)}
            >
              Exportar Certificado
            </GenericButton>
          </ConditionGuard>
        </Box>
      </header>

      <ConditionGuard
        condition={!isFetching}
        fallback={
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        }
      >
        <div className={classes.content}>
          <header className={classes.contentHeader}>
            <PreviewData title="Data de Submissão" subtitle={data?.submissionDate} />
            <PreviewData title="Nº do Mapa RENT" subtitle={id} />
            <PreviewData title="Estado" subtitle={data?.stateDescription} />
            <PreviewData title="Centro de Emprego" subtitle={employmentCenterDescription} />
          </header>

          <ConditionGuard condition={data?.stateKeyValue === MAP_RENT_STATES.REJECTED}>
            <section className={classes.section}>
              <Typography color="primary" variant="h2">
                Motivo da Rejeição
              </Typography>

              <Card>
                <CardContent className={classes.content}>
                  <PreviewData title="Motivo" subtitle={data?.rentRejectMotive?.motive} />

                  <PreviewData title="Observações" subtitle={data?.rentRejectMotive?.description} />
                </CardContent>
              </Card>
            </section>
          </ConditionGuard>

          <section className={classes.section}>
            <Typography color="primary" variant="h2">
              1. Informações da Empresa
            </Typography>

            <Card>
              <CardContent className={classes.content}>
                <Typography color="primary" variant="h4">
                  Dados da Empresa
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Nome" subtitle={data?.companyName} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="NIF" subtitle={data?.companyTaxIdNumber} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="NISS" subtitle={data?.companyNISS} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Morada" subtitle={data?.companyAddress} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="Provincia" subtitle={data?.companyProvince} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="Municipio" subtitle={data?.companyMunicipality} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="E-mail" subtitle={data?.companyEmail} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <PreviewData title="Telefone" subtitle={data?.companyPhoneNumber} />
                  </Grid>
                </Grid>

                <Divider />

                <Typography color="primary" variant="h4">
                  Actividade e Prestação Social
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Actividade Principal da Empresa" subtitle={data?.companyActivity} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Objecto Social" subtitle={data?.companyCorporateObject} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Ano de Inicío da Actividade" subtitle={data?.companyActivityStartYear} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Natureza Jurídica" subtitle={data?.companyLegalForm} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <PreviewData
                      title="Trabalhadores ao Serviço na Última Semana de Março"
                      subtitle={data?.companyTotalWorkersNumber}
                    />
                  </Grid>
                </Grid>

                <Divider />

                <Typography color="primary" variant="h4">
                  Capital Social
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <PreviewData title="Capital Social" subtitle={formatCurrency(data?.companyShareCapital)} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreviewData
                      title="Repartição Estatal (%)"
                      subtitle={formatPercentage(data?.companyPublicDivisionPercentage)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreviewData
                      title="Repartição Privado Nacional (%)"
                      subtitle={formatPercentage(data?.companyPrivateNationalDivisionPercentage)}
                    />
                  </Grid>
                </Grid>

                <ConditionGuard condition={data?.stateKeyValue !== MAP_RENT_STATES.AWAITING_PAYMENT}>
                  <Divider />

                  <Typography color="primary" variant="h4">
                    Comprovativo de Pagamento
                  </Typography>

                  <PreviewDocument promise={getPaymentDocuments} documents={data?.documents} />
                </ConditionGuard>
              </CardContent>
            </Card>
          </section>

          <FormProvider {...form}>
            <form>
              <section className={classes.section}>
                <Typography color="primary" variant="h2">
                  2. Dados de Centros de Trabalho
                </Typography>

                <WorkCentersList />
              </section>

              <section className={classes.section}>
                <Typography color="primary" variant="h2">
                  3. Dados de Trabalhadores
                </Typography>

                <EmployeeList />
              </section>
            </form>
          </FormProvider>

          <section className={classes.section}>
            <Typography color="primary" variant="h2">
              Observações
            </Typography>

            <Card>
              <CardContent className={classes.content}>
                <PreviewObservations observations={data?.observations} />
              </CardContent>
            </Card>
          </section>
        </div>
      </ConditionGuard>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <GenericButton onClick={() => history.push("/admin/rent/list")} color="tertiary" size="large">
          Voltar à página inicial
        </GenericButton>
      </Box>

      <ConditionGuard condition={modalOpen}>
        <PaymentModal handleClosePaymentModal={() => setModalOpen(false)} isOpenPaymentModal={modalOpen} />
      </ConditionGuard>
    </Container>
  );
}
