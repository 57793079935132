import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";
import ServiceButton from "../Components/ServiceButton";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import {
  loginAction,
  setTargetPathAfterLoginAction,
} from "redux/actions/auth/loginAction";
import { AUTH } from "utils/actionsConsts";
import LoginModal from "components/Modals/Auth/LoginModal";
import RecoverModal from "components/Modals/Auth/RecoverModal";

function ServicesBeforeLogin(props) {
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  const history = useHistory();

  const [loginModal, setStateModal] = useState(false);
  const [recoverModal, setRecoverModal] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    recoverPasswordPhone: "",
  });

  const handleLoginModal = () => {
    props.resetLoginForm();
    setRecoverModal(false);
    setStateModal(true);
  };

  const handlerecoverModal = () => {
    setStateModal(false);
    setRecoverModal(true);
  };

  const handleClose = () => {
    setStateModal(false);
    props.setTargetPathAfterLogin(null, null);
  };

  const handleCloseRecover = () => {
    setRecoverModal(false);
  };

  const onChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const setTargetPath = (path, pathUserType) => {
    props.setTargetPathAfterLogin(path, pathUserType);
  };

  return (
    <>
      <Container
        maxWidth={false}
        className={
          classes.homepageSectionContainer +
          " " +
          classes.homepageSectionContainerGrey
        }
      >
        <Grid container>
          {props.servicesList?.map((area, areaKey) => (
            <Grid key={areaKey} item xs={12} xl={6}>
              <Grid container style={{ marginBottom: "20px" }}>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Typography className={classes.servicesSubtitle}>
                    {area.title}
                  </Typography>

                  <div className={classes.servicesHorizontalLine}></div>
                </Grid>

                {area.services?.map((service, serviceKey) => (
                  <Grid
                    key={serviceKey}
                    item
                    xs={12}
                    sm={4}
                    style={{ textAlign: "center" }}
                  >
                    <ServiceButton
                      id={service.id}
                      title={service.title}
                      icon={service.icon}
                      handleOnClick={() => {
                        if (service.id === "ratify-agreement") {
                          props.clearContractApprovalState();
                        }
                        if (service.externalLink) {
                          window.open(service.path, "_blank", "noreferrer");
                        } else {
                          if (!!service.hasToLogin) {
                            handleLoginModal();
                            let path = "";
                            path = path.concat("/admin");
                            path = path.concat(service.path);
                            setTargetPath(path, service.userType);
                          } else {
                            history.push(
                              (props.authState?.loggedIn ? "/admin" : "/auth") +
                                service.path
                            );
                          }
                        }
                      }}
                      userType={area.userType}
                      isBeforeLogin={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
      <LoginModal
        loginModal={loginModal}
        handleClose={handleClose}
        handlerecoverModal={handlerecoverModal}
        loginAction={props.loginAction}
        onChange={onChange}
        state={state}
      />

      <RecoverModal
        recoverModal={recoverModal}
        handleCloseRecover={handleCloseRecover}
        showAuthenticationModal={props.showAuthenticationModal}
        onChange={onChange}
        state={state}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  resetLoginForm: () => dispatch({ type: AUTH.RESET_LOGIN_FORM }),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesBeforeLogin);
