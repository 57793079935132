import { listContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";
import { createContractApprovalWorkCenterEmployee } from "crud/contractApprovalWorkCenterEmployee.crud";

import { CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE, DIALOG } from "utils/actionsConsts";

export const createContractApprovalWorkCenterEmployeeAction = (data) => async (dispatch) => {
  dispatch({ 
    type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CREATE_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING,
    payload: true 
  });

  try {
    const res = await createContractApprovalWorkCenterEmployee(data);
    
    if(res.data === true) { 
      dispatch({
        type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CREATE_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE,
        payload: true
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack: true,
            buttonLabel: "Ok",
            message: "Trabalhador adicionado com sucesso."
          }
        }
      });
    }
  } catch (error) {
    dispatch({
      type: DIALOG.SHOW,
      payload: {
        showGenericDialog: true,
        genericDialogData: {
          type: DIALOG.ONE_BUTTON_TYPE,
          isSuccess: false,
          message: error.response?.data?.message || "Erro ao processar solicitação"
        }
      }
    });
  } finally {
    dispatch({
      type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CREATE_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING,
      payload: false
    });
  }
};

export const listContractApprovalWorkCenterEmployeeAction = (data) => async (dispatch) => {
  dispatch({
    type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING,
    payload: true,
  });
  return listContractApprovalWorkCenterEmployee(data)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          contractTypeDescription: record.contractTypeDescription,
          contractTypeId: record.contractTypeId,
          municipalityDescription: record.municipalityDescription,
          municipalityId: record.municipalityId,
          provinceDescription: record.provinceDescription,
          provinceId: record.provinceId,
          nationalityDescription: record.nationalityDescription,
          nationalityId: record.nationalityId,
          created: record.created,
          requestDate: record.requestDate,
          requestNumber: record.requestNumber,
          statusDescription: record.statusDescription,
          statusId: record.statusId,
          name: record.name,
        };
      });
      dispatch({
        type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE,
        result: false,
        payload: [],
      });
      dispatch({
        type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
