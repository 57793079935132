import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import jwt_decode from "jwt-decode";
import { refresh_token } from "crud/auth.crud";
import { createCookie } from "utils/auth";
import { AUTH_COOKIE_NAME } from "utils/const";
import { AUTH } from "utils/actionsConsts";
import { DIALOG } from "./actionsConsts";
import { takeResponseErrors } from "./takeResponseErrors";

const white_list = [
  "login",
  "register",
  "refresh-token",
  "getdatasetbyname",
  "getchildrenbypropertyid",
  "Nationalities",
  "GetProvinces",
  "GetMunicipalities",
  "recover-password",
  "reset-password",
  "GetDataSet",
  "get-filtered-information",
  "get-information-details-public",
  "get-governmentprograms",
  "get-governmentprogram-details",
  "get-who-we-are",
  "validate-sso-access",
  "get-social-media-default-links",
  "get-statistical-data",
  "newsletter-add-contact",
  'get-employment-centers',
  'get-training-centers',
  'get-contract-approval-certificate-from-token',
  'get-contract-approval-contract-from-token',
  'get-contract-approval-certificate-info-from-token',
  'get-rent-certificate-info-from-token',
  'get-rent-certificate-from-token'
];
class UndecoratedSetupAxios extends React.Component {
  static propTypes = {
    //auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    axios: PropTypes.func,
    children: PropTypes.node,
    requestInterceptorHandler: PropTypes.func,
    requestInterceptorErrorHandler: PropTypes.func,
    responseInterceptorSuccessHandler: PropTypes.func,
    responseInterceptorErrorHandler: PropTypes.func,
  };

  static defaultProps = {
    axios,
    children: null,
    requestInterceptorHandler: (config) => config,
    requestInterceptorErrorHandler: (error) => Promise.reject(error),
    responseInterceptorSuccessHandler: (response) => response,
    responseInterceptorErrorHandler: (error) => Promise.reject(error),
  };

  static displayName = "SetupAxios";

  requestInterceptor = null;
  responseInterceptor = null;

  componentDidMount() {
    this.requestInterceptor = this.props.axios.interceptors.request.use(
      this.requestInterceptorSuccessHandler,
      this.requestInterceptorErrorHandler
    );
    this.responseInterceptor = this.props.axios.interceptors.response.use(
      this.responseInterceptorSuccessHandler,
      this.responseInterceptorErrorHandler
    );
  }

  componentWillUnmount() {
    this.props.axios.interceptors.request.eject(this.requestInterceptor);
    this.props.axios.interceptors.response.eject(this.responseInterceptor);
  }

  requestInterceptorSuccessHandler = async (config) => {
    var token = this.props.store.getState().authState?.token;

    if (this.check_white_list(config.url)) {
      return config;
    }
    // If not able to retrieve a token, send the user back to login
    if (typeof token === "undefined") {
      createCookie(AUTH_COOKIE_NAME, "", -1);
      this.props.history.push("/auth/home");
      return config;
    }

    if (this.is_token_expired(token)) {
      await refresh_token(this.props.store.getState().authState?.refreshToken)
        .then((res) => {
          if (res.data.refreshToken) {
            this.props.store.dispatch({
              type: "refresh-token",
              payload: {
                jwtToken: res.data.jwtToken,
                refreshToken: res.data.refreshToken,
              },
            });
          } else {
            this.props.store.dispatch({
              type: AUTH.SESSION_EXPIRED,
            });
            this.props.history.push("/auth/home");
          }

          token = res.data.jwtToken;
          createCookie(AUTH_COOKIE_NAME, token, 1);
        })
        .catch((error) => {
          console.log("error on refresh token response", error);
        });
    }

    // Process the user supplied requestInterceptorHandler
    const newConfig = this.props.requestInterceptorHandler(config);
    // Return the config with the token appended to the Authorization Header
    return {
      ...newConfig,
      headers: {
        ...get(newConfig, "headers", {}),
        Authorization: `Bearer ${token}`,
      },
    };
  };

  requestInterceptorErrorHandler = (error) =>
    this.props.requestInterceptorErrorHandler(error);

  responseInterceptorSuccessHandler = (response) =>
    this.props.responseInterceptorSuccessHandler(response);

  responseInterceptorErrorHandler = (error) => {
    if (get(error, "response.status") === 401) {
      this.props.store.dispatch({
        type: "logout",
        payload: false,
      });
      this.props.history.push("/auth/home");
    }

    if (get(error, "response.status") === 400) {
      this.props.store.dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: takeResponseErrors(error?.response.data?.errors),
          },
        },
      });
    }

    return this.props.responseInterceptorErrorHandler(error);
  };

  is_token_expired = (token) => {
    var current_time = new Date().getTime() / 1000;
    var jwt = jwt_decode(token);
    return current_time > jwt.exp;
  };

  check_white_list = (url) => {
    var flag = false;

    white_list.forEach((el) => (url.includes(el) ? (flag = true) : ""));

    return flag;
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(UndecoratedSetupAxios);
