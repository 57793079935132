import { Box, CircularProgress, Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import GenericButton from "components/Buttons/GenericButton";
import { getRentCertificateDocumentFromToken } from "crud/rent.crud";
import { getRentCertificateInfoFromToken } from "crud/rent.crud";
import { ConditionGuard } from "guards/ConditionGuard";
import useDownloadDocument from "hooks/useDownloadDocument";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function RENTValidation() {
  const classes = useStyles();

  const { id } = useParams();

  const { data, isLoading, error } = useQuery(["rent", "certificate", id], () => getRentCertificateInfoFromToken(id), {
    enabled: !!id,
  });

  const [{ state }, handleDownloadRentCertificate] = useDownloadDocument(getRentCertificateDocumentFromToken);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box my={4}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Certificado RENT
          </Typography>

          <ConditionGuard
            condition={!isLoading}
            fallback={
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
              </Box>
            }
          >
            <ConditionGuard
              condition={!error}
              fallback={
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <Alert severity="error">
                    Você não tem permissões para aceder ao certificado, ou este é inválido. Por favor, contacte o
                    administrador.
                  </Alert>
                </Box>
              }
            >
              <ConditionGuard
                condition={!!data}
                fallback={
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Alert severity="info">Nenhum dado disponível para este certificado.</Alert>
                  </Box>
                }
              >
                <Typography variant="body1" paragraph>
                  O Instituto Nacional de Emprego e Formação Profissional (INEFOP), instituição oficial responsável pela
                  emissão do Certificado RENT, valida, por meio deste portal, a autenticidade deste documento, emitido
                  para a empresa <strong>{data?.companyName}</strong>, referente ao ano de{" "}
                  <strong>{moment.utc(data?.certificateYear).format("YYYY")}</strong>.
                </Typography>

                <Typography variant="body1" paragraph>
                  Este certificado foi emitido em conformidade com os procedimentos e critérios estabelecidos pela nossa
                  plataforma, garantindo que todas as informações nele contidas são verídicas e verificáveis. A
                  autenticidade do certificado pode ser confirmada exclusivamente através deste portal oficial, sendo
                  válido somente quando acompanhado deste código QR e validado diretamente na nossa plataforma.
                </Typography>

                <Box my={4} textAlign="center">
                  <Typography variant="h6" gutterBottom>
                    Para efetuar o download do seu certificado, clique no botão abaixo:
                  </Typography>
                  <GenericButton
                    color="primary"
                    size="large"
                    onClick={() => handleDownloadRentCertificate(id)}
                    loading={state.get(id)}
                  >
                    Download do Certificado
                  </GenericButton>
                </Box>

                <Typography variant="body1" paragraph>
                  Caso tenha alguma dúvida ou precise de mais informações, por favor, entre em contacto connosco através
                  dos nossos canais oficiais.
                </Typography>
              </ConditionGuard>
            </ConditionGuard>
          </ConditionGuard>
        </Paper>
      </Box>
    </Container>
  );
}
