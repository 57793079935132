import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const WORKCENTER_URL = "WorkCenter";

export async function createWorkCenter(WorkCenter) {
  return axios.post(EXTERNAL_API_URL + WORKCENTER_URL, WorkCenter);
}

export function updateWorkCenter(WorkCenter) {
  return axios.put(EXTERNAL_API_URL + WORKCENTER_URL, WorkCenter);
}

export function listWorkCenters(data) {
  return axios.get(EXTERNAL_API_URL + WORKCENTER_URL, {
    params: data,
  });
}

export function getWorkCenter(id) {
  return axios.get(EXTERNAL_API_URL + WORKCENTER_URL + "/" + id);
}

export function inactiveWorkCenter(data) {
  return axios.put(EXTERNAL_API_URL + WORKCENTER_URL + "/inactive-work-center", {id: data});
}