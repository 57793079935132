export const limitaString = (value, limitSize) => {
  if (!value || !limitSize) return value;
  if (value.length > limitSize) return value.substring(0, limitSize) + "...";
  return value;
};

export const removeAccents = (value) => {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Format a number to a string with the given options
 * @param {string} value
 * @param {Intl.LocalesArgument} opts
 * @returns {string}
 */
export const formatNumber = (value, opts) => {
  return Intl.NumberFormat("pt-PT", opts).format(value);
};

/**
 * @param {string} value
 * @param {Intl.LocalesArgument} opts
 * @returns {string}
 */
export const formatCurrency = (value) => {
  return formatNumber(value, {
    currencyDisplay: "narrowSymbol",
    style: "currency",
    currency: "AOA",
  });
};

/**
 * Format number a percentage
 * 
 * @param {number} value
 * @param {Intl.LocalesArgument} opts
 * @returns {string}
 */
export const formatPercentage = (value) => {
  return formatNumber(value / 100, {
    style: "percent",
    minimumFractionDigits: 0,
  });
};

/**
 * Serializes an object into a query string format.
 *
 * @param {Object} params - The parameters to serialize.
 * @returns {string} The serialized query string.
 */
export function serializeParams(params) {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value.map((val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  return queryString;
}

export default limitaString;
