import { React } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { TABLE_STATE_CHIP_COLOR } from "utils/const";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { ConditionGuard } from "guards/ConditionGuard";
import { CONTRACT_APPROVAL_STATUS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ResultSection = () => {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const { watch, setValue } = useFormContext();
  const { data, isLoading, refetch } = useQuery(["listContractApproval"]);

  const page = watch("pageIndex");

  const tableHead = [
    "Data de Submissão",
    "Nº de Pedido",
    "Nº de contratos",
    "Tipo de Contrato",
    "Ramo de Actividade",
    "Nome(s) do(s) Trabalhador(es)",
    "Estado",
  ];

  const mapOpportunityStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case CONTRACT_APPROVAL_STATUS.CONCLUDED:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case CONTRACT_APPROVAL_STATUS.CANCELED:
        return TABLE_STATE_CHIP_COLOR.RED;
      case CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT:
      case CONTRACT_APPROVAL_STATUS.AWAITING_RECTIFICATION:
      case CONTRACT_APPROVAL_STATUS.AWAITING_VALIDATION:
      case CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT_DATA:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case CONTRACT_APPROVAL_STATUS.CREATED:
        return TABLE_STATE_CHIP_COLOR.YELLOW;
      case CONTRACT_APPROVAL_STATUS.SUBMITTED:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  return (
    <>
      <CardTableContainer title={"Lista de pedidos de homologação de contratos de trabalho"} count={data?.totalCount}>
        <TableContainer>
          <ConditionGuard condition={!isLoading} fallback={<TableBackdrop open />}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                  >
                    Detalhes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.requestDate}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.requestNumber}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.numberOfContracts}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.contractTypeDescriptions}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.companyActivityDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.workersNames}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <TableStateChip
                        state={mapOpportunityStateToTableChipColors(prop.statusKeyValue)}
                        message={prop.statusDescription}
                      />
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title={"Detalhes"}>
                        <Link to={`/admin/contract-approval/details/${prop.id}`}>
                          <IconButton>
                            <Box
                              component={ArrowForwardIosIcon}
                              width="12px!important"
                              height="12px!important"
                              color={theme.palette.primary.main}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </ConditionGuard>
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

        <Pagination
          className={classes.alignPagination}
          count={data?.totalPages}
          page={page}
          onChange={(_, value) => {
            setValue("pageIndex", value);

            refetch();
          }}
          color="primary"
        ></Pagination>
      </CardTableContainer>

      {!isLoading && data?.data?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
};

export default ResultSection;
