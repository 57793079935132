import React, { useEffect } from "react";
import { FORM_FIELDS_PREFIX } from "utils/const";

export default function useDownloadDocument(promise) {
  const [state, setState] = React.useState(new Map());
  const [error, setError] = React.useState(new Map());

  const handleDownload = React.useCallback(
    async (id, fileName) => {
      try {
        updateError(id, null);

        updateState(id, true);

        const data = await promise(id);

        const blob = new Blob([data], { type: data.type });

        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;

        link.download = fileName ?? `${FORM_FIELDS_PREFIX.DOCUMENT}${id}`;
        document.body.appendChild(link);
        link.click();

        link.remove();

        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Erro ao baixar o arquivo", error);

        updateError(id, true);
      } finally {
        updateState(id, false);
      }
    },
    [promise]
  );

  function updateState(id, isLoading) {
    setState((prevState) => new Map(prevState.set(id, isLoading)));
  }

  function updateError(id, error) {
    setError((prevState) => new Map(prevState.set(id, error)));
  }

  useEffect(() => {
    return () => {
      setState(new Map());
      setError(new Map());
    };
  }, []);

  return [{ state, error }, handleDownload];
}
