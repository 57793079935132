import { CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE } from "utils/actionsConsts";

const contractApprovalWorkCenterEmployeeReducer = (
  state = {
    contract_approval_workcenter_employee_loading: false,
  },
  action
) => {
  switch (action.type) {
    case CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CREATE_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE:
      return {
        ...state,
        contract_approval_workcenter_employee_loading: false,
      };
    case CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CREATE_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING:
      return { ...state, contract_approval_workcenter_employee_loading: true };
    case CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE:
      return {
        ...state,
        result: action.payload,
        contract_approval_workcenter_employee_loading: false,
      };
    case CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.LIST_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_LOADING:
      return { ...state, contract_approval_workcenter_employee_loading: true };
    case CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CLEAR_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_REQUEST:
      return {
        ...state,
        result: null,
      };
    default:
      return state;
  }
};

export default contractApprovalWorkCenterEmployeeReducer;
