import { getListAsStringLabels } from "utils/formSelectMultiple";
import { getMultipleSelectOptionsElementByCode } from "utils/formSelect";

import { useEffect } from "react";
import _ from "lodash";
const isNullOrUndefined = (object) => {
  return object == null || object === undefined || object?.length === 0;
};

export const fetchComponentDatasets = (action, state, datasetsToFetch) => {
  if (!isNullOrUndefined(datasetsToFetch != null)) {
    datasetsToFetch.forEach((dataset) => {
      if (isNullOrUndefined(state) || isNullOrUndefined(state[dataset])) action(dataset);
    });
  }
};
//General FetchOnMount
export const FetchDatasetsOnMount = (datasetsAction, state, datasetsToFetch) => {
  useEffect(() => {
    if (datasetsAction !== undefined) fetchComponentDatasets(datasetsAction, state, datasetsToFetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const getDatasetStateByName = (props, name) => {
  if (!isNullOrUndefined(props.datasetsState) && !isNullOrUndefined(props.datasetsState[name]))
    return props.datasetsState[name];
  else return [];
};

export const getDatasetLabelByCode = (dataset, code) => {
  if (code && dataset) {
    const result = dataset?.find((r) => r.code === code)?.label;

    return result;
  }

  return "N/A";
};

export const getDatasetConcatenatedListOfLabelsByCode = (dataset, codesList) => {
  if (codesList && codesList?.length > 0) {
    let list = getMultipleSelectOptionsElementByCode(dataset, codesList);
    let result = getListAsStringLabels(list, ", ");
    return result;
  }

  return "N/A";
};

export const getDatasetDataByCode = (dataset, code) => {
  if (_.isEmpty(dataset)) return null;

  if (code && dataset) {
    const result = dataset?.find((r) => r.code === code);

    return result;
  }

  return null;
};

export const getKeyValueByCode = (dataset, code) => getDatasetDataByCode(dataset, code)?.keyValue;

export const getLabelByCode = (dataset, code) => getDatasetDataByCode(dataset, code)?.label;
