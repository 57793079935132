import React from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormMultipleSelect({
  control,
  fieldName,
  selectOptions,
  initialLabel,
  getValues,
  required,
  requiredMessage,
  limitTags,
  errors,
  classes,
  disabled,
  style,
  ...rest
}) {
  const theme = useTheme();

  const GetMultiSelectFormVal = (name) => {
    const value = getValues(name);
    return value ? value : [];
  };
  return (
    <Controller
      render={({ field: { onChange, name } }) => (
        <Autocomplete
          {...rest}
          style={style}
          noOptionsText={"Sem opções"}
          disabled={disabled !== undefined ? disabled : false}
          multiple
          limitTags={limitTags}
          name={name}
          onChange={(e, data) => {
            onChange(data);
          }}
          value={GetMultiSelectFormVal(name)}
          options={selectOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.code === value.code}
          classes={{
            inputRoot: clsx({
              [classes.selectBorderWarningInputRoot]: errors !== undefined && errors[name] !== undefined,
            }),
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={!initialLabel ? "Seleccione opção" : initialLabel}
              InputLabelProps={{
                style: {
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: theme.palette.gray[600],
                },
              }}
            />
          )}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: {
          value: required,
          message: requiredMessage,
        },
      }}
    />
  );
}
