import React from "react";

export default function usePaginate() {
  const [page, setPage] = React.useState(1);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  return [page, handleChangePage];
}