import { makeStyles } from "@material-ui/core";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function useGenericStyles() {
  const genericStyles = useStylesGeneric();

  return genericStyles;
}
