import React, { useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import WorkCentersEmployeesModal from "./WorkCentersEmployeesModal";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { connect } from "react-redux";
import FilterSection from "./FilterSection";
import ResultSection from "./ResultSection";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import {
  getWorkCenterEmployerByIdAction,
  updateWorkCenterEmployerAction,
  listWorkCenterEmployersAction,
  createWorkCenterEmployerAction,
} from "redux/actions/workCentersEmployerAction";
import { FormProvider, useForm } from "react-hook-form";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const WorkCentersEmployees = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedWorkCenterEmployer, setSelectedWorkCenterEmployer] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleOpenDialog = (workCenterEmployer) => {
    setSelectedWorkCenterEmployer(workCenterEmployer);
    setIsCreating(isCreating);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWorkCenterEmployer(null);
  };

  const classes = useStylesGeneric();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      ssn: "",
      startDate: null,
      endDate: null,
      workCenterId: "",
      professionalCategoryId: "",
      employmentStatusId: "",

      pageIndex: 1,
      pageSize: 15,
    },
  });

  return (
    <FormProvider {...form}>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <PageTitleHeader title="Trabalhadores" />
        <GenericButton
          typeSubmit={false}
          size="medium"
          color="tertiary"
          onClick={() => handleOpenDialog()}
          style={{
            marginBottom: "37px",
          }}
        >
          Criar Trabalhador
        </GenericButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <FilterSection />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
          Na tabela em baixo estão listados todos os trabalhadores que integram a lista que registou na sua área de
          utilizador e serão integrados no relatório RENT. <br />
          Poderá editar as informações directamente entrando no detalhe de cada centro de trabalho. Após gravar ou
          adicionar o centro de trabalho, a lista no menu de utilizadores será actualizada.
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box classes={{ root: classes.tableResultBoxRoot }}>
          <ResultSection handleOpenDialog={handleOpenDialog} />
        </Box>
      </Grid>
      {openDialog && (
        <WorkCentersEmployeesModal
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          isCreating={isCreating}
          selectedWorkCenterEmployer={selectedWorkCenterEmployer}
        />
      )}
    </FormProvider>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  createWorkCenterEmployer: (data) => dispatch(createWorkCenterEmployerAction(data)),
  getFilteredWorkCenterEmployer: (data) => dispatch(listWorkCenterEmployersAction(data)),
  getWorkCenterEmployerById: (id) => dispatch(getWorkCenterEmployerByIdAction(id)),
  updateWorkCenterEmployer: (data) => dispatch(updateWorkCenterEmployerAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkCentersEmployees);
