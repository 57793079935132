import React from "react";
import Dropzone from "react-dropzone";

import { useController } from "react-hook-form";
import { Box, FormGroup, FormHelperText, FormLabel, Tooltip, useTheme } from "@material-ui/core";
import { Clear, Help } from "@material-ui/icons";
import useGenericStyles from "hooks/useGenericStyles";
import clsx from "clsx";
import Chip from "components/Chip";

export default function FormDropzoneField({
  control,
  name,
  label = "Documento",
  inputLabel = "Adicionar documento",
  maxSize,
  maxFiles,
  required,
  tooltipLabel,
  rules,
  multiple = false,
  metadata,
  ...rest
}) {
  const classes = useGenericStyles();
  const theme = useTheme();

  const labelClasses = clsx({
    [classes.labelError]: required,
  });

  const maxNumberOfFiles = React.useMemo(() => {
    if (multiple) {
      return maxFiles || 5;
    }

    return undefined;
  }, [maxFiles, multiple]);

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: {
        value: required,
        message: "Campo obrigatório",
      },
      validate: {
        maxFile: (files) => {
          if (maxFiles && files?.length > maxFiles) {
            return `Número máximo de ficheiros excedido, número máximo: ${maxFiles}`;
          }

          return true;
        },
        maxSize: (files) => {
          if (maxSize && files?.some((file) => file.size > maxSize * 1048576)) {
            return `Tamanho máximo de ficheiro excedido, tamanho máximo: ${maxSize}MB`;
          }

          return true;
        },
      },
    },
  });

  function onRemoveFile(index) {
    const newValue = value.filter((_, i) => i !== index);

    onChange(newValue);
  }

  function onDrop(files) {
    if (metadata) {
      const filesWithMetadata = files.map((file) => {
        const newFile = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });

        newFile.metadata = metadata;

        return newFile;
      });

      return onChange(filesWithMetadata);
    }

    onChange(files);
  }

  return (
    <FormGroup>
      <FormLabel className={labelClasses}>
        {label}
        {tooltipLabel && (
          <Tooltip title={tooltipLabel}>
            <Help className={classes.tooltipInfoIcon} />
          </Tooltip>
        )}
      </FormLabel>

      <Dropzone {...rest} multiple={multiple} maxSize={maxSize} maxFiles={maxNumberOfFiles} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              "data-error": !!error,
              className: clsx(classes.documentDropzone),
            })}
          >
            <input {...getInputProps()} onBlur={onBlur} />
            <p>{inputLabel}</p>
          </div>
        )}
      </Dropzone>

      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
        {error?.message}
      </FormHelperText>

      <Box className={classes.ul} component="ul" width="100%" padding={0} marginTop={2}>
        {value?.map((file, index) => (
          <li className={classes.li}>
            <Chip
              className={classes.documentBadge}
              variant="outlined"
              color="primary"
              key={`key-${index}`}
              label={file.name}
              onDelete={() => onRemoveFile(index)}
              deleteIcon={<Clear color="primary" />}
            />
          </li>
        ))}
      </Box>
    </FormGroup>
  );
}
