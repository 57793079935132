import { getWorkCenterEmployerById } from "crud/workCentersEmployer.crud";
import { getWorkCenterEmployerBySsn } from "crud/workCentersEmployer.crud";
import { getEmployeeDocumentById } from "crud/workCentersEmployer.crud";
import {
  createWorkCenterEmployer,
  listWorkCenterEmployers,
  updateWorkCenterEmployer,
} from "crud/workCentersEmployer.crud";
import { convertByteArrayToBlobUrl } from "utils/converters";

import { WORK_CENTERS_EMPLOYER, DIALOG } from "utils/actionsConsts";

export const createWorkCenterEmployerAction = (data) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER_LOADING,
    payload: true,
  });
  createWorkCenterEmployer(data)
    .then(() => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER,
        payload: true,
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack: true,
            buttonLabel: "Ok",
            onClose: "/admin/user-profile",
            message: "Trabalhador adicionado com sucesso.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER,
        payload: false,
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const listWorkCenterEmployersAction = (data) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER_LOADING,
    payload: true,
  });
  listWorkCenterEmployers(data)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          employeeStatus: record.employeeStatusDescription,
          created: record.created,
          professionalCategory: record.professionalCategoryDescription,
          ssn: record.ssn,
          name: record.name,
          workCenter: record.workCenterDescription,
        };
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER,
        result: false,
        payload: [],
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateWorkCenterEmployerAction = (data) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER_LOADING,
    payload: true,
  });

  return updateWorkCenterEmployer(data)
    .then((res) => {
      dispatch(
        listWorkCenterEmployers({
          pageIndex: 1,
          pageSize: 10,
        })
      );

      dispatch({
        type: WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER,
        payload: true,
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data ? true : false,
            goBack: true,
            buttonLabel: "Ok",
            onClose: "/admin/user-profile",
            message: res.data
              ? "Centro de trabalho actualizado com sucesso."
              : "Ocorreu um erro ao actualizar o centro de trabalho.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER,
        payload: false,
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const getWorkCenterEmployerByIdAction = (id) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER,
  });
  dispatch({
    type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_LOADING,
    payload: true,
  });
  return getWorkCenterEmployerById(id)
    .then((res) => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      return res.data;
    })
    .catch((error) => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER,
        result: false,
        payload: {},
      });
      dispatch({
        type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getWorkCenterEmployerBySsnAction = (ssn, contractApprovalId) => async (dispatch) => {
  const setLoading = (isLoading) => {
    dispatch({
      type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_BY_SSN_LOADING,
      payload: isLoading,
    });
  };

  const showErrorDialog = (errorMessage) => {
    dispatch({
      type: DIALOG.SHOW,
      payload: {
        showGenericDialog: true,
        genericDialogData: {
          type: DIALOG.ONE_BUTTON_TYPE,
          isSuccess: false,
          message: errorMessage || "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
        },
      },
    });
  };

  const setEmployerData = (data, result) => {
    dispatch({
      type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_BY_SSN,
      result: result,
      payload: data,
    });
  };

  setLoading(true);

  try {
    const res = await getWorkCenterEmployerBySsn(ssn, contractApprovalId);
    setEmployerData({ data: res.data }, true);
  } catch (error) {
    console.error("Error on get workcenter employer by ssn:", error);

    if (error.response) {
      console.error("Error Response:", error.response);

      const errorData = error.response.data;
      
      const errorMessages = errorData?.errors
      ? Object.values(errorData.errors)
          .flat() 
          .join(". ") 
      : null;

      const errorMessage =
        errorMessages || errorData?.title || "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.";

      showErrorDialog(errorMessage);
    } else {
      showErrorDialog("Ocorreu um erro de rede. Por favor, verifique a sua conexão.");
    }

    setEmployerData([], false);
  } finally {
    setLoading(false);
  }
};


export const getWorkCenterEmployeeDocumentAction = (docId, docName) => async (dispatch) => {
  getEmployeeDocumentById(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: WORK_CENTERS_EMPLOYER.GET_WORK_CENTER_EMPLOYEE_DOCUMENT_DATA_DOWNLOAD,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};
