import { React } from "react";

import { makeStyles } from "@material-ui/core/styles";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import { Card, Grid } from "@material-ui/core";
import PreviewData from "components/FormFields/PreviewData";
import { ConditionGuard } from "guards/ConditionGuard";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export const ContractApprovalRequestDetailsCard = ({ contractApprovalRequest, isDraft }) => {
  const classes = useStylesGeneric();

  return (
    <Grid item xs={12} md={12}>
      <Card className={classes.cardRoot}>
        <Grid container>
          <Grid item xs={12} md={isDraft ? 4 : 3}>
            <PreviewData title="Data de Submissão" subtitle={contractApprovalRequest?.requestDate} />
          </Grid>
          <Grid item xs={12} md={isDraft ? 4 : 3}>
            <PreviewData title="Nº de Pedido" subtitle={contractApprovalRequest?.requestNumber} />
          </Grid>
          <Grid item xs={12} md={isDraft ? 4 : 3}>
            <PreviewData title="Estado" subtitle={contractApprovalRequest?.contractApprovalStatusDescription} />
          </Grid>
          <ConditionGuard condition={!isDraft}>
            <Grid item xs={12} md={3}>
              <PreviewData
                title="Centro de Emprego"
                subtitle={
                  (!!contractApprovalRequest?.provinceDescription
                    ? contractApprovalRequest?.provinceDescription
                    : "-") +
                  " / " +
                  (!!contractApprovalRequest?.employmentCenterDescription
                    ? contractApprovalRequest?.employmentCenterDescription
                    : "-")
                }
              />
            </Grid>
          </ConditionGuard>
        </Grid>
      </Card>
    </Grid>
  );
};
