import { Card, CardContent, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import FormInput from "components/FormFields/FormInput";
import { useFormContext } from "react-hook-form";
import StepContainer from "../step-container";
import PreviewData from "components/FormFields/PreviewData";
import FormDatePicker from "components/Forms/FormDatePicker";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { DATASET } from "utils/const";
import errorMessages from "resources/resources";
import { ConditionGuard } from "guards/ConditionGuard";
import { formatPercentage } from "utils/string_utils";
import { formatCurrency } from "utils/string_utils";
import FormMaskedInput from "components/FormFields/FormMaskedInput";
import { INPUT_MASKS } from "utils/const";
import { useQuery } from "@tanstack/react-query";
import { getPersonalInformation } from "crud/auth.crud";
import { useDataset } from "hooks/useDataset";
import { getKeyValueByCode } from "utils/datasets";
import moment from "moment";
import FormDropdown from "components/FormFields/FormDropdown";
import CenterSpinner from "components/Spinners/CenterSpinner";
import { useDependentDataset } from "hooks/useDependentDataset";
import { getLabelByCode } from "utils/datasets";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    padding: theme.spacing(3),

    "& h3": {
      fontWeight: 600,
      fontSize: "18px",
      margin: 0,
      color: theme.palette.primary.main,
    },
  },
}));

export function CompanyInfoData() {
  const classes = useStyles();

  const { watch } = useFormContext();

  const user = watch("user");
  const employmentCenterId = watch("employmentCenterId");

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const employmentCenterProvinceKeyValue = watch("employmentCenterProvinceKeyValue");
  const { data: EMPLOYMENT_CENTERS } = useDataset(
    {
      name: DATASET.EMPLOYMENT_CENTERS,
      parentKeyValue: employmentCenterProvinceKeyValue,
    },
    {
      enabled: !!employmentCenterProvinceKeyValue,
    }
  );

  const { data: LEGAL_FORM } = useDataset({ name: DATASET.LEGAL_FORM });
  const { data: BUSINESS_PURPOSE } = useDataset({ name: DATASET.BUSINESS_PURPOSE });

  const provinceId = watch("user.provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const businessPurposeList = watch("user.businessPurposeList");

  const businessPurposeKeyValues = useMemo(() => {
    return BUSINESS_PURPOSE?.map((item) => businessPurposeList?.includes(item.code) && item.keyValue) || [];
  }, [BUSINESS_PURPOSE, businessPurposeList]);

  const { data: COMPANY_ACTIVITY } = useDependentDataset(
    { name: DATASET.COMPANY_ACTIVITY, parentKeyValues: businessPurposeKeyValues },
    {
      enabled: !!businessPurposeKeyValues.length,
    }
  );

  return (
    <Card>
      <CardContent className={classes.section}>
        <Typography variant="h3">Dados da empresa</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PreviewData title="Nome da empresa" subtitle={user?.name} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="NIF" subtitle={user?.taxIdNumber} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="INSS" subtitle={user?.niss} />
          </Grid>

          <Grid item xs={12}>
            <PreviewData title="Morada" subtitle={user?.address} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PreviewData title="Província" subtitle={getLabelByCode(PROVINCES, user.provinceId)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Município" subtitle={getLabelByCode(MUNICIPALITIES, user.municipalityId)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Centro de Emprego" subtitle={getLabelByCode(EMPLOYMENT_CENTERS, employmentCenterId)} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PreviewData title="Telefone" subtitle={user?.phoneNumber} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="E-mail" subtitle={user?.email} />
          </Grid>
        </Grid>

        <Divider />

        <Typography variant="h3">Actividade e Prestação Social</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PreviewData
              title="Actividade Principal da Empresa"
              subtitle={getLabelByCode(COMPANY_ACTIVITY, user?.companyActivityId)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Objecto Social" subtitle={user?.corporateObject} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Ano de Início de Actividade" subtitle={user?.activityStartYear?.format("YYYY")} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Natureza Jurídica" subtitle={getLabelByCode(LEGAL_FORM, user?.legalFormId)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData
              title="Trabalhadores ao Serviço na Última Semana de Março"
              subtitle={user?.totalWorkersNumber}
            />
          </Grid>
        </Grid>

        <Divider />

        <Typography variant="h3">Capital Social</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PreviewData title="Capital Social" subtitle={formatCurrency(user?.shareCapital)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData title="Repartição Estatal (%)" subtitle={formatPercentage(user?.publicDivisionPercentage)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <PreviewData
              title="Repartição Privado Nacional (%)"
              subtitle={formatPercentage(user?.privateNationalDivisionPercentage)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function CompanyInfoForm({ fieldsDisabled = false, fetchDefaultValues = true, isEditing = false }) {
  const {
    control,
    trigger,
    getValues,
    formState: { errors },
    watch,
    reset,
  } = useFormContext();

  const classes = useStyles();

  const intl = useIntl();

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });
  const { data: LEGAL_FORM } = useDataset({ name: DATASET.LEGAL_FORM });
  const { data: BUSINESS_PURPOSE } = useDataset({ name: DATASET.BUSINESS_PURPOSE });

  const provinceId = watch("user.provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const employmentCenterProvinceKeyValue = watch("employmentCenterProvinceKeyValue");
  const { data: EMPLOYMENT_CENTERS } = useDataset(
    {
      name: DATASET.EMPLOYMENT_CENTERS,
      parentKeyValue: employmentCenterProvinceKeyValue,
    },
    {
      enabled: !!employmentCenterProvinceKeyValue,
    }
  );

  const businessPurposeList = watch("user.businessPurposeList");

  const businessPurposeKeyValues = useMemo(() => {
    return BUSINESS_PURPOSE?.map((item) => businessPurposeList?.includes(item.code) && item.keyValue) || [];
  }, [BUSINESS_PURPOSE, businessPurposeList]);

  const { data: COMPANY_ACTIVITY, isFetching: isCompanyActivityLoading } = useDependentDataset(
    { name: DATASET.COMPANY_ACTIVITY, parentKeyValues: businessPurposeKeyValues },
    {
      enabled: !!businessPurposeKeyValues.length,
    }
  );

  const { isFetching } = useQuery(["getPersonalInformation"], () => getPersonalInformation(), {
    cacheTime: 0,
    enabled: fetchDefaultValues,
    onSuccess: ({ data }) => {
      const user = data.userColective;

      reset({
        employmentCenterId: getValues("employmentCenterId"),
        employmentCenterProvinceKeyValue: getValues("employmentCenterProvinceKeyValue"),
        user: {
          id: data.id,
          name: data.name,
          email: user?.companyEmail,
          address: user?.compositeAddress?.address,
          taxIdNumber: user?.taxIdNumber,
          niss: user?.niss,
          phoneNumber: user?.companyPhone,

          companyActivityId: user?.companyActivityId,
          provinceId: user?.compositeAddress?.provinceId,
          municipalityId: user?.compositeAddress?.municipalityId,
          legalFormId: user?.legalFormId,

          totalWorkersNumber: user?.totalWorkersNumber,
          shareCapital: user?.shareCapital,
          publicDivisionPercentage: user?.publicDivisionPercentage,
          privateNationalDivisionPercentage: user?.privateNationalDivisionPercentage,
          activityStartYear: moment(user?.activityStartYear, "YYYY"),
          corporateObject: user?.corporateObject,

          businessPurposeList: user?.businessPurposeList,
        },
      });
    },
  });

  return (
    <ConditionGuard condition={!isFetching} fallback={<CenterSpinner />}>
      <Card>
        <CardContent className={classes.section}>
          <Typography variant="h3">Centro de Emprego</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormDropdown
                control={control}
                name="employmentCenterProvinceKeyValue"
                label="Província do Centro de Emprego"
                options={PROVINCES}
                required
                mappingTo="keyValue"
                disabled={isEditing}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDropdown
                control={control}
                name="employmentCenterId"
                label="Centro de Emprego"
                options={EMPLOYMENT_CENTERS}
                required
                disabled={!getValues("employmentCenterProvinceKeyValue") || isEditing}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent className={classes.section}>
          <Typography variant="h3">Dados da Empresa</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormInput
                name="user.name"
                label="Nome da Empresa"
                placeholder="Insira o nome da empresa"
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInput
                name="user.address"
                label="Morada"
                placeholder="Insira a morada"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormInput
                name="user.taxIdNumber"
                label="NIF"
                placeholder="Insira o NIF"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormInput
                name="user.niss"
                label="NISS"
                placeholder="Insira o INSS"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDropdown control={control} name="user.provinceId" label="Província" options={PROVINCES} required />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDropdown
                control={control}
                name="user.municipalityId"
                label="Município"
                options={MUNICIPALITIES}
                required
                disabled={fieldsDisabled || !getValues("user.provinceId")}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormInput
                name="user.phoneNumber"
                label="Telefone"
                placeholder="Insira o telefone"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInput
                name="user.email"
                label="E-mail"
                placeholder="Insira o e-mail"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Typography variant="h3">Actividade e Prestação Social</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormDropdown
                label="Actividade Principal da Empresa"
                placeholder="Seleccione a actividade principal da empresa"
                name="user.companyActivityId"
                control={control}
                options={COMPANY_ACTIVITY}
                loading={isCompanyActivityLoading}
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInput
                name="user.corporateObject"
                label="Objecto Social"
                placeholder="Seleccione o objecto social"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDatePicker
                name="user.activityStartYear"
                label="Ano de Início de Actividade"
                placeholder="Seleccione o ano de início de actividade"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                dateFormat="YYYY"
                trigger={trigger}
                getValues={getValues}
                isRequired
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDropdown
                control={control}
                name="user.legalFormId"
                label="Natureza Jurídica"
                options={LEGAL_FORM}
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInput
                name="user.totalWorkersNumber"
                label="Trabalhadores ao Serviço na Última Semana de Março"
                placeholder="Insira o número de trabalhadores ao serviço na última semana de Março"
                required
                control={control}
                errors={errors}
                disabled={fieldsDisabled}
                numberType
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Typography variant="h3">Capital Social</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormMaskedInput
                name="user.shareCapital"
                label="Capital Social"
                placeholder="Insira o capital social"
                required
                control={control}
                disabled={fieldsDisabled}
                mask={INPUT_MASKS.MONETARY}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormMaskedInput
                name="user.publicDivisionPercentage"
                label="Repartição Estatal (%)"
                placeholder="Insira a repartição estatal"
                required
                control={control}
                disabled={fieldsDisabled}
                mask={INPUT_MASKS.PERCENT}
                rules={{
                  validate: {
                    privateAndStatePercentagesAmountTo100: (value) => {
                      if (
                        value &&
                        getValues("user.privateNationalDivisionPercentage") &&
                        parseInt(value) + parseInt(getValues("user.privateNationalDivisionPercentage")) !== 100
                      )
                        return intl.formatMessage(errorMessages.ErrorMessage_StateAndPrivatePercentageMustAmountTo100);
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormMaskedInput
                name="user.privateNationalDivisionPercentage"
                label="Repartição Privado Nacional (%)"
                placeholder="Insira a repartição privado nacional"
                required
                control={control}
                disabled={fieldsDisabled}
                mask={INPUT_MASKS.PERCENT}
                rules={{
                  validate: {
                    privateAndStatePercentagesAmountTo100: (value) => {
                      if (
                        value &&
                        getValues("user.publicDivisionPercentage") &&
                        parseInt(value) + parseInt(getValues("user.publicDivisionPercentage")) !== 100
                      )
                        return intl.formatMessage(errorMessages.ErrorMessage_StateAndPrivatePercentageMustAmountTo100);
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ConditionGuard>
  );
}

export default function CompanyInfoTab(props) {
  return (
    <StepContainer title="Informações da empresa">
      <CompanyInfoForm {...props} />
    </StepContainer>
  );
}
