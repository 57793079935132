import { Box, Typography } from "@material-ui/core";
import StepContainer from "../step-container";
import { CompanyInfoData } from "../CompanyInfo";
import { WorkCenterTable } from "./WorkCenterTable";
import { EmployeesTable } from "./EmployeesTable";

export default function ConfirmationTab() {
  return (
    <StepContainer title="Confirmação">
      <Box>
        <Typography color="primary" variant="h3">
          1. Informações da Empresa
        </Typography>

        <CompanyInfoData />
      </Box>

      <Box>
        <Typography color="primary" variant="h3">
          2. Dados de Centros de Trabalho
        </Typography>

        <WorkCenterTable />
      </Box>

      <Box>
        <Typography color="primary" variant="h3">
          3. Dados de Trabalhadores
        </Typography>

        <EmployeesTable />
      </Box>
    </StepContainer>
  );
}
