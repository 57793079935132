import React, { useCallback, useMemo } from "react";
import { Typography, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormAutoComplete from "components/FormFields/FormAutoComplete";
import { getWorkCenterEmployerBySsnAction } from "redux/actions/workCentersEmployerAction";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: "0 24px 24px",
      width: "100%",
      height: "auto",
      "& .MuiTypography-body1": {
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    "& .MuiDialogTitle-root": {
      padding: 24,
    },
    "& h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "24px",
      fontWeight: 400,
      "& .MuiIconButton-root": {
        width: 24,
        height: 24,
        padding: 0,
        "& svg": {
          width: 24,
          height: 24,
        },
      },
    },
  },
}));

export default function SearchWorkerModal({ onClose, open, fillData, newContractApprovalId, setWorkerId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: paramId } = useParams(); 
  const contractApprovalId = newContractApprovalId || paramId;

  const { result_getbyssn, work_center_employer_getbyssn_loading } = useSelector(
    (state) => state.workCenterEmployerState
  ) || {};

  const searchWorker = useCallback(
    async (ssn) => {
      if (ssn) {
        await dispatch(getWorkCenterEmployerBySsnAction(ssn, contractApprovalId));
      }
    },
    [dispatch, contractApprovalId]
  );
  const isLoading = useMemo(
    () => work_center_employer_getbyssn_loading,
    [work_center_employer_getbyssn_loading]
  );

  const data = useMemo(() => {
    if (isLoading || !result_getbyssn || !result_getbyssn.data) {
      return [];
    }
  
    if (Array.isArray(result_getbyssn.data)) {
      return result_getbyssn.data.map(worker => ({
        ...worker,
        onClick: () => {
          setWorkerId(worker.id);
          fillData(worker); 
          onClose();
        },
      }));
    } else {
      return [{
        ...result_getbyssn.data,
        onClick: () => {
          fillData(result_getbyssn.data);
          onClose();
        },
      }];
    }
  }, [isLoading, result_getbyssn, fillData, onClose]);

  return (
    <Dialog className={classes.dialog} maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2">Pesquisar Trabalhador</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <>
          <Typography variant="body1">Pesquise pelo Nome, NIF, NISS ou  Número do Visto do trabalhador</Typography>
          <FormAutoComplete
            handleOnSearch={searchWorker}
            isLoading={isLoading}
            data={data}
            placeholder="Pesquise pelo Nome, NIF, NISS ou  Número do Visto do trabalhador"
          />
        </>
      </DialogContent>
    </Dialog>
  );
}
