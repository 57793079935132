import React, { useMemo } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//style
import { makeStyles } from "@material-ui/core/styles";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function GenericButton({ className, id, ...props }) {
  const classes = { ...useStylesGeneric() };

  const type = useMemo(() => {
    if (props.type) {
      return props.type;
    }

    return props.typeSubmit ? "submit" : "button";
  }, [props.type, props.typeSubmit]);

  return (
    <>
      <Button
        data-testid={"generic-button-" + id}
        type={type}
        size={props.size}
        variant="contained"
        color={props.color === "primary" || props.color === "secondary" ? props.color : "default"}
        className={clsx(
          {
            [classes.containedTertiaryButton]: props.color === "tertiary",
            [classes.containedTertiaryRedButton]: props.color === "tertiaryRed",
            [classes.containedGhostButton]: props.color === "ghost",
            [classes.orangeButton]: props.color === "orange",
            [props.classes]: true,
          },
          className
        )}
        disabled={props.loading || props.disabled}
        {...props}
      >
        {props.children}
        {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    </>
  );
}
