import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { Controller } from "react-hook-form";
import { Box, FormGroup, FormHelperText, FormLabel, OutlinedInput, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages } from "resources/resources";

// Styles
const useStylesGeneric = makeStyles(componentStylesGeneric);

/**
 * Custom Masked Input Component
 * @param {Object} props - Component props
 * @param {function} props.onChange - Change handler
 * @param {string} props.name - Input name
 * @param {Object} props.mask - Mask configuration
 * @param {React.Ref} ref - Ref object
 */
const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={mask}
      unmask
      onAccept={(value) => onChange({ target: { name: props.name, value: String(value) } })}
    />
  );
});

TextMaskCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

/**
 * FormMaskedInput component
 * @param {Object} props - Component props
 * @param {string} props.name - Input name
 * @param {string} props.label - Input label
 * @param {Object} props.control - React Hook Form control object
 * @param {Object} props.rules - Validation rules
 * @param {string} [props.tooltipLabel] - Tooltip label
 * @param {boolean} [props.required=false] - Is input required
 * @param {string} [props.defaultValues=""] - Default value
 * @param {boolean} [props.disabled=false] - Is input disabled
 * @param {Object} [props.rest] - Other props
 */
function FormMaskedInput({
  name,
  label,
  control,
  rules,
  tooltipLabel,
  required = false,
  defaultValues = "",
  disabled = false,
  mask,
  ...rest
}) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();

  const labelClasses = clsx({
    [classes.labelError]: required,
  });

  return (
    <FormGroup style={{ width: "100%" }}>
      <FormLabel className={labelClasses}>
        {label}
        {tooltipLabel && (
          <Tooltip title={tooltipLabel}>
            <HelpIcon className={classes.tooltipInfoIcon} />
          </Tooltip>
        )}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValues}
        rules={{
          ...rules,
          required: {
            value: required,
            message: intl.formatMessage(messages.Message_Generic_MandatoryField),
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <OutlinedInput
              disabled={disabled}
              {...rest}
              {...field}
              value={field.value ? String(field.value) : ""}
              type="text"
              fullWidth
              autoComplete="off"
              inputComponent={TextMaskCustom}
              inputProps={{ mask, ...rest }} // Pass mask and other props to inputProps
              classes={{
                notchedOutline: clsx({
                  [classes.borderWarning]: !!error,
                }),
              }}
            />
            {!!error && (
              <FormHelperText error component={Box} color={theme.palette.warning.main + "!important"}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormGroup>
  );
}

FormMaskedInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  tooltipLabel: PropTypes.string,
  required: PropTypes.bool,
  defaultValues: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

FormMaskedInput.defaultProps = {
  rules: {},
  tooltipLabel: "",
  required: false,
  defaultValues: "",
  disabled: false,
};

export default FormMaskedInput;
