import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Grid, CardActions } from "@material-ui/core";
import GenericButton from "components/Buttons/GenericButton";
import FormInput from "components/FormFields/FormInput";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { DATASET } from "utils/const";
import { useQuery } from "@tanstack/react-query";
import { getFilteredMapRent } from "crud/rent.crud";
import FormDropdown from "components/FormFields/FormDropdown";
import FormDatePickerV2 from "components/FormFields/FormDatePickerV2";

const useStylesGeneric = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 6px 6px 0px #00000026",
  },

  title: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    textAlign: "left",
    color: theme.palette.primary.main,
  },

  actions: {
    justifyContent: "flex-end",
    borderTop: "none !important",
  },
}));

const FilterSection = () => {
  const classes = useStylesGeneric();

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { refetch } = useQuery(
    ["getFilteredMapRent"],
    () =>
      getFilteredMapRent({
        ...getValues(),
        startDate: getValues("startDate") ? getValues("startDate")?.format() : null,
        endDate: getValues("endDate") ? getValues("endDate")?.format() : null,
      }),
    {
      enabled: false,
    }
  );

  const datasets = useSelector((state) => state.datasetsState);
  const dispatch = useDispatch();

  const getDataSetByName = useCallback(
    (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
    [dispatch]
  );

  useEffect(() => {
    getDataSetByName(DATASET.MAP_RENT_STATES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setValue("pageIndex", 1);

    await refetch();
  };

  return (
    <Card className={classes.root} elevation={1}>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        title="Pesquisa de Mapas RENT"
      />

      <CardContent>
        <form id="filter-rent" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormInput label="Nº do Mapa RENT" placeholder="Nº do Mapa RENT" name="name" control={control} />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormDropdown
                control={control}
                name="stateId"
                label="Estado"
                options={datasets[DATASET.MAP_RENT_STATES]}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormDatePickerV2
                name="startDate"
                label="Data de Submissão (Desde)"
                control={control}
                maxDateToday={true}
                minDateToday={false}
                endDateFieldName="endDate"
                errors={errors}
                classes={classes}
                trigger={trigger}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormDatePickerV2
                name="endDate"
                label="Data de Submissão (Até)"
                control={control}
                maxDateToday={true}
                minDateToday={false}
                startDateFieldName="startDate"
                errors={errors}
                classes={classes}
                trigger={trigger}
                getValues={getValues}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>

      <CardActions className={classes.actions}>
        <GenericButton onClick={() => reset()} color="ghost">
          Limpar Filtros
        </GenericButton>
        <GenericButton type="submit" form="filter-rent" color="primary">
          Pesquisar
        </GenericButton>
      </CardActions>
    </Card>
  );
};

export default FilterSection;
