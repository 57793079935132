import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const CONTRACT_APPROVAL_URL = "ContractApproval";

export function createContractApproval(data) {
  return axios.post(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/create", data);
}

export function updateStatusContractApproval(data) {
  return axios.put(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/update-status", data);
}

export function submitContractApproval(data) {
  return axios.put(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/submit", {
    id: data?.id,
    employmentCenterId: data?.employmentCenterId,
    provinceId: data?.provinceId,
  });
}

export function deleteContractApproval(id) {
  return axios.delete(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL, {
    data: { id: id },
  });
}

export async function listContractApproval(data) {
  const response = await axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/getFiltered", {
    params: data,
  });

  return response.data;
}

export async function getContractApprovalDetails(id) {
  const response = await axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL, {
    params: { id: id },
  });

  return response.data;
}

export async function getContractApprovalPaymentInfo(id) {
  const response = await axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/get-payment-info", {
    params: { id: id },
  });

  return response.data;
}

export async function submitPaymentProof(data) {
  return axios.post(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/submit-payment-proof", data);
}

export async function getPaymentProofDocument(id) {
  const response = await axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_URL + "/get-payment-proof-document", {
    params: { Id: id },
    responseType: "blob",
  });
  return response.data;
}
