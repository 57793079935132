import React, { useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { connect, useDispatch } from "react-redux";
import FilterSection from "./FilterSection";
import ResultSection from "./ResultSection";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

import LocationHeader from "components/Headers/LocationHeader";
import { listContractApprovalAction } from "redux/actions/contractApprovalAction";
import { CONTRACT_APPROVAL } from "utils/actionsConsts";
import { CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE } from "utils/actionsConsts";
import { FormProvider, useForm } from "react-hook-form";
import { PAGINATION } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ContractApprovalList = (props) => {
  const classes = useStylesGeneric();
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      requestNumber: undefined,
      startDate: undefined,
      endDate: undefined,
      branchOfActivity: undefined,
      name: undefined,
      ssn: undefined,
      nif: undefined,
      contractType: undefined,
      state: undefined,
      pageIndex: 1,
      pageSize: PAGINATION.PAGE_SIZE,
    },
  });

  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const goToContractApprovalCreate = () => {
    dispatch({
      type: CONTRACT_APPROVAL.CLEAR_CONTRACT_APPROVAL_REQUEST,
    });
    dispatch({
      type: CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE.CLEAR_CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_REQUEST,
    });
    props.history.push("/admin/contract-approval/create");
  };

  return (
    <FormProvider {...form}>
        <Grid container classes={{ root: classes.containerRoot }}>
        <LocationHeader section="Homologação de contratos" />
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <PageTitleHeader title="Pedidos de Homologação de contratos" />
          <GenericButton
            typeSubmit={false}
            size="medium"
            color="tertiary"
            onClick={() => goToContractApprovalCreate()}
            style={{
              marginBottom: "37px",
            }}
          >
            Criar Pedido de Homologação de Contrato
          </GenericButton>
        </Grid>
        <Grid item xs={12} md={12}>
          <FilterSection
            page={page}
            props={props}
            handleChangePage={(value) => {
              handleChangePage(undefined, value);
            }}
            listContractApproval={props.listContractApproval}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box classes={{ root: classes.tableResultBoxRoot }}>
            <ResultSection
              page={page}
              handleChangePage={handleChangePage}
              contractApprovalState={props.contractApprovalState}
              props={props}
            />
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  listContractApproval: (data) => dispatch(listContractApprovalAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractApprovalList);
