import { makeStyles, Container as MUIContainer } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
}));

export default function Container({ children, className, ...props }) {
  const classes = useStyles();

  return (
    <MUIContainer {...props} className={clsx(classes.root, className)}>
      {children}
    </MUIContainer>
  );
}
