import { submitContractApproval } from "crud/contractApproval.crud";
import { createContractApproval, listContractApproval } from "crud/contractApproval.crud";

import { CONTRACT_APPROVAL, DIALOG } from "utils/actionsConsts";

export const createContractApprovalAction = (data) => async (dispatch) => {
  dispatch({
    type: CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL_LOADING,
    payload: true,
  });
  createContractApproval(data)
    .then((response) => {
      dispatch({
        type: CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL,
        payload: response.data,
      });
      dispatch({
        type: CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });       
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack: true,
            buttonLabel: "Ok",
            message: "Trabalhador adicionado com sucesso.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL,
        payload: false,
      });
      dispatch({
        type: CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const listContractApprovalAction = (data) => async (dispatch) => {
  const dataFilters = {
    PageIndex: data.pageIndex,
    PageSize: data.pageSize,
    RequestNumber: data.requestNumber,
    WorkerName: data.name,
    SSN: data.ssn,
    NIF: data.nif,
    StartDate: data.startDate,
    EndDate: data.endDate,
    ContractApprovalStatusId: data.contractApprovalStatusId,
    ContractTypeId: data.contractType,
  };
  dispatch({
    type: CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL_LOADING,
    payload: true,
  });
  listContractApproval(dataFilters)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          requestDate: record.requestDate,
          requestNumber: record.requestNumber,
          numberOfContracts: record.numberOfContracts,
          contractTypeDescriptions: record.contractTypeDescriptions,
          workersNames: record.workersNames,
          statusId: record.statusId,
          statusDescription: record.statusDescription,
          created: record.created,
        };
      });
      dispatch({
        type: CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL,
        result: false,
        payload: [],
      });
      dispatch({
        type: CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const submitContractApprovalAction = (data) => async (dispatch) => {
  dispatch({
    type: CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL_LOADING,
    payload: true,
  });
  submitContractApproval(data)
    .then(() => {
      dispatch({
        type: CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL,
        payload: true,
      });
      dispatch({
        type: CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            onClose: "/admin/contract-approval/list",
            goBack: true,
            buttonLabel: "Ok",
            message: "Pedido de homologação submetido com sucesso.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL,
        payload: false,
      });
      dispatch({
        type: CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};