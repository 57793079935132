import { WORK_CENTERS_EMPLOYER } from "utils/actionsConsts";

const workCenterEmployerReducer = (
  state = {
    work_center_employer_loading: false,
    total_records: 0,
    total_pages: 0,
    result_getbyssn: null,
  },
  action
) => {
  switch (action.type) {
    case WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER:
      return {
        ...state,
        work_center_employer_loading: false,
      };
    case WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_LOADING:
      return { ...state, work_center_employer_loading: true };

    case WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER:
      return {
        ...state,
        work_center_employer_loading: false,
      };
    case WORK_CENTERS_EMPLOYER.CREATE_WORK_CENTERS_EMPLOYER_LOADING:
      return { ...state, work_center_employer_loading: true };

    case WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER:
      return {
        ...state,
        work_center_employer_loading: false,
      };
    case WORK_CENTERS_EMPLOYER.UPDATE_WORK_CENTERS_EMPLOYER_LOADING:
      return { ...state, work_center_employer_loading: true };

    case WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER:
      return {
        ...state,
        result: action.payload,
        total_pages: action.payload.total_pages,
        total_records: action.payload.total_records,
        work_center_employer_loading: false,
      };
    case WORK_CENTERS_EMPLOYER.LIST_WORK_CENTERS_EMPLOYER_LOADING:
      return { ...state, work_center_employer_loading: action.payload };
    case WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_BY_SSN:
      return { 
        ...state,
        result_getbyssn: action.payload,
      };
    case WORK_CENTERS_EMPLOYER.GET_WORK_CENTERS_EMPLOYER_BY_SSN_LOADING:
      return { 
        ...state,
        work_center_employer_loading: action.payload,
      };
      case WORK_CENTERS_EMPLOYER.CLEAR_WORKER_SEARCH_BY_SSN:
      return {
        ...state,
        result_getbyssn: null
      };
    default:
      return state;
  }
};

export default workCenterEmployerReducer;
