import React from "react";
import { Box, Grid } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import PreviewData from "components/FormFields/PreviewData";
import DropzoneFieldV2 from "components/FormFields/DropzoneFieldV2";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ConditionGuard } from "guards/ConditionGuard";
import CenterSpinner from "components/Spinners/CenterSpinner";
import { formatCurrency } from "utils/string_utils";
import { jsonToFormData } from "utils/formData";
import useMessage from "hooks/useMessage";
import { getContractApprovalPaymentInfo } from "crud/contractApproval.crud";
import { submitPaymentProof } from "crud/contractApproval.crud";
import { getPaymentProofDocument } from "crud/contractApproval.crud";
import useDownloadDocument from "hooks/useDownloadDocument";
import { CONTRACT_APPROVAL_STATUS, DOCUMENT_AND_IMAGE_TYPES } from "utils/const";

const PaymentModal = ({ handleClosePaymentModal, isOpenPaymentModal }) => {
  const queryClient = useQueryClient();

  const { showSuccessMessage } = useMessage();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();

  const { data, isFetching } = useQuery(["getContractApprovalPaymentInfo"], () => getContractApprovalPaymentInfo(id));

  const { mutateAsync } = useMutation(["submitPayment"], (params) => submitPaymentProof(params), {
    onSuccess: () => {
      showSuccessMessage("Comprovativo de pagamento enviado com sucesso.", {
        okAction: () => {
          queryClient.invalidateQueries(["contractApprovalDetails", id]);
          handleClosePaymentModal();
        },
      });
    },
  });

  const onSubmit = async ({ document }) => {
    await mutateAsync(jsonToFormData({ id, document }));
  };

  const [{ state }, handleDownload] = useDownloadDocument(getPaymentProofDocument);

  const { data: contractApprovalDetailsData } = useQuery({ queryKey: ["contractApprovalDetails", id] });

  return (
    <GenericDialog open={isOpenPaymentModal} onClose={handleClosePaymentModal} fullWidth maxWidth="md">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "-22px",
        }}
      >
        <PageTitleHeader title={"Informações de Pagamento"} />
      </Box>
      <ConditionGuard condition={!isFetching} fallback={<CenterSpinner />}>
        <form id="payment-modal-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Referência para Pagamento (RUPE)" subtitle={data?.paymentRUPE} />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Prazo de Pagamento" subtitle={data?.paymentDeadline} />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              <PreviewData title="Valor do Pagamento" subtitle={formatCurrency(data?.paymentValue)} />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1rem",
              }}
            >
              {CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT ===
              contractApprovalDetailsData?.contractApprovalStatusKeyValue ? (
                <DropzoneFieldV2
                  label="Adicionar comprovativo de pagamento"
                  name="document"
                  control={control}
                  required
                  accept={DOCUMENT_AND_IMAGE_TYPES}
                />
              ) : (
                <ConditionGuard condition={data?.paymentDocumentName !== null}>
                  <GenericButton
                    typeSubmit={false}
                    color="ghost"
                    size="medium"
                    loading={state.get(id)}
                    onClick={() => handleDownload(id, `Comprovativo_Pagamento_Pedido_De_Homologação_${id}.pdf`)}
                  >
                    Comprovativo de pagamento
                  </GenericButton>
                </ConditionGuard>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={handleClosePaymentModal}>
                {CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT ===
                contractApprovalDetailsData?.contractApprovalStatusKeyValue
                  ? "Cancelar"
                  : "Fechar"}
              </GenericButton>
              <ConditionGuard
                condition={
                  CONTRACT_APPROVAL_STATUS.AWAITING_PAYMENT ===
                  contractApprovalDetailsData?.contractApprovalStatusKeyValue
                }
              >
                <GenericButton loading={isSubmitting} typeSubmit size="medium" color="primary">
                  Enviar
                </GenericButton>
              </ConditionGuard>
            </Grid>
          </Grid>
        </form>
      </ConditionGuard>
    </GenericDialog>
  );
};

export default PaymentModal;
