import { WORK_CENTERS } from "utils/actionsConsts";

const workCenterReducer = (
  state = { 
    work_center_loading: false,
    listWorkCenters_loading: false,
    total_records: 0,
    total_pages: 0,
  }, action) => {
  switch (action.type) {
    case WORK_CENTERS.GET_WORK_CENTERS:
      return {
        ...state,
        work_center_loading: false,
      };
    case WORK_CENTERS.GET_WORK_CENTERS_LOADING:
      return { ...state, work_center_loading: true };

    case WORK_CENTERS.CREATE_WORK_CENTERS:
      return {
        ...state,
        work_center_loading: false,
      };
      
    case WORK_CENTERS.UPDATE_WORK_CENTERS:
      return {
        ...state,
        work_center_loading: false,
      };
    case WORK_CENTERS.UPDATE_WORK_CENTERS_LOADING:
      return { ...state, work_center_loading: true };

      case WORK_CENTERS.LIST_WORK_CENTERS:
        return {
          ...state,
          result: action.payload,
          total_pages: action.payload.total_pages,
          total_records: action.payload.total_records,
        };
      case WORK_CENTERS.LIST_WORK_CENTERS_LOADING:
        return { ...state, work_center_loading: action.payload };

    default:
      return state;
  }
};

export default workCenterReducer;