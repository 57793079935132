import GenericButton from "components/Buttons/GenericButton";
import StepContainer from "../step-container";

import FilterSection from "views/admin/UserArea/UserColective/WorkCenters/FilterSection";
import { connect } from "react-redux";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { createWorkCenterAction } from "redux/actions/workCentersAction";
import { updateWorkCenterAction } from "redux/actions/workCentersAction";
import { getWorkCenterByIdAction } from "redux/actions/workCentersAction";
import { getFilteredWorkCenterAction } from "redux/actions/workCentersAction";
import ResultSection from "views/admin/UserArea/UserColective/WorkCenters/ResultSection";
import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import WorkCenterDialog from "views/admin/UserArea/UserColective/WorkCenters/WorkCenterModal";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

function WorkCentersInfoTab({ ...props }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedWorkCenter, setSelectedWorkCenter] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleOpenDialog = (workCenter, isEditing, isCreating) => {
    setSelectedWorkCenter(workCenter);
    setIsCreating(isCreating);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWorkCenter(null);
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries("listWorkCenters");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      provinceId: "",
      municipalityId: "",
      secundaryActivityId: "",
      tertiaryActivityId: "",
      pageIndex: 1,
      pageSize: 15,
    },
  });

  return (
    <StepContainer
      title="Dados de Centros"
      action={
        <GenericButton onClick={() => handleOpenDialog(null, false, true)} color="tertiary" size="small">
          Criar Centro
        </GenericButton>
      }
    >
      <FormProvider {...form}>
        <form id="form-2">
          <FilterSection />

          <Typography
            style={{
              fontWeight: 600,
            }}
            component="p"
            variant="body2"
          >
            Na tabela em baixo estão listados todos os centros de trabalho que integram os registos da área de
            utilizador e serão integrados no relatório RENT. <br />
            Poderá editar as informações entrando no detalhe de cada centro de trabalho. Após gravar ou adicionar o
            centro de trabalho, a lista de centros de trabalho no menu utilizadores será actualizada.
          </Typography>

          <ResultSection handleOpenDialog={handleOpenDialog} />
        </form>
      </FormProvider>

      {openDialog && (
        <WorkCenterDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          isCreating={isCreating}
          selectedWorkCenter={selectedWorkCenter}
          redirect={false}
          creatingFromRENT={true}
        />
      )}
    </StepContainer>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  createWorkCenter: (data) => dispatch(createWorkCenterAction(data)),
  updateWorkCenter: (data) => dispatch(updateWorkCenterAction(data)),
  getWorkCenterById: (id) => dispatch(getWorkCenterByIdAction(id)),
  getFilteredWorkCenters: (data) => dispatch(getFilteredWorkCenterAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkCentersInfoTab);
