import React, { useState, useEffect, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  CircularProgress,
  IconButton,
  OutlinedInput,
} from "@material-ui/core";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { Search } from "@material-ui/icons";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function FormAutoComplete({ handleOnSearch, isLoading, data, placeholder }) {
  const classes = { ...useStylesGeneric() };
  const [searchValue, setSearchValue] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  //Hook that alerts clicks outside of the passed ref
  function ClickOutsideComponent(ref) {
    useEffect(() => {
      //Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  ClickOutsideComponent(wrapperRef);

  const handleChangeSearchValue = (newValue) => {
    setSearchValue(newValue);
    setOpenDropdown(false);
  };

  useEffect(() => {
    if (searchValue) {
      setOpenDropdown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hasData = useMemo(() => data.length > 0, [data]);

  return (
    <Box overflow="hidden" ref={wrapperRef} gridGap={12} display="flex" flexDirection="column" width="100%">
      <OutlinedInput
        fullWidth
        placeholder={placeholder}
        onChange={(e) => handleChangeSearchValue(e?.target?.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOnSearch(searchValue);
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            {isLoading && <CircularProgress size={24} className={classes.circularProgress} />}

            {!isLoading && (
              <IconButton onClick={() => handleOnSearch(searchValue)} aria-label="search-button" edge="end">
                <Search />
              </IconButton>
            )}
          </InputAdornment>
        }
      />

      {hasData && openDropdown && (
        <List
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
          component="ol"
        >
          {data.map(({ name, onClick }, key) => (
            <>
              <ListItem key={key} onClick={onClick} button>
                <ListItemText primary={name} />
              </ListItem>
              <Divider key={key} variant="fullWidth" component="li" />
            </>
          ))}
        </List>
      )}
    </Box>
  );
}
