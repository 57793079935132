// @material-ui/icons components
import BubbleChart from "@material-ui/icons/BubbleChart";
import Dns from "@material-ui/icons/Dns";
// import Event from "@material-ui/icons/Event";
import FlashOn from "@material-ui/icons/FlashOn";
import House from "@material-ui/icons/House";
import Palette from "@material-ui/icons/Palette";
import PermMedia from "@material-ui/icons/PermMedia";
import { v4 as uuidv4 } from "uuid";

import AuthHomepage from "views/auth/AuthHomepage";

//Registration
import ColectiveRegistrationForm from "views/auth/ColectiveRegistrationSteps/MainForm";
import Register from "views/auth/Register.js";
import IndividualRegistrationForm from "views/auth/SimplifiedIndividualRegistration/IndividualRegistrationForm";

import Notifications from "views/admin/Components/Notifications.js";
import AdminHomepage from "views/admin/Homepage/AdminHomepage.js";

//UserArea
import UserColectiveMyFollowUpsList from "views/admin/UserArea/UserColective/MyFollowUpsList";
import UserColectiveProfile from "views/admin/UserArea/UserColective/UserProfile.js";
import UserSingularMyApplicationsList from "views/admin/UserArea/UserSingular/MyApplicationsList";
import UserSingularMyFollowUpsList from "views/admin/UserArea/UserSingular/MyFollowUpsList";
import UserSingularProfile from "views/admin/UserArea/UserSingular/UserProfile.js";

//InformationDissemination
import InformationDisseminationDetails from "views/admin/InformationDissemination/Details";
import InformationDissemination from "views/admin/InformationDissemination/InformationDissemination";
import StructuringProjects from "views/admin/InformationDissemination/StructuringProjects";

//Opportunities
import OpportunityCreation from "views/admin/Opportunities/UserColective/Opportunities/Create";
import UserColectiveOpportunitiesList from "views/admin/Opportunities/UserColective/Opportunities/OpportunitiesList";
import UserColectiveOpportunityDetails from "views/admin/Opportunities/UserColective/Opportunities/OpportunityDetails";
import UserColectiveOpportunityApplicationsList from "views/admin/Opportunities/UserColective/OpportunityApplications/OpportunityApplicationsList";
import UserColectiveOpportunityApplicationFollowUpDetails from "views/admin/Opportunities/UserColective/OpportunityApplicationsFollowUp/OpportunityApplicationFollowUpDetails";
import CandidateDetails from "views/admin/Opportunities/UserColective/OpportunityCandidates/CandidateDetails";
import UserSingularOpportunitiesList from "views/admin/Opportunities/UserSingular/Opportunities/OpportunitiesList";
import UserSingularOpportunityDetails from "views/admin/Opportunities/UserSingular/Opportunities/OpportunityDetails";
import UserSingularOpportunityApplicationDetails from "views/admin/Opportunities/UserSingular/OpportunityApplications/OpportunityApplicationDetails";
import UserSingularOpportunityApplicationFollowUpDetails from "views/admin/Opportunities/UserSingular/OpportunityApplicationsFollowUp/OpportunityApplicationFollowUpDetails";

//Applications
import ApplicationCreateFormPage from "views/admin/GovernmentProgram/Application/MainForm";
import MainFormEdit from "views/admin/GovernmentProgram/Application/MainFormEdit";
import ApplicationDetails from "views/admin/GovernmentProgram/UserApplications/ApplicationDetails";

//InstitutionalInformation
import DirectorBiography from "views/admin/InstitutionalInformation/DirectorBiography";
import InstitutionHistory from "views/admin/InstitutionalInformation/History";
import InstituteHolders from "views/admin/InstitutionalInformation/InstituteHolders";
import InstituteHolderBiography from "views/admin/InstitutionalInformation/InstituteHoldersBiography";
import InstitutionMissionVisionAndValues from "views/admin/InstitutionalInformation/MissionVisionAndValues";
import InstitutionOrganicStatus from "views/admin/InstitutionalInformation/OrganicStatus";
import InstitutionOrganizationChart from "views/admin/InstitutionalInformation/OrganizationChart";
import InstitutionOverview from "views/admin/InstitutionalInformation/Overview";

//Information
import InformationRatifyAgreement from "views/admin/Information/CompanyServices/RatifyAgreement";
import InformationEmploymentCenters from "views/admin/Information/EmploymentServices/EmploymentCenters";
import InformationEmploymentCentersNetwork from "views/admin/Information/EmploymentServices/EmploymentCentersNetwork";
import InformationForeignWorkersContractRegistration from "views/admin/Information/EmploymentServices/ForeignWorkersContractRegistration";
import InformationEntrepreneurshipCenters from "views/admin/Information/EntrepreneurshipServices/EntrepreneurshipCenters";
import InformationEntrepreneurshipCentersNetwork from "views/admin/Information/EntrepreneurshipServices/EntrepreneurshipCentersNetwork";
import InformationFormationOffer from "views/admin/Information/ProfessionalTraining/FormationOffer";
import InformationProfessionalWallet from "views/admin/Information/ProfessionalTraining/ProfessionalWallet";
import InformationTrainingCentersLicensing from "views/admin/Information/ProfessionalTraining/TrainingCentersLicensing";

//InformationSearch
import InformationSearch from "views/admin/InformationSearch/InformationSearch";

//Help
import FAQs from "views/admin/Help/FAQs";
import UsefulLinks from "views/admin/Help/UsefulLinks";

import ContactsPage from "views/contact-page";

import StatisticsDetails from "views/admin/Statistics/StatisticsDetails.js";

import PrivacyPolicyPage from "views/admin/Components/PrivacyPolicyPage.js";
import TermsAndConditionsPage from "views/admin/Components/TermsAndConditionsPage.js";

import ProfessionalTrainingRedirect from "views/admin/Components/ProfessionalTrainingRedirect";

//SSO
import Login from "views/sso/Login";

//National Scholarship of trainers
import NationalScholarshipTrainers from "views/admin/Components/NationalScholarshipTrainers";

//RENTs
import RENTCreate from "views/admin/RENT/Create";
import RENTList from "views/admin/RENT/List";
import RENTDetails from "views/admin/RENT/Details";
import RentCertificateValid from "views/admin/RENT/Certificate";
import RentCertificateError from "views/admin/RENT/Certificate/Error";

//Contract Approval
import ContractApprovalCreate from "views/admin/CompanyServices/ContractApproval/Create";
import ContractApprovalList from "views/admin/CompanyServices/ContractApproval/List";
import ContractApprovalDetails from "views/admin/CompanyServices/ContractApproval/Details";
import EditMapRENT from "views/admin/RENT/Edit";
import ContractApprovalCertificateValid from "views/admin/CompanyServices/ContractApproval/Certificate";
import ContractApprovalCertificateError from "views/admin/CompanyServices/ContractApproval/Certificate/Error";

//! ***IMPORTANT***:
//! If you add a new informative page, make sure to add its content to the views/admin/InformationSearch/InformationSearch.js resourcesDataList!

var routes = [
  {
    path: "/home",
    name: "AdminHomepage",
    miniName: "H",
    component: AdminHomepage,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Examples",
    icon: PermMedia,
    iconColor: "Warning",
    state: "examplesCollapse",
    invisible: true,
    views: [
      {
        path: "/home",
        name: "Home",
        miniName: "H",
        component: AuthHomepage,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/individual-user-registration",
        name: "IndividualRegistrationForm",
        miniName: "R",
        component: IndividualRegistrationForm,
        layout: "/auth",
      },
      {
        path: "/collective-user-registration",
        name: "ColectiveRegistrationForm",
        miniName: "R",
        component: ColectiveRegistrationForm,
        layout: "/auth",
      },
    ],
  },

  {
    collapse: true,
    name: "UserArea",
    icon: PermMedia,
    iconColor: "Warning",
    state: "examplesCollapse",
    invisible: true,
    views: [
      {
        path: "/personal-profile",
        name: "My Profile",
        miniName: "P",
        component: UserSingularProfile,
        layout: "/admin",
      },
      {
        path: "/my-applications/:applicationType?",
        name: "My Applications",
        miniName: "P",
        component: UserSingularMyApplicationsList,
        layout: "/admin",
      },
      {
        path: "/my-followups",
        name: "My Followups",
        miniName: "P",
        component: UserSingularMyFollowUpsList,
        layout: "/admin",
      },

      {
        path: "/user-profile",
        name: "User Profile",
        miniName: "P",
        component: UserColectiveProfile,
        layout: "/admin",
      },
      {
        path: "/my-candidates-followup",
        name: "My Followups",
        miniName: "P",
        component: UserColectiveMyFollowUpsList,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Help",
    icon: PermMedia,
    iconColor: "Warning",
    state: "helpCollapse",
    invisible: true,
    views: [
      {
        path: "/frequently-asked-questions",
        name: "FAQs",
        miniName: "FAQ",
        component: FAQs,
        layout: "/admin",
      },
      {
        path: "/useful-links",
        name: "UsefulLinks",
        miniName: "UL",
        component: UsefulLinks,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Applications",
    iconColor: "Primary",
    state: "applicationsCollapse",
    views: [
      {
        path: "/application-create/",
        name: "Applications",
        miniName: "A",
        component: ApplicationCreateFormPage,
        layout: "/admin",
      },
      {
        path: "/edit-application/:applicationNumber",
        name: "Editar Candidatura",
        miniName: "EA",
        component: MainFormEdit,
        layout: "/admin",
      },
      {
        path: "/application-details/:applicationNumber",
        name: "Detalhes de Candidatura",
        miniName: "AD",
        component: ApplicationDetails,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "InformationDissemination",
    iconColor: "Primary",
    state: "informationDisseminationCollapse",
    views: [
      {
        path: "/informationDissemination/:infoType?",
        name: "Listar Informação",
        miniName: "M",
        component: InformationDissemination,
        layout: "/admin",
      },
      {
        path: "/informationDissemination/:infoType?",
        name: "Listar Informação",
        miniName: "M",
        component: InformationDissemination,
        layout: "/auth",
      },
      {
        path: "/informationDissemination-details/:infoKey",
        name: "Detalhes",
        miniName: "D",
        component: InformationDisseminationDetails,
        layout: "/admin",
      },
      {
        path: "/informationDissemination-details/:infoKey",
        name: "Detalhes",
        miniName: "D",
        component: InformationDisseminationDetails,
        layout: "/auth",
      },
      {
        path: "/structuringProjects",
        name: "StructuringProjects",
        miniName: "M",
        component: StructuringProjects,
        layout: "/admin",
      },
      {
        path: "/structuringProjects",
        name: "StructuringProjects",
        miniName: "M",
        component: StructuringProjects,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Opportunities",
    icon: House,
    iconColor: "Primary",
    state: "opportunitiesCollapse",
    views: [
      {
        path: "/opportunities",
        name: "Listar Informação",
        miniName: "L",
        component: UserColectiveOpportunitiesList,
        layout: "/admin",
      },
      {
        path: "/user-colective-opportunity-applications/:opportunityType?",
        name: "Listar candidaturas a emprego e estágio para utilizadores coletivos",
        miniName: "LCUC",
        component: UserColectiveOpportunityApplicationsList,
        layout: "/admin",
      },
      {
        path: "/available-opportunities/:oppType?",
        name: "Listar Informação para utilizadores singulares",
        miniName: "LS",
        component: UserSingularOpportunitiesList,
        layout: "/admin",
      },
      {
        path: "/opportunities-create/:oppType?",
        name: "Criar",
        miniName: "C",
        component: OpportunityCreation,
        layout: "/admin",
      },
      {
        path: "/opportunity-details/:opportunityId",
        name: "Detalhes",
        miniName: "D",
        component: UserColectiveOpportunityDetails,
        layout: "/admin",
      },
      {
        path: "/available-opportunity-details/:opportunityId",
        name: "Detalhes",
        miniName: "D",
        component: UserSingularOpportunityDetails,
        key: uuidv4(),
        layout: "/admin",
      },
      {
        path: "/candidate-details/:alternateKey",
        name: "Detalhes",
        miniName: "D",
        component: CandidateDetails,
        layout: "/admin",
      },
      {
        path: "/opportunity-application-details/:candidateCode",
        name: "Detalhes",
        miniName: "D",
        component: UserSingularOpportunityApplicationDetails,
        layout: "/admin",
      },
      {
        path: "/user-colective-opportunity-application-followup-details/:candidateCode",
        name: "Detalhes de follow up de candidaturas a emprego e estágio",
        miniName: "D",
        component: UserColectiveOpportunityApplicationFollowUpDetails,
        layout: "/admin",
      },
      {
        path: "/opportunity-application-followup-details/:candidateCode",
        name: "Detalhes de follow up de candidaturas a emprego e estágio",
        miniName: "D",
        component: UserSingularOpportunityApplicationFollowUpDetails,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "InstitutionalInformation",
    iconColor: "Primary",
    state: "institutionalInformationCollapse",
    views: [
      {
        path: "/institution-overview",
        name: "InstitutionOverview",
        miniName: "M",
        component: InstitutionOverview,
        layout: "/auth",
      },
      {
        path: "/institution-history",
        name: "InstitutionHistory",
        miniName: "M",
        component: InstitutionHistory,
        layout: "/auth",
      },
      {
        path: "/institution-mission-vision-values",
        name: "InstitutionMissionVisionAndValues",
        miniName: "M",
        component: InstitutionMissionVisionAndValues,
        layout: "/auth",
      },
      {
        path: "/institution-organic-status",
        name: "InstitutionOrganicStatus",
        miniName: "M",
        component: InstitutionOrganicStatus,
        layout: "/auth",
      },
      {
        path: "/institution-organization-chart",
        name: "InstitutionOrganizationChart",
        miniName: "M",
        component: InstitutionOrganizationChart,
        layout: "/auth",
      },
      {
        path: "/director-biography",
        name: "DirectorBiography",
        miniName: "M",
        component: DirectorBiography,
        layout: "/auth",
      },
      {
        path: "/institute-holders/:holderLink",
        name: "InstituteHolderBiography",
        miniName: "M",
        component: InstituteHolderBiography,
        layout: "/auth",
      },
      {
        path: "/institute-holders",
        name: "InstituteHolders",
        miniName: "M",
        component: InstituteHolders,
        layout: "/auth",
      },
    ],
  },

  {
    collapse: true,
    name: "InstitutionalInformation",
    iconColor: "Primary",
    state: "institutionalInformationCollapse",
    views: [
      {
        path: "/institution-overview",
        name: "InstitutionOverview",
        miniName: "M",
        component: InstitutionOverview,
        layout: "/admin",
      },
      {
        path: "/institution-history",
        name: "InstitutionHistory",
        miniName: "M",
        component: InstitutionHistory,
        layout: "/admin",
      },
      {
        path: "/institution-mission-vision-values",
        name: "InstitutionMissionVisionAndValues",
        miniName: "M",
        component: InstitutionMissionVisionAndValues,
        layout: "/admin",
      },
      {
        path: "/institution-organic-status",
        name: "InstitutionOrganicStatus",
        miniName: "M",
        component: InstitutionOrganicStatus,
        layout: "/admin",
      },
      {
        path: "/institution-organization-chart",
        name: "InstitutionOrganizationChart",
        miniName: "M",
        component: InstitutionOrganizationChart,
        layout: "/admin",
      },
      {
        path: "/director-biography",
        name: "DirectorBiography",
        miniName: "M",
        component: DirectorBiography,
        layout: "/admin",
      },
      {
        path: "/institute-holders",
        name: "InstituteHolders",
        miniName: "M",
        component: InstituteHolders,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Information",
    iconColor: "Primary",
    state: "InformationCollapse",
    views: [
      {
        path: "/information-employment-centers",
        name: "InformationEmploymentCenters",
        miniName: "M",
        component: InformationEmploymentCenters,
        layout: "/auth",
      },
      {
        path: "/information-employment-centers-network",
        name: "InformationEmploymentCentersNetwork",
        miniName: "M",
        component: InformationEmploymentCentersNetwork,
        layout: "/auth",
      },
      {
        path: "/information-foreign-workers-contract-registration",
        name: "InformationForeignWorkersContractRegistration",
        miniName: "M",
        component: InformationForeignWorkersContractRegistration,
        layout: "/auth",
      },
      {
        path: "/information-entrepreneurship-centers",
        name: "InformationEntrepreneurshipCenters",
        miniName: "M",
        component: InformationEntrepreneurshipCenters,
        layout: "/auth",
      },
      {
        path: "/information-entrepreneurship-centers-network",
        name: "InformationEntrepreneurshipCentersNetwork",
        miniName: "M",
        component: InformationEntrepreneurshipCentersNetwork,
        layout: "/auth",
      },
      {
        path: "/information-professional-wallet",
        name: "InformationProfessionalWallet",
        miniName: "M",
        component: InformationProfessionalWallet,
        layout: "/auth",
      },
      {
        path: "/information-formation-offer",
        name: "InformationFormationOffer",
        miniName: "M",
        component: InformationFormationOffer,
        layout: "/auth",
      },
      {
        path: "/information-training-centers-licensing",
        name: "InformationTrainingCentersLicensing",
        miniName: "M",
        component: InformationTrainingCentersLicensing,
        layout: "/auth",
      },
      {
        path: "/ratify-agreement",
        name: "InformationRatifyAgreement",
        miniName: "M",
        component: InformationRatifyAgreement,
        layout: "/auth",
      },
    ],
  },

  {
    collapse: true,
    name: "RENT",
    iconColor: "Primary",
    state: "RENTCollapse",
    views: [
      {
        path: "/rent/create",
        name: "RENTCreate",
        miniName: "C",
        component: RENTCreate,
        layout: "/admin",
      },
      {
        path: "/rent/list",
        name: "RENTList",
        miniName: "C",
        component: RENTList,
        layout: "/admin",
      },
      {
        path: "/rent/details/:id",
        name: "RENTDetails",
        miniName: "C",
        component: RENTDetails,
        layout: "/admin",
      },
      {
        path: "/rent/edit/:id",
        name: "EditMapRENT",
        miniName: "C",
        component: EditMapRENT,
        layout: "/admin",
      },
      {
        path: "/rent/certificate/error",
        name: "RentCertificateError",
        miniName: "C",
        component: RentCertificateError,
        layout: "/auth",
      },
      {
        path: "/rent/certificate/:id",
        name: "RentCertificateValid",
        miniName: "C",
        component: RentCertificateValid,
        layout: "/auth",
      }
    ],
  },

  {
    collapse: true,
    name: "Information",
    iconColor: "Primary",
    state: "InformationCollapse",
    views: [
      {
        path: "/information-employment-centers",
        name: "InformationEmploymentCenters",
        miniName: "M",
        component: InformationEmploymentCenters,
        layout: "/admin",
      },
      {
        path: "/information-employment-centers-network",
        name: "InformationEmploymentCentersNetwork",
        miniName: "M",
        component: InformationEmploymentCentersNetwork,
        layout: "/admin",
      },
      {
        path: "/information-foreign-workers-contract-registration",
        name: "InformationForeignWorkersContractRegistration",
        miniName: "M",
        component: InformationForeignWorkersContractRegistration,
        layout: "/admin",
      },
      {
        path: "/information-entrepreneurship-centers",
        name: "InformationEntrepreneurshipCenters",
        miniName: "M",
        component: InformationEntrepreneurshipCenters,
        layout: "/admin",
      },
      {
        path: "/information-entrepreneurship-centers-network",
        name: "InformationEntrepreneurshipCentersNetwork",
        miniName: "M",
        component: InformationEntrepreneurshipCentersNetwork,
        layout: "/admin",
      },
      {
        path: "/information-professional-wallet",
        name: "InformationProfessionalWallet",
        miniName: "M",
        component: InformationProfessionalWallet,
        layout: "/admin",
      },
      {
        path: "/information-formation-offer",
        name: "InformationFormationOffer",
        miniName: "M",
        component: InformationFormationOffer,
        layout: "/admin",
      },
      {
        path: "/information-training-centers-licensing",
        name: "InformationTrainingCentersLicensing",
        miniName: "M",
        component: InformationTrainingCentersLicensing,
        layout: "/admin",
      },
      {
        path: "/ratify-agreement",
        name: "InformationRatifyAgreement",
        miniName: "M",
        component: InformationRatifyAgreement,
        layout: "/admin",
      },
      {
        path: "/formacao-redirect",
        name: "ProfessionalTrainingRedirect",
        miniName: "M",
        component: ProfessionalTrainingRedirect,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/information-search/:searchText",
    name: "InformationSearch",
    miniName: "N",
    component: InformationSearch,
    layout: "/admin",
  },

  {
    path: "/information-search/:searchText",
    name: "InformationSearch",
    miniName: "N",
    component: InformationSearch,
    layout: "/auth",
  },

  {
    path: "/notifications",
    name: "Notifications",
    miniName: "N",
    component: Notifications,
    layout: "/admin",
  },

  {
    path: "/contacts",
    name: "Contacts",
    miniName: "C",
    component: ContactsPage,
    layout: "/admin",
  },
  {
    path: "/contacts",
    name: "Contacts",
    miniName: "C",
    component: ContactsPage,
    layout: "/auth",
  },

  {
    path: "/statistics",
    name: "Statistics",
    miniName: "S",
    component: StatisticsDetails,
    layout: "/admin",
  },
  {
    path: "/statistics",
    name: "Statistics",
    miniName: "S",
    component: StatisticsDetails,
    layout: "/auth",
  },

  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    miniName: "P",
    component: PrivacyPolicyPage,
    layout: "/auth",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    miniName: "P",
    component: PrivacyPolicyPage,
    layout: "/admin",
  },

  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    miniName: "T",
    component: TermsAndConditionsPage,
    layout: "/auth",
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    miniName: "T",
    component: TermsAndConditionsPage,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Help",
    icon: PermMedia,
    iconColor: "Warning",
    state: "helpCollapse",
    invisible: true,
    views: [
      {
        path: "/frequently-asked-questions",
        name: "FAQs",
        miniName: "FAQ",
        component: FAQs,
        layout: "/auth",
      },
      {
        path: "/useful-links",
        name: "UsefulLinks",
        miniName: "UL",
        component: UsefulLinks,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "SSO",
    iconColor: "Primary",
    state: "ssoCollapse",
    views: [
      {
        path: "/login/:token/:redirectURL",
        name: "Login",
        miniName: "A",
        component: Login,
        layout: "/sso",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/sso",
      },
      {
        path: "/individual-user-registration",
        name: "IndividualRegistrationForm",
        miniName: "R",
        component: IndividualRegistrationForm,
        layout: "/sso",
      },
      {
        path: "/collective-user-registration",
        name: "ColectiveRegistrationForm",
        miniName: "R",
        component: ColectiveRegistrationForm,
        layout: "/sso",
      },
    ],
  },
  {
    collapse: true,
    name: "ContractApproval",
    iconColor: "Primary",
    state: "contractApprovalCollapse",
    views: [
      {
        path: "/contract-approval/create",
        name: "ContractApprovalCreate",
        miniName: "C",
        component: ContractApprovalCreate,
        layout: "/admin",
      },
      {
        path: "/contract-approval/list",
        name: "ContractApprovalList",
        miniName: "C",
        component: ContractApprovalList,
        layout: "/admin",
      },
      {
        path: "/contract-approval/details/:id",
        name: "ContractApprovalDetails",
        miniName: "C",
        component: ContractApprovalDetails,
        layout: "/admin",
      },
      {
        path: "/contract-approval/certificate/error",
        name: "ContractApprovalCertificateError",
        miniName: "C",
        component: ContractApprovalCertificateError,
        layout: "/auth",
      },
      {
        path: "/contract-approval/certificate/:id",
        name: "ContractApprovalCertificateValid",
        miniName: "C",
        component: ContractApprovalCertificateValid,
        layout: "/auth",
      },
    ],
  },
  {
    path: "/national-scholarship-of-trainers",
    name: "NationalScholarshipTrainers",
    miniName: "NST",
    component: NationalScholarshipTrainers,
    layout: "/admin",
  },
  {
    path: "/national-scholarship-of-trainers",
    name: "NationalScholarshipTrainers",
    miniName: "NST",
    component: NationalScholarshipTrainers,
    layout: "/auth",
  },
  {
    divider: true,
  },
  {
    title: "Documentation",
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/overview/argon-dashboard?ref=admui-admin-sidebar",
    name: "Getting started",
    icon: FlashOn,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/colors/argon-dashboard?ref=admui-admin-sidebar",
    name: "Foundation",
    icon: Palette,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/alerts/argon-dashboard?ref=admui-admin-sidebar",
    name: "Components",
    icon: Dns,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/google-maps/argon-dashboard?ref=admui-admin-sidebar",
    name: "Plugins",
    icon: BubbleChart,
  },
];
export default routes;
