import React, { useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import WorkCenterModal from "./WorkCenterModal";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { connect } from "react-redux";
import FilterSection from "./FilterSection";
import ResultSection from "./ResultSection";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import {
  createWorkCenterAction,
  updateWorkCenterAction,
  getWorkCenterByIdAction,
  getFilteredWorkCenterAction,
} from "redux/actions/workCentersAction";
import { FormProvider, useForm } from "react-hook-form";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const WorkCenters = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedWorkCenter, setSelectedWorkCenter] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleOpenDialog = (workCenter, isCreating) => {
    setSelectedWorkCenter(workCenter);
    setIsCreating(isCreating);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWorkCenter(null);
    props.getFilteredWorkCenters();
  };

  const classes = useStylesGeneric();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      provinceId: "",
      municipalityId: "",
      secondaryActivityId: "",
      tertiaryActivityId: "",

      pageIndex: 1,
      pageSize: 15,
    },
  });

  return (
    <FormProvider {...form}>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <PageTitleHeader title="Centros de Trabalho" />
        <GenericButton
          typeSubmit={false}
          size="medium"
          color="tertiary"
          onClick={() => handleOpenDialog(null, true)}
          style={{
            marginBottom: "37px",
          }}
        >
          Criar Centro de Trabalho
        </GenericButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <FilterSection />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
          Na tabela em baixo estão listados todos os centros de trabalho que integram a lista que registou na sua área
          de utilizador e serão integrados no relatório RENT. <br />
          Poderá editar as informações directamente entrando no detalhe de cada centro de trabalho. Após gravar ou
          adicionar o centro de trabalho, a lista no menu de utilizadores será actualizada.
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box classes={{ root: classes.tableResultBoxRoot }}>
          <ResultSection handleOpenDialog={handleOpenDialog} />
        </Box>
      </Grid>
      {openDialog && (
        <WorkCenterModal
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          getDataSetByName={props.getDataSetByName}
          props={props}
          isCreating={isCreating}
          selectedWorkCenter={selectedWorkCenter}
        />
      )}
    </FormProvider>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  createWorkCenter: (data, onCloseAction) => dispatch(createWorkCenterAction(data, onCloseAction)),
  updateWorkCenter: (data) => dispatch(updateWorkCenterAction(data)),
  getWorkCenterById: (id) => dispatch(getWorkCenterByIdAction(id)),
  getFilteredWorkCenters: (data) => dispatch(getFilteredWorkCenterAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkCenters);
