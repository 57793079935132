import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL = "ContractApprovalWorkCenterEmployee";
export const GET_CONTRACT = "/get-contract-by-id";

export function createContractApprovalWorkCenterEmployee(data) {
  return axios.post(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/create", data);
}

export function updateStatusContractApprovalWorkCenterEmployee(data) {
  return axios.put(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/update-status", data);
}

export function updateContractApprovalWorkCenterEmployee(data) {
  return axios.put(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/update", data);
}

export function deleteContractApprovalWorkCenterEmployee(id) {
  return axios.delete(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL, { params: { id: id } });
}

export function listContractApprovalWorkCenterEmployee(contractApprovalId) {
  return axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/GetList", {
    params: { contractApprovalId: contractApprovalId },
  });
}

export function getContractApprovalWorkCenterEmployeeById(id) {
  return axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL, { params: { id: id } });
}

export async function getContract(data) {
  const response = await axios.get(EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + GET_CONTRACT, {
    params: {
      Id: data,
    },
    responseType: "blob",
  });

  return response.data;
}

export function getContractApprovalCertificateInfoFromToken(id) {
  return axios.get(
    EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-contract-approval-certificate-info-from-token",
    { params: { CertificateNumberToken: id } }
  );
}

export function getContractApprovalCertificateInfo(id) {
  return axios.get(
    EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-contract-approval-certificate-info",
    { params: { CertificateNumber: id } }
  );
}

export async function getContractApprovalCertificateFromToken(id) {
  console.log(id);
  const response = await axios.get(
    EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-contract-approval-certificate-from-token",
    {
      params: { CertificateNumberToken: id },
      responseType: "blob",
    }
  );

  return response.data;
}

 export async function getContractApprovalContractFromToken(id) {

  const url = EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-contract-approval-contract-from-token" + "?CertificateNumberToken=" + id;

  console.log(url);

  const response = await axios.get(
    url,
    {
      responseType: "blob",
    }
  );
  return response.data;
} 

export async function getContractApprovalCertificate(id) {
  const response = await axios.get(
    EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-contract-approval-certificate",
    {
      params: { CertificateNumber: id },
      responseType: "blob",
    }
  );

  return response.data;
}

export function getCertificateNumberFromToken(token) {
  return axios.get(
    EXTERNAL_API_URL + CONTRACT_APPROVAL_WORKCENTER_EMPLOYEE_URL + "/get-certificate-number-from-token",
    { params: { token: token } }
  );
}
