import "react-datetime/css/react-datetime.css";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt";

import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { FormHelperText, FormGroup, FormLabel, Box, OutlinedInput, InputAdornment, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DateRange, Help } from "@material-ui/icons";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages, errorMessages } from "resources/resources";
import { REGEX_PATTERNS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function FormDatePickerV2({
  name,
  label,
  control,
  rules,
  getValues,
  trigger,
  maxDateToday,
  minDateToday,
  minDate,
  startDateFieldName,
  endDateFieldName,
  isRequired,
  disabled,
  timeFormat = false,
  format = "DD/MM/YYYY",
  placeholder,
  adornmentPosition = "start",
  tooltipLabel,
  dateFormat,
  ...rest
}) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();

  const labelClasses = clsx({
    [classes.labelError]: isRequired,
  });

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  const isValidDate = (current) => {
    const selectedDate = current.toDate();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for accurate comparison

    if (maxDateToday && !(selectedDate <= currentDate)) return false;

    if (minDateToday && !(selectedDate >= currentDate)) return false;

    if (minDate && !(selectedDate >= minDate)) return false;

    if (
      startDateFieldName &&
      getValues(startDateFieldName)?._isAMomentObject &&
      !(getValues(startDateFieldName) <= selectedDate)
    )
      return false;

    if (
      endDateFieldName &&
      getValues(endDateFieldName)?._isAMomentObject &&
      !(selectedDate <= getValues(endDateFieldName))
    )
      return false;

    return true;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt">
      <FormGroup>
        <FormLabel className={labelClasses}>
          {label}
          {tooltipLabel && (
            <Tooltip title={tooltipLabel}>
              <Help className={classes.tooltipInfoIcon} />
            </Tooltip>
          )}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            required: {
              value: isRequired,
              message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
            },
            validate: {
              isValidDateFormat: (value) => {
                return !value || value._isAMomentObject || intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
              },
              maxDate: (value) => {
                return (
                  !maxDateToday ||
                  !value ||
                  value <= moment().startOf("day") ||
                  intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                );
              },
              minDate: (value) => {
                return (
                  !minDateToday ||
                  !value ||
                  value >= moment().startOf("day") ||
                  intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToTodayField)
                );
              },
              isLessThanEndDate: (value) => {
                return (
                  !endDateFieldName ||
                  !getValues(endDateFieldName) ||
                  !value ||
                  (value !== "" &&
                    value._isAMomentObject &&
                    getValues(endDateFieldName)._isAMomentObject &&
                    value <= getValues(endDateFieldName)) ||
                  intl.formatMessage(messages.Message_Generic_DateFromLessThanOrEqualToDateToField)
                );
              },
              isGreaterThanStartDate: (value) => {
                return (
                  !startDateFieldName ||
                  !getValues(startDateFieldName) ||
                  !value ||
                  (value !== "" &&
                    value._isAMomentObject &&
                    getValues(startDateFieldName)._isAMomentObject &&
                    value >= getValues(startDateFieldName)) ||
                  intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToDateFromField)
                );
              },
            },
          }}
          render={({ field: { value, ref, onChange, ...field }, fieldState: { error } }) => (
            <>
              <ReactDatetime
                {...field}
                utc
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (startDateFieldName && getValues(startDateFieldName)) trigger(startDateFieldName);

                  if (endDateFieldName && getValues(endDateFieldName)) trigger(endDateFieldName);

                  trigger(name);
                }}
                locale="pt"
                dateFormat={dateFormat || "DD/MM/YYYY"}
                timeFormat={timeFormat}
                isValidDate={isValidDate}
                closeOnSelect={true}
                closeOnTab={true}
                closeOnClickOutside={true}
                className={classes.formDatePickerRoot}
                inputProps={{
                  className: "",
                  value: value && value._isAMomentObject ? value.format(format) : value ?? "",
                  placeholder: placeholder ?? format,
                }}
                renderInput={(dateInputProps) => (
                  <OutlinedInput
                    {...dateInputProps}
                    {...rest}
                    ref={ref}
                    onClick={!disabled ? dateInputProps.onClick : undefined}
                    onFocus={!disabled ? dateInputProps.onFocus : undefined}
                    onKeyDown={!disabled ? dateInputProps.onKeyDown : undefined}
                    disabled={disabled}
                    placeholder={placeholder ?? format}
                    type={dateInputProps.type}
                    onChange={(e) => handleValidDate(e.target.value, dateInputProps.onChange)}
                    startAdornment={
                      adornmentPosition === "start" && (
                        <InputAdornment position="start">
                          <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                        </InputAdornment>
                      )
                    }
                    endAdornment={
                      adornmentPosition === "end" && (
                        <InputAdornment position="end">
                          <Box component={DateRange} marginLeft=".5rem" color={theme.palette.primary.main} />
                        </InputAdornment>
                      )
                    }
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: !!error,
                      }),
                    }}
                    fullWidth
                  />
                )}
              />
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {error?.message}
              </FormHelperText>
            </>
          )}
        />
      </FormGroup>
    </MuiPickersUtilsProvider>
  );
}
