import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import moment from "moment";
import { ConditionGuard } from "guards/ConditionGuard";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const ResultSection = ({ handleOpenDialog, detailsEnabled = true }) => {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const { watch, setValue } = useFormContext();

  const { data, isLoading, refetch } = useQuery(["listWorkCenters"], {
    select: (response) => {
      if (!response?.data) return {};

      return {
        data: response.data.data,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      };
    },
  });

  const page = watch("pageIndex");

  const tableHead = [
    "Data de Registo",
    "Nome do Centro",
    "Província",
    "Munícipio",
    "Actividade Secundária",
    "Actividade Terciária",
  ];

  const handleOpenDetails = (workCenter) => {
    handleOpenDialog(workCenter);
  };

  return (
    <>
      <CardTableContainer title={"Lista de Centros de Trabalho Registados Na Sua Empresa"} count={data?.totalCount}>
        <TableContainer>
          <ConditionGuard condition={!isLoading} fallback={<TableBackdrop open />}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <ConditionGuard condition={detailsEnabled}>
                    <TableCell
                      key="detailsCol"
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead +
                          " " +
                          classes.tableCellHeadStickyRight,
                      }}
                    >
                      Detalhes
                    </TableCell>
                  </ConditionGuard>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(prop.created).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.province}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipality}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.secundaryActivity}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.tertiaryActivity}</TableCell>
                    <ConditionGuard condition={detailsEnabled}>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                        <Tooltip title="Detalhes">
                          <IconButton onClick={() => handleOpenDetails(prop.id)}>
                            <Box
                              component={ArrowForwardIosIcon}
                              width="12px!important"
                              height="12px!important"
                              color={theme.palette.primary.main}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </ConditionGuard>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </ConditionGuard>
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

        <Pagination
          className={classes.alignPagination}
          count={data?.totalPages}
          page={page}
          onChange={(_, value) => {
            setValue("pageIndex", value);

            refetch();
          }}
          color="primary"
        />
      </CardTableContainer>

      {!isLoading && data?.data?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ResultSection);
