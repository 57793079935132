import { CONTRACT_APPROVAL } from "utils/actionsConsts";

const contractApprovalReducer = (
  state = {
    contract_approval_loading: false,
  },
  action
) => {
  switch (action.type) {
    case CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL:
      return {
        ...state,
        new_contract_approval: action.payload,
        contract_approval_loading: false,
      };
    case CONTRACT_APPROVAL.CREATE_CONTRACT_APPROVAL_LOADING:
      return { ...state, contract_approval_loading: true };
    case CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL:
      return {
        ...state,
        result: action.payload,
        contract_approval_loading: false,
      };
    case CONTRACT_APPROVAL.LIST_CONTRACT_APPROVAL_LOADING:
      return { ...state, contract_approval_loading: true };
    case CONTRACT_APPROVAL.CLEAR_CONTRACT_APPROVAL_REQUEST:
      return {
        ...state,
        new_contract_approval: null,
      };
      case CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL:
      return {
        ...state,
        contract_approval_loading: false,
      };
    case CONTRACT_APPROVAL.SUBMIT_CONTRACT_APPROVAL_LOADING:
      return { ...state, contract_approval_loading: true };
    default:
      return state;
  }
};

export default contractApprovalReducer;
