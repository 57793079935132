import { Container, makeStyles, Typography } from "@material-ui/core";
import LocationHeader from "components/Headers/LocationHeader";
import Stepper from "components/Stepper";
import CompanyInfoTab from "./Steps/CompanyInfo";
import WorkCentersInfoTab from "./Steps/WorkCentersInfo";
import EmployeesInfoTab from "./Steps/EmployeesInfo";
import ConfirmationTab from "./Steps/Confirmation";
import useStepper from "hooks/useStepper";
import { StepProvider } from "contexts/stepper-context";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import NavigationButtons from "./navigation-buttons";
import { createMapRent } from "crud/rent.crud";
import { DIALOG } from "utils/actionsConsts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCheckRENTSubmission } from "crud/rent.crud";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
}));

const STEPS = {
  COMPANY_INFO: 0,
  WORK_CENTERS_INFO: 1,
  EMPLOYEES_INFO: 2,
  CONFIRMATION: 3,
};

function RENTCreate() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      user: {
        id: "",
        name: "",
        email: "",
        address: "",
        taxIdNumber: "",
        niss: "",
        phoneNumber: "",

        provinceId: "",
        municipalityId: "",
        legalForm: "",

        totalWorkersNumber: "",
        shareCapital: "",
        publicDivisionPercentage: "",
        privateNationalDivisionPercentage: "",
        activityStartYear: null,

        businessPurposeList: [],
      },
    },
  });

  const { mutateAsync } = useMutation(["createMapRent"], createMapRent, {
    onSuccess: (data) => {
      if (data.succeeded) {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: data.succeeded,
              onClose: data.succeeded ? "/admin/rent/list" : null,
              goBack: true,
              message: data.succeeded ? "Mapa RENT criado com sucesso." : "Ocorreu um erro ao criar o mapa RENT.",
            },
          },
        });

        queryClient.invalidateQueries({
          queryKey: ["getFilteredMapRent"],
        });
      } else {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro ao criar o mapa RENT.",
            },
          },
        });
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { steps, activeStep, canGoNext, handleNext, canGoBack, handleBack } = useStepper([
    {
      title: "Informações da Empresa",
      subtitle: "Registo de dados da empresa",
    },
    {
      title: "Dados de Centros",
      subtitle: "Registo de dados dos centros de trabalho",
    },
    {
      title: "Dados de Trabalhadores",
      subtitle: "Registo de dados dos trabalhadores",
    },
    {
      title: "Confirmação",
      subtitle: "Confirmação dos dados",
    },
  ]);

  const onSubmit = async (data) => {
    try {
      if (!canGoNext) {
        await mutateAsync({
          ...data.user,
          activityStartYear: data.user.activityStartYear.format("YYYY"),
          employmentCenterId: data.employmentCenterId,
          address: data?.user?.address ?? ""
        });

        return;
      }

      handleNext();
    } catch (error) {
      console.error(error);
    }
  };

  const nextLabel = useMemo(() => (!canGoNext ? "Submeter Mapa Rent" : "Seguinte"), [canGoNext]);

  return (
    <FormProvider {...form}>
      <Container maxWidth="lg" className={classes.root}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LocationHeader
            section="Criar Mapa RENT"
            subsection="Lista de Mapas RENT"
            subsectionLink="/admin/rent/list"
          />

          <header className={classes.header}>
            <Typography
              style={{
                textTransform: "uppercase",
              }}
              color="primary"
              variant="h1"
            >
              Criar Mapa RENT
            </Typography>
          </header>

          <Stepper steps={steps} activeStep={activeStep} showIndex>
            {(step) => {
              switch (step) {
                case STEPS.COMPANY_INFO:
                  return <CompanyInfoTab />;
                case STEPS.WORK_CENTERS_INFO:
                  return <WorkCentersInfoTab />;
                case STEPS.EMPLOYEES_INFO:
                  return <EmployeesInfoTab />;
                case STEPS.CONFIRMATION:
                  return <ConfirmationTab />;
                default:
                  return null;
              }
            }}
          </Stepper>

          <NavigationButtons nextLabel={nextLabel} canGoBack={canGoBack} handleBack={handleBack} />
        </form>
      </Container>
    </FormProvider>
  );
}

export default function Page() {

    const { data: canSubmitRENT } = useQuery(["getCheckRENTSubmission"], () => getCheckRENTSubmission());
  
    if (canSubmitRENT && !canSubmitRENT.canSubmit) {
      return <Redirect to="/admin/rent/list" />;
    }
  

  return (
    <StepProvider>
      <RENTCreate />
    </StepProvider>
  );
}
