import React from "react";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { DATASET } from "utils/const";
import { useDataset } from "hooks/useDataset";
import { useQuery } from "@tanstack/react-query";
import useTokenHelper from "hooks/useTokenHelper";
import { getMyWorkCenters } from "crud/workCentersEmployer.crud";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { listWorkCenterEmployers } from "crud/workCentersEmployer.crud";
import FormDatePickerV2 from "components/FormFields/FormDatePickerV2";
import { listWorkCenterEmployersRENT } from "crud/workCentersEmployer.crud";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const FilterSection = ({ FromRENT = false }) => {
  const classes = useStylesGeneric();

  const tokenHelper = useTokenHelper();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    trigger,
    reset,
    setValue,
  } = useFormContext();

  const { data: professionalCategory } = useDataset({ name: DATASET.EMPLOYMENT_PROFESSIONAL_CATEGORY });
  const { data: employmentStatus } = useDataset({ name: DATASET.EMPLOYMENT_STATUS });

  const { data: workCenters } = useQuery(["my-workcenters"], () => getMyWorkCenters(), {
    enabled: !!tokenHelper?.getUserId(),
    select: (data) => {
      if (!data) return [];

      return data.map((item) => ({
        code: item.id,
        label: item.value,
      }));
    },
  });

  const fetchWorkCenterEmployees = async () => {
    const queryParams = {
      ...getValues(),
      startDate: getValues("startDate") ? getValues("startDate").format() : null,
      endDate: getValues("endDate") ? getValues("endDate").format() : null,
    };
    
    return FromRENT ? listWorkCenterEmployersRENT(queryParams) : listWorkCenterEmployers(queryParams);
  };

  const { refetch, isLoading } = useQuery({
    queryKey: ["listWorkCenterEmployers"],
    queryFn: fetchWorkCenterEmployees,
    staleTime: 0,
    refetchOnMount: true,
  });
  
  const onSubmit = async () => {
    setValue("pageIndex", 1);
    await refetch();
  };
  

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <Grid container>
            <Box>Pesquisa de Trabalhadores</Box>
          </Grid>
        }
      />

      <CardContent>
        <Box>
          <form id="filter-employees" onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormInputV2
                  name="name"
                  label="Nome do Trabalhador"
                  placeholder="Informe o nome do trabalhador"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormInputV2 name="ssn" label="NISS" placeholder="Informe o NISS" control={control} />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormDatePickerV2
                  name="startDate"
                  label="Data de Registo (Desde)"
                  control={control}
                  maxDateToday={true}
                  minDateToday={false}
                  endDateFieldName="endDate"
                  errors={errors}
                  classes={classes}
                  trigger={trigger}
                  getValues={getValues}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormDatePickerV2
                  name="endDate"
                  label="Data de Registo (Até)"
                  control={control}
                  maxDateToday={true}
                  minDateToday={false}
                  startDateFieldName="startDate"
                  errors={errors}
                  classes={classes}
                  trigger={trigger}
                  getValues={getValues}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="workCenterId"
                  label="Centro de Trabalho"
                  mappingTo="code"
                  control={control}
                  options={workCenters}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="employeeStatusId"
                  label="Situação Profissional"
                  mappingTo="code"
                  control={control}
                  options={employmentStatus}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormDropdown
                  name="professionalCategoryId"
                  label="Categoria Profissional"
                  mappingTo="code"
                  control={control}
                  options={professionalCategory}
                />
              </Grid>
              {/* Botões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  type="button"
                  color="ghost"
                  onClick={() => {
                    reset();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  form="filter-employees"
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  loading={isLoading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FilterSection;
