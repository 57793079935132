import React from "react";

import { Box, Container, makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
  paper: {
    padding: theme.spacing(4),
  },
}));

const Error = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="md" className={classes.root}>
        <Box my={4}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
              Certificado Homologação Contrato{" "}
            </Typography>

            <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
                Não foi possível validar o certificado.
            </Typography>

            <Typography variant="body1" paragraph>
                Caso tenha alguma dúvida ou precise de mais informações, por favor, entre em contacto connosco
                através dos nossos canais oficiais.
            </Typography>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Error;
