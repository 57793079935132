import React, { useEffect, useState } from "react";
import { Box, FormGroup, FormHelperText, FormLabel, Grid, useTheme } from "@material-ui/core";
import { errorMessages } from "resources/resources";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import FormInput from "components/Forms/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import FormSelect from "components/FormFields/FormSelect";
import { getDatasetStateByName } from "utils/datasets";
import { DATASET } from "utils/const";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { makeStyles } from "@material-ui/core";
import { getDatasetDataByCode } from "utils/datasets";
import { useDispatch, useSelector } from "react-redux";
import { DIALOG } from "utils/actionsConsts";
import { inactiveWorkCenter } from "crud/workCenters.crud";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FormMaskedInput from "components/FormFields/FormMaskedInput";
import { INPUT_MASKS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const WorkCenterDialog = ({
  redirect = true,
  openDialog,
  handleCloseDialog,
  selectedWorkCenter,
  isCreating,
  props,
  creatingFromRENT = false,
}) => {
  const theme = useTheme();
  const classes = { ...useStylesGeneric() };
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();

  const datasetsState = useSelector((state) => state.datasetsState);

  useEffect(() => {
    if (!datasetsState[DATASET.PROVINCES] || datasetsState[DATASET.PROVINCES].length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
  }, [datasetsState, props]);

  async function getDefaultValues() {
    if (selectedWorkCenter) {
      const values = await props.getWorkCenterById(selectedWorkCenter);

      const workCenterProvince = getDatasetDataByCode(datasetsState[DATASET.PROVINCES], values?.provinceId);

      const municipalities = await props.getDataSetByName(DATASET.MUNICIPALITIES, workCenterProvince?.keyValue);

      const workCenterMunicipality = getDatasetDataByCode(municipalities, values?.municipalityId);

      return {
        workCenterName: values?.name,
        workCenterAddress: values?.address,
        workCenterProvince,
        workCenterMunicipality,
        workCenterPostalCode: values?.postalCode,
        workCenterPhone: values?.phoneNumber,
        workCenterEmail: values?.email,
        workCenterSecundaryActivity: getDatasetDataByCode(
          datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          values?.secundaryActivityId
        ),
        workCenterTertiaryActivity: getDatasetDataByCode(
          datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          values?.tertiaryActivityId
        ),
        workerCount: values?.workerCount,
        instrumentCount: values?.instrumentCount,
      };
    }

    return {};
  }

  const methods = useForm({
    defaultValues: getDefaultValues,
  });

  const onSubmit = async (data) => {
    const workCenter = {
      name: data?.workCenterName,
      address: data?.workCenterAddress,
      provinceId: data?.workCenterProvince.code,
      municipalityId: data?.workCenterMunicipality.code,
      postalCode: data?.workCenterPostalCode,
      phoneNumber: data?.workCenterPhone,
      email: data?.workCenterEmail,
      secundaryActivityId: data?.workCenterSecundaryActivity.code,
      tertiaryActivityId: data?.workCenterTertiaryActivity.code,
      workerCount: data?.workerCount,
      instrumentCount: data?.instrumentCount,
    };

    if (isEditing) {
      await props.updateWorkCenter(
        {
          id: selectedWorkCenter,
          ...workCenter,
        },
        redirect
      );
      handleCloseDialog();
    } else {
      await props.createWorkCenter(workCenter, () => handleCloseDialog());
      if (creatingFromRENT) {
        handleCloseDialog();
      }
    }
    await queryClient.refetchQueries({ queryKey: ["listWorkCenters"] });
  };

  const closeEditing = () => {
    setIsEditing(false);
    methods.reset();
  };

  const dispatch = useDispatch();

  const handleSuccess = async () => {
    await dispatch({
      type: DIALOG.SHOW,
      payload: {
        showGenericDialog: true,
        genericDialogData: {
          type: DIALOG.ONE_BUTTON_TYPE,
          isSuccess: true,
          buttonLabel: "Ok",
          message: "Centro de Trabalho removido com sucesso.",
        },
      },
    });

    await queryClient.refetchQueries({
      queryKey: ["listWorkCenters"],
      exact: true,
    });

    handleCloseDialog();
  };

  const { mutateAsync: inactiveWorkCenterMutation, isLoading: inactiveWorkCenterLoading } = useMutation({
    mutationFn: async () => {
      if (!selectedWorkCenter) {
        throw new Error("O id do centro de trabalho não está definido.");
      }
      return await inactiveWorkCenter(selectedWorkCenter);
    },
    mutationKey: ["inactiveWorkCenter"],
    onSuccess: handleSuccess,
    onError: (error) => {
      console.error(error);
      let errorMessage = "Ocurreu um erro ao eliminar o centro de trabalho.";
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        errorMessage = Object.values(errors).flat().join(". ");
      }
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            buttonLabel: "Ok",
            message: errorMessage,
          },
        },
      });
    },
  });

  return (
    <FormProvider {...methods}>
      <GenericDialog open={openDialog} onClose={handleCloseDialog} fullWidth={true} maxWidth="xl">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PageTitleHeader
            title={
              isCreating
                ? "Adicionar Centro de Trabalho"
                : isEditing
                ? "Editar Centro de Trabalho"
                : "Centro de Trabalho"
            }
            style={{
              marginLeft: "-22px",
            }}
          />
          {!isCreating && !isEditing && (
            <Box
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <GenericButton
                typeSubmit={false}
                size="medium"
                color="tertiary"
                onClick={() => setIsEditing(!isEditing)}
                style={{
                  marginBottom: "37px",
                }}
              >
                Editar
              </GenericButton>
              <GenericButton
                typeSubmit={false}
                size="medium"
                color="tertiaryRed"
                onClick={() => inactiveWorkCenterMutation()}
                style={{
                  marginBottom: "37px",
                }}
                loading={inactiveWorkCenterLoading}
              >
                Eliminar Centro de Trabalho
              </GenericButton>
            </Box>
          )}
        </Box>

        <form id="workcenter-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormInput
                name="workCenterName"
                label="Nome do Centro"
                placeholder="Insira o nome do centro"
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name="workCenterAddress"
                label="Morada"
                placeholder="Insira a morada do centro"
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workCenterProvince"
                  selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                  loadChildrenDataSet={props.getDataSetByName}
                  childrenDataSet={DATASET.MUNICIPALITIES}
                  childrenSelect="workCenterMunicipality"
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={!isCreating && !isEditing}
                />
                {methods.formState.errors["workCenterProvince"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workCenterProvince"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Município</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workCenterMunicipality"
                  selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                  loadChildrenDataSet={props.getDataSetByName}
                  childrenDataSet={DATASET.DISTRICTS}
                  childrenSelect="workCenterDistrict"
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={(!isCreating && !isEditing) || !methods.getValues("workCenterProvince")}
                  setValue={methods.setValue}
                />
                {methods.formState.errors["workCenterMunicipality"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workCenterMunicipality"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormMaskedInput
                mask={INPUT_MASKS.NUMERIC}
                name="workCenterPostalCode"
                label="Caixa Postal (Opcional)"
                placeholder="Insira a caixa postal do centro"
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isCreating && !isEditing}
                rules={{
                  pattern: {
                    value: /^\d{5}$/,
                    message: "A caixa postal deve ter 5 dígitos",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormMaskedInput
                mask={INPUT_MASKS.NUMERIC}
                name="workCenterPhone"
                label="Telefone"
                placeholder="Insira o telefone do centro"
                numberType
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                  pattern: {
                    value: /^\d{9}$/,
                    message: "O telefone deve ter 9 dígitos",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name="workCenterEmail"
                label="E-mail"
                placeholder="Insira o e-mail do centro"
                type="email"
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Por favor, insira um e-mail válido.' 
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PageTitleHeader title="Actividade" />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Actividade Secundária</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workCenterSecundaryActivity"
                  selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={!isCreating && !isEditing}
                />
                {methods.formState.errors["workCenterSecundaryActivity"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workCenterSecundaryActivity"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Actividade Terciária</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workCenterTertiaryActivity"
                  selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={!isCreating && !isEditing}
                />
                {methods.formState.errors["workCenterTertiaryActivity"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workCenterTertiaryActivity"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormMaskedInput
                mask={INPUT_MASKS.NUMERIC}
                name="workerCount"
                label="Nº de Trabalhadores"
                placeholder="Insira o número de trabalhadores"
                control={methods.control}
                errors={methods.formState.errors}
                numberType
                required={true}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormMaskedInput
                mask={INPUT_MASKS.APLHA_NUMERIC}
                name="instrumentCount"
                label="Instrumento"
                placeholder="Insira o instrumento"
                control={methods.control}
                errors={methods.formState.errors}
                numberType
                required={true}
                disabled={!isCreating && !isEditing}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GenericButton
                typeSubmit={false}
                size="medium"
                color="tertiary"
                onClick={isEditing ? () => closeEditing() : handleCloseDialog}
              >
                {isCreating || isEditing ? "Cancelar" : "Fechar"}
              </GenericButton>
              {(isCreating || isEditing) && (
                <GenericButton
                  loading={methods.formState.isSubmitting}
                  onClick={methods.handleSubmit(onSubmit)}
                  size="medium"
                  color="primary"
                >
                  {isCreating ? "Adicionar Centro" : "Editar Centro de Trabalho"}
                </GenericButton>
              )}
            </Grid>
          </Grid>
        </form>
      </GenericDialog>
    </FormProvider>
  );
};

export default WorkCenterDialog;
