import { React,useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

import CardNotificationsDashboard from "components/Cards/Dashboard/CardNotificationsDashboard";
import FilterSection from "./NotificationsFilterSection";
import { getNotificationsAction } from "redux/actions/notificationsAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Notifications() {
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }}>      
      <Grid item xs={12} md={12}>
      <FilterSection
            page={page}
            handleChangePage={(value) => {
              handleChangePage(undefined, value);
            }}
          />
      </Grid>

      <Grid item xs={12} md={12}>
        <CardNotificationsDashboard 
          page={page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(getNotificationsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
