import React from "react";
import { Controller } from "react-hook-form";
import { Box, FormGroup, FormHelperText, FormLabel, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const FormInputV2 = React.memo(
  ({ name, required, label, control, rules, tooltipLabel, dataTestId, defaultValue, ...rest }) => {
    const classes = useStylesGeneric();
    const theme = useTheme();
    const intl = useIntl();

    const labelClasses = clsx({
      [classes.labelError]: required,
    });

    return (
      <FormGroup style={{ width: "100%" }}>
        <FormLabel className={labelClasses}>
          {label}
          {tooltipLabel && (
            <Tooltip title={tooltipLabel}>
              <HelpIcon className={classes.tooltipInfoIcon} />
            </Tooltip>
          )}
        </FormLabel>

        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            required: {
              value: required,
              message: intl.formatMessage(messages.Message_Generic_MandatoryField),
            },
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState: { error } }) => (
            <>
              <OutlinedInput
                {...rest}
                {...field}
                inputProps={{
                  ...rest.inputProps,
                  "data-testid": dataTestId,
                }}
                fullWidth
                autoComplete="off"
                classes={{
                  notchedOutline: clsx({
                    [classes.borderWarning]: !!error,
                  }),
                }}
              />

              {!!error && (
                <FormHelperText error component={Box} color={theme.palette.warning.main + "!important"}>
                  {error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormGroup>
    );
  }
);

export default FormInputV2;
