import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import ReactDatetime from "react-datetime";
import clsx from "clsx";
import "moment/locale/pt";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
// @material-ui/core components
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DateRange from "@material-ui/icons/DateRange";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons components
import DeleteIcon from "@material-ui/icons/Delete";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FormSelect from "components/FormFields/FormSelect";
import FormInput from "components/FormFields/FormInput";
import FormMonetaryInput from "components/FormFields/FormMonetaryInput";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import DocumentSection from "./DocumentSection";
import { getDatasetStateByName } from "utils/datasets";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import {
  DATASET,
  OPPORTUNITY_TYPE_KEY,
  INTERNSHIP_TYPE_KEY,
  REGEX_PATTERNS,
  REGISTRATION_CONSTS,
  OPPORTUNITY_STATE_KEY,
  FORM_FIELDS_PREFIX,
  UNITS,
  TABLE_STATE_CHIP_COLOR,
} from "utils/const";
import { messages, titles, errorMessages, labels } from "resources/resources";
import {
  getSelectOptionsElementByCode,
  getBoolSelectOptionsElementByCode,
  getMultipleSelectOptionsElementByCode,
} from "utils/formSelect";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import GenericButton from "components/Buttons/GenericButton";

const ExpandMore = styled((props) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityData(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const methods = useFormContext();
  const [internshipType, setInternshipType] = useState(undefined);
  const [validToState, setValidToState] = useState(true);
  const [validFromState, setValidFromState] = useState(true);
  const [addQuestionButtonState, setAddQuestionButtonState] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isEditableDataSection, setIsEditableDataSection] = useState(props.isEditable);
  const [isEditableDocumentsSection, setIsEditableDocumentsSection] = useState(props.isEditable);
  const [startDateState, setStartDateState] = useState(true);
  const [endDateState, setEndDateState] = useState(true);

  useEffect(() => {
    handleGetDataSetByName(DATASET.PROVINCES);
    handleGetDataSetByName(DATASET.CONTRACT_TYPE);
    handleGetDataSetByName(DATASET.INTERNSHIP_TYPE);
    handleGetDataSetByName(DATASET.TIMETABLE_TYPE);
    handleGetDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    handleGetDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES);
    handleGetDataSetByName(DATASET.OPPORTUNITY_DOCUMENT_TYPE);
    handleGetDataSetByName(DATASET.EMPLOYMENT_CENTERS);
    handleGetDataSetByName(DATASET.ACADEMIC_LEVEL);
    handleGetDataSetByName(DATASET.LANGUAGES);
    handleGetDataSetByName(DATASET.GENDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.datasetsState) {
      var provinceList = props.datasetsState[DATASET.PROVINCES] ? props.datasetsState[DATASET.PROVINCES] : [];
      methods.setValue(
        "province",
        getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.datasetsState) {
      var municipalityList = props.datasetsState[DATASET.MUNICIPALITIES]
        ? props.datasetsState[DATASET.MUNICIPALITIES]
        : [];
      methods.setValue(
        "county",
        getSelectOptionsElementByCode(municipalityList, props.opportunityDetailsData?.municipalityId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MUNICIPALITIES]]);

  useEffect(() => {
    if (props.datasetsState) {
      var contractList = props.datasetsState[DATASET.CONTRACT_TYPE] ? props.datasetsState[DATASET.CONTRACT_TYPE] : [];
      methods.setValue(
        "contractType",
        getSelectOptionsElementByCode(contractList, props.opportunityDetailsData?.contractTypeId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.CONTRACT_TYPE]]);

  useEffect(() => {
    if (props.datasetsState) {
      var internshipTypetList = props.datasetsState[DATASET.INTERNSHIP_TYPE]
        ? props.datasetsState[DATASET.INTERNSHIP_TYPE]
        : [];
      methods.setValue(
        "internshipType",
        getSelectOptionsElementByCode(internshipTypetList, props.opportunityDetailsData?.contractTypeId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.INTERNSHIP_TYPE]]);

  useEffect(() => {
    if (props.datasetsState) {
      var timeTableList = props.datasetsState[DATASET.TIMETABLE_TYPE]
        ? props.datasetsState[DATASET.TIMETABLE_TYPE]
        : [];
      methods.setValue(
        "timetableType",
        getSelectOptionsElementByCode(timeTableList, props.opportunityDetailsData?.timetableTypeId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.TIMETABLE_TYPE]]);

  useEffect(() => {
    if (props.datasetsState) {
      var professionalCategoryList = props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
        ? props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
        : [];
      if (props.opportunityDetailsData?.opportunityProfessionalCategories)
        methods.setValue(
          "opportunityProfessionalCategories",
          getSelectOptionsElementByCode(
            professionalCategoryList,
            props.opportunityDetailsData?.opportunityProfessionalCategories[0]
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    if (props.datasetsState) {
      var employmentCentersList = props.datasetsState[DATASET.EMPLOYMENT_CENTERS]
        ? props.datasetsState[DATASET.EMPLOYMENT_CENTERS]
        : [];

      methods.setValue(
        "employmentCenter",
        getSelectOptionsElementByCode(employmentCentersList, props.opportunityDetailsData?.employmentCenterId)
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.datasetsState[DATASET.EMPLOYMENT_CENTERS]]);

  useEffect(() => {
    if (props.datasetsState) {
      var academicLevelList = props.datasetsState[DATASET.ACADEMIC_LEVEL]
        ? props.datasetsState[DATASET.ACADEMIC_LEVEL]
        : [];

      methods.setValue(
        "academicLevel",
        getSelectOptionsElementByCode(academicLevelList, props.opportunityDetailsData?.academicLevelId)
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.datasetsState[DATASET.ACADEMIC_LEVEL]]);

  useEffect(() => {
    if (props.datasetsState) {
      var languagesList = props.datasetsState[DATASET.LANGUAGES] ? props.datasetsState[DATASET.LANGUAGES] : [];

      methods.setValue(
        "requiredLanguages",
        getMultipleSelectOptionsElementByCode(languagesList, props.opportunityDetailsData?.requiredLanguagesIds)
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.datasetsState[DATASET.LANGUAGES]]);

  useEffect(() => {
    if (props.datasetsState) {
      var gendersList = props.datasetsState[DATASET.GENDER] ? props.datasetsState[DATASET.GENDER] : [];

      methods.setValue(
        "gender",
        getSelectOptionsElementByCode(gendersList, props.opportunityDetailsData?.genderId)
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.datasetsState[DATASET.GENDER]]);

  useEffect(() => {
    if (props.opportunityDetailsData) {
      setInternshipType(undefined);
      if (props.opportunityDetailsData.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL) {
        setInternshipType(props.opportunityDetailsData.internshipTypeKeyValue);
      }
      methods.setValue("description", props.opportunityDetailsData.description);
      methods.setValue("amount", props.opportunityDetailsData.amount);
      methods.setValue("reference", props.opportunityDetailsData.reference);
      methods.setValue("numVacancies", props.opportunityDetailsData.numVacancies);
      methods.setValue(
        "validFrom",
        props.opportunityDetailsData.validFrom ? moment(props.opportunityDetailsData.validFrom) : null
      );
      methods.setValue(
        "validTo",
        props.opportunityDetailsData.validTo ? moment(props.opportunityDetailsData.validTo) : null
      );
      methods.setValue("mentorName", props.opportunityDetailsData.mentorName);
      methods.setValue("mentorMobilePhone", props.opportunityDetailsData.mentorMobilePhone);
      methods.setValue("reportingRequired", props.opportunityDetailsData.reportingRequired);
      methods.setValue("reportingPeriod", props.opportunityDetailsData.reportingPeriod ?? "");
      methods.setValue(
        "startDate",
        props.opportunityDetailsData.startDate ? moment(props.opportunityDetailsData.startDate) : null
      );
      methods.setValue(
        "endDate",
        props.opportunityDetailsData.endDate ? moment(props.opportunityDetailsData.endDate) : null
      );
      methods.setValue("socialPerks", props.opportunityDetailsData.socialPerks ?? "");
      methods.setValue("otherPerks", props.opportunityDetailsData.otherPerks ?? "");
      methods.setValue("workplace", props.opportunityDetailsData.workplace ?? "");
      methods.setValue("commune", props.opportunityDetailsData.commune ?? "");
      methods.setValue(
        "activeMonths",
        props.opportunityDetailsData.activeMonths && props.opportunityDetailsData.activeMonths >= 0
          ? props.opportunityDetailsData.activeMonths
          : 0
      );
      methods.setValue("opportunity_observations", props.opportunityDetailsData.observations ?? "");
      methods.setValue("requiredKnowledge", props.opportunityDetailsData.requiredKnowledge ?? "");
      methods.setValue("minAge", props.opportunityDetailsData.minAge ?? "");
      methods.setValue("maxAge", props.opportunityDetailsData.maxAge ?? "");

      if (
        props.opportunityDetailsData.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA ||
        props.opportunityDetailsData.opportunityStateKey === OPPORTUNITY_STATE_KEY.FECHADA ||
        props.opportunityDetailsData.opportunityStateKey === OPPORTUNITY_STATE_KEY.SUBMETIDA
      ) {
        methods.setValue("stateMotive", props.opportunityDetailsData.opportunityStateMotive?.stateMotiveDescription);
        methods.setValue("observations", props.opportunityDetailsData.opportunityStateMotive?.stateMotiveObservations);
      }

      if (props.opportunityDetailsData.opportunityQuestions) {
        props.setOpportunityQuestionsState((prevState) => {
          prevState = props.opportunityDetailsData.opportunityQuestions.map((q) => {
            methods.setValue(q.id + "", q.question);
            return { name: q.id + "", question: q.question };
          });
          return [...prevState];
        });
      }

      if (props.datasetsState) {
        var provinceList = props.datasetsState[DATASET.PROVINCES] ? props.datasetsState[DATASET.PROVINCES] : [];
        methods.setValue(
          "province",
          getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId)
        );

        if (props.getDataSet !== undefined) {
          props.getDataSet(DATASET.MUNICIPALITIES, props.opportunityDetailsData?.provinceKey, true);
        }

        var municipalityList = props.datasetsState[DATASET.MUNICIPALITIES]
          ? props.datasetsState[DATASET.MUNICIPALITIES]
          : [];

        methods.setValue(
          "county",
          getSelectOptionsElementByCode(municipalityList, props.opportunityDetailsData?.municipalityId)
        );

        var contractList = props.datasetsState[DATASET.CONTRACT_TYPE] ? props.datasetsState[DATASET.CONTRACT_TYPE] : [];
        methods.setValue(
          "contractType",
          getSelectOptionsElementByCode(contractList, props.opportunityDetailsData?.contractTypeId)
        );

        var internshipTypetList = props.datasetsState[DATASET.INTERNSHIP_TYPE]
          ? props.datasetsState[DATASET.INTERNSHIP_TYPE]
          : [];
        methods.setValue(
          "internshipType",
          getSelectOptionsElementByCode(internshipTypetList, props.opportunityDetailsData?.internshipTypeId)
        );

        var timeTableList = props.datasetsState[DATASET.TIMETABLE_TYPE]
          ? props.datasetsState[DATASET.TIMETABLE_TYPE]
          : [];
        methods.setValue(
          "timetableType",
          getSelectOptionsElementByCode(timeTableList, props.opportunityDetailsData?.timetableTypeId)
        );

        var professionalCategoryList = props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
          ? props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
          : [];

        if (props.opportunityDetailsData?.opportunityProfessionalCategories)
          methods.setValue(
            "opportunityProfessionalCategories",
            getSelectOptionsElementByCode(
              professionalCategoryList,
              props.opportunityDetailsData?.opportunityProfessionalCategories[0]
            )
          );

        if (props.getDataSet !== undefined) {
          props.getDataSet(DATASET.EMPLOYMENT_CENTERS, props.opportunityDetailsData?.provinceKey, true);
        }

        var employmentCentersList = props.datasetsState[DATASET.EMPLOYMENT_CENTERS]
          ? props.datasetsState[DATASET.EMPLOYMENT_CENTERS]
          : [];

        methods.setValue(
          "employmentCenter",
          getSelectOptionsElementByCode(employmentCentersList, props.opportunityDetailsData?.employmentCenterId)
        );

        var academicLevelList = props.datasetsState[DATASET.ACADEMIC_LEVEL]
          ? props.datasetsState[DATASET.ACADEMIC_LEVEL]
          : [];
        methods.setValue(
          "academicLevel",
          getSelectOptionsElementByCode(academicLevelList, props.opportunityDetailsData?.academicLevelId)
        );

        methods.setValue(
          "acceptWorkersFromOtherRegions",
          getBoolSelectOptionsElementByCode(
            DATASET.YES_OR_NO_DROPDOWN,
            props.opportunityDetailsData?.acceptWorkersFromOtherRegions
          )
        );

        var languagesList = props.datasetsState[DATASET.LANGUAGES] ? props.datasetsState[DATASET.LANGUAGES] : [];

        methods.setValue(
          "requiredLanguages",
          getMultipleSelectOptionsElementByCode(languagesList, props.opportunityDetailsData?.requiredLanguagesIds)
        );

        var genderList = props.datasetsState[DATASET.GENDER]
          ? props.datasetsState[DATASET.GENDER]
          : [];

        methods.setValue(
          "gender",
          getSelectOptionsElementByCode(genderList, props.opportunityDetailsData?.genderId)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunityDetailsData]);

  useEffect(() => {
    if (!props.isEditable) {
      setIsEditableDataSection(false);
      setIsEditableDocumentsSection(false);
    }
  }, [props.isEditable]);

  const handleGetDataSetByName = (datasetName) => {
    if (props.datasetsState[datasetName] === undefined || props.datasetsState[datasetName]?.length === 0) {
      props.getDataSetByName(datasetName);
    }
  };

  const handleEditDataSection = () => {
    const isEditableAux = !isEditableDataSection;
    setIsEditableDataSection(isEditableAux);
    if (isEditableAux || isEditableDocumentsSection) {
      props.handleEditSection();
    }
  };

  const handleEditDocumentsSection = () => {
    const isEditableAux = !isEditableDocumentsSection;
    setIsEditableDocumentsSection(isEditableAux);
    if (isEditableAux || isEditableDataSection) {
      props.handleEditSection();
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const controlOpportunityQuestions = () => {
    const numQuestions = props.opportunityQuestionsState?.length;
    if (numQuestions <= 3)
      props.setOpportunityQuestionsState((prevState) => {
        prevState.push({
          name: "question_" + uuidv4(),
        });
        return [...prevState];
      });
    if (numQuestions === 2) setAddQuestionButtonState(true);
  };

  const removeQuestion = (name) => {
    const numQuestions = props.opportunityQuestionsState?.length;

    methods.unregister(name);
    props.setOpportunityQuestionsState((prevState) => {
      let elements = prevState.filter((question) => question.name !== name);
      return elements;
    });
    if (numQuestions === 3) setAddQuestionButtonState(false);
  };

  const controlInternshipType = (selectedType) => {
    if (selectedType) setInternshipType(selectedType.keyValue);
    else setInternshipType(undefined);

    unregisterAndClearInternshipDependentFields();
  };

  const unregisterAndClearInternshipDependentFields = () => {
    setValidFromState(false);
    setValidToState(false);
    setStartDateState(false);
    setEndDateState(false);
    methods.setValue("validFrom", undefined);
    methods.setValue("validTo", undefined);
    methods.setValue("mentorName", "");
    methods.setValue("mentorMobilePhone", "");
    methods.setValue("startDate", undefined);
    methods.setValue("endDate", undefined);
    methods.unregister(["validFrom", "validTo", "mentorName", "mentorMobilePhone", "startDate", "endDate"]);

    // Unregister docs in a dynamic manner
    props.datasetsState[DATASET.OPPORTUNITY_DOCUMENT_TYPE]?.map((documentType) =>
      methods.unregister(FORM_FIELDS_PREFIX.DOCUMENT + documentType.code.toString())
    );
  };

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  const handleValidSecondDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  const showDateFields = () => {
    return (
      props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO ||
      internshipType === INTERNSHIP_TYPE_KEY.GERAL
    );
  };

  const showQuestionsFields = () => {
    return props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO;
  };

  const showPAPEInternshipFields = () => {
    return (
      props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
      internshipType === INTERNSHIP_TYPE_KEY.PAPE
    );
  };

  const getCardTitle = () => {
    switch (props.opportunityDetailsData?.opportunityTypeKey) {
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return intl.formatMessage(titles.Title_Opportunity_Employment_Data_Card);
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
        return intl.formatMessage(titles.Title_Opportunity_Internship_Data_Card);
      default:
        return "";
    }
  };

  const getCardStateMotiveTitle = () => {
    switch (props.opportunityDetailsData?.opportunityStateKey) {
      case OPPORTUNITY_STATE_KEY.REJEITADA:
        return intl.formatMessage(titles.Title_Opportunity_Employment_RejectMotive_Card);
      case OPPORTUNITY_STATE_KEY.SUBMETIDA:
        return intl.formatMessage(titles.Title_Opportunity_Employment_RejectMotive_Card);
      case OPPORTUNITY_STATE_KEY.FECHADA:
        return intl.formatMessage(titles.Title_Opportunity_Employment_CloseMotive_Card);
      default:
        return "";
    }
  };

  const getAmountTitle = () => {
    switch (props.opportunityDetailsData?.opportunityTypeKey) {
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return "Salário";
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
        return "Bolsa de Estágio";
      default:
        return "";
    }
  };

  const getContractInternshipTypeLabel = () => {
    switch (props.opportunityDetailsData?.opportunityTypeKey) {
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return (
          <>
            <FormLabel>Tipo de Contrato</FormLabel>
            <FormSelect
              control={methods.control}
              disabled={!isEditableDataSection}
              fieldName="contractType"
              selectOptions={getDatasetStateByName(props, DATASET.CONTRACT_TYPE)}
              getValues={methods.getValues}
              setValue={methods.setValue}
              required={true}
              requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
              errors={methods.formState.errors}
              classes={classes}
            />
            {methods.formState.errors["contractType"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {methods.formState.errors["contractType"].message}
              </FormHelperText>
            )}
          </>
        );
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
        return (
          <>
            <FormLabel>Tipo de Estágio</FormLabel>
            <FormSelect
              control={methods.control}
              disabled={!isEditableDataSection}
              fieldName="internshipType"
              selectOptions={getDatasetStateByName(props, DATASET.INTERNSHIP_TYPE)}
              getValues={methods.getValues}
              setValue={methods.setValue}
              onOptionChange={controlInternshipType}
              required={true}
              requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
              errors={methods.formState.errors}
              classes={classes}
            />
            {methods.formState.errors["internshipType"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {methods.formState.errors["internshipType"].message}
              </FormHelperText>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const showStateMotiveArea = () => {
    return (
      props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA ||
      props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.FECHADA
    );
  };

  const mapOpportunityStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_STATE_KEY.SUBMETIDA:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_STATE_KEY.FECHADA:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={getCardTitle()}
          action={
            <Grid container>
              {!props.isCreationPage && (
                <Grid item style={{ marginTop: "-1px" }}>
                  <TableStateChip
                    state={mapOpportunityStateToTableChipColors(props.opportunityDetailsData?.opportunityStateKey)}
                    message={props.opportunityDetailsData?.opportunityStateDescription}
                  />
                </Grid>
              )}
              {props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.APROVADA &&
                props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA &&
                !props.isCreationPage && (
                  <Grid item>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      onClick={() => {
                        handleEditDataSection();
                      }}
                    >
                      Editar
                    </GenericButton>
                  </Grid>
                )}
            </Grid>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormInput
                name="description"
                label="Descrição"
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                errors={methods.formState.errors}
                placeholder="Escreva aqui uma descrição da oferta"
                multiline={true}
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="province"
                  disabled={!isEditableDataSection}
                  selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  loadChildrenDataSet={props.getDataSet}
                  childrenDataSet={DATASET.MUNICIPALITIES}
                  childrenSelect="county"
                  childrenList={[{ name: "employmentCenter", dataset: DATASET.EMPLOYMENT_CENTERS }]}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["province"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["province"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>Município</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="county"
                  selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  disabled={!isEditableDataSection || !methods.getValues("province")}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["county"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["county"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Opportunity_OpportunityData_EmploymentCenter)}</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="employmentCenter"
                  disabled={!isEditableDataSection || !methods.getValues("province")}
                  selectOptions={getDatasetStateByName(props, DATASET.EMPLOYMENT_CENTERS)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["employmentCenter"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["employmentCenter"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="numVacancies"
                label="Nº Vagas"
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                errors={methods.formState.errors}
                numberType={true}
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value <= 0)
                        return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueGreaterThanZeroField);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>Horário</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="timetableType"
                  disabled={!isEditableDataSection}
                  selectOptions={getDatasetStateByName(props, DATASET.TIMETABLE_TYPE)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["timetableType"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["timetableType"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormMonetaryInput
                name="amount"
                label={getAmountTitle()}
                control={methods.control}
                disabled={!isEditableDataSection}
                errors={methods.formState.errors}
                defaultValue={props.opportunityDetailsData?.amount}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                  },
                  pattern: {
                    value: REGEX_PATTERNS.MONETARY,
                    message: intl.formatMessage(messages.Message_Generic_InvalidField),
                  },
                  validate: {
                    minValue: (value) => {
                      if (value === "0" || value === "0,00" || value === "0,0")
                        return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueGreaterThanZeroField);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="socialPerks"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_SocialPerks)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                tooltipLabel={intl.formatMessage(labels.Label_Opportunity_OpportunityData_SocialPerks_Examples)}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="otherPerks"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_OtherPerks)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="workplace"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_Workplace)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="commune"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_Commune)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>Área</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="opportunityProfessionalCategories"
                  disabled={!isEditableDataSection}
                  selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["opportunityProfessionalCategories"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["opportunityProfessionalCategories"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Opportunity_OpportunityData_AcceptWorkersFromOtherRegions)}
                </FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="acceptWorkersFromOtherRegions"
                  disabled={!isEditableDataSection}
                  selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["acceptWorkersFromOtherRegions"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["acceptWorkersFromOtherRegions"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>{getContractInternshipTypeLabel()}</FormGroup>
            </Grid>

            {showDateFields() && (
              <>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormLabel>Data da Publicação da Oferta (opcional)</FormLabel>
                    <Controller
                      name="validFrom"
                      control={methods.control}
                      rules={{
                        validate: {
                          minDate: (value) => {
                            return (
                              !value ||
                              value >= moment().startOf("day") ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateGreaterThanTodayField)
                            );
                          },
                          isLessThanEndDate: (value) => {
                            if (!methods.getValues("validTo") || value === "") return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("validTo") &&
                                methods.getValues("validTo") instanceof moment &&
                                value.startOf("day") < methods.getValues("validTo")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateFromLessThanDateToField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setValidFromState(true);
                            onChange(e);
                            methods.trigger("validTo");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          isValidDate={(currentDate, _) => {
                            return currentDate >= moment().startOf("day");
                          }}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              value={validFromState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              disabled={dateInputProps.disabled}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setValidFromState(true);
                                handleValidDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["validFrom"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["validFrom"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["validFrom"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormLabel>Data do Término da Oferta (opcional)</FormLabel>
                    <Controller
                      name="validTo"
                      control={methods.control}
                      rules={{
                        validate: {
                          maxDate: (value) => {
                            if (!value) return true;
                            return (
                              value >= moment().startOf("day") ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateGreaterThanTodayField)
                            );
                          },
                          isGreaterThanStartDate: (value) => {
                            if (!methods.getValues("validFrom") || value === "") return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("validFrom") &&
                                methods.getValues("validFrom") instanceof moment &&
                                value.startOf("day") > methods.getValues("validFrom")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateToGreaterThanDateFromField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setValidToState(true);
                            onChange(e);
                            methods.trigger("validFrom");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          isValidDate={(currentDate, _) => {
                            return currentDate >= moment().startOf("day");
                          }}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              disabled={!isEditableDataSection || dateInputProps.disabled}
                              value={validToState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setValidToState(true);
                                handleValidSecondDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["validTo"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["validTo"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["validTo"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
            {showPAPEInternshipFields() && (
              <>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormInput
                    name="mentorName"
                    label="Tutor/Orientador do Estágio"
                    disabled={!isEditableDataSection}
                    isRequired
                    control={methods.control}
                    errors={methods.formState.errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormInput
                    name="mentorMobilePhone"
                    label="Telemóvel"
                    control={methods.control}
                    disabled={!isEditableDataSection}
                    isRequired
                    errors={methods.formState.errors}
                    numberType={true}
                    rules={{
                      validate: {
                        validPhoneNumber: (value) => {
                          if (
                            value &&
                            (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                              parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                          )
                            return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
                {!props.isCreationPage && (
                  <>
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <FormGroup>
                        <Controller
                          name="reportingRequired"
                          control={methods.control}
                          component={Box}
                          defaultValue={false}
                          render={({ field: { name, value } }) => (
                            <FormControlLabel
                              disabled={true}
                              name={name}
                              value="end"
                              control={<Checkbox color="primary" checked={value} />}
                              label={
                                <Box fontSize={14} component="div">
                                  Requer submissão de relatório
                                </Box>
                              }
                              labelPlacement="end"
                              classes={{
                                root: classes.formControlCheckboxLabelRoot,
                                label: classes.formControlCheckboxLabelLabel,
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <FormGroup>
                        <FormLabel>
                          Período de submissão
                          <Tooltip title="Período em meses">
                            <ErrorIcon className={classes.tooltipInfoIcon} />
                          </Tooltip>
                        </FormLabel>
                        <Controller
                          name="reportingPeriod"
                          control={methods.control}
                          component={Box}
                          defaultValue=""
                          render={({ field: { name, value } }) => (
                            <OutlinedInput
                              name={name}
                              value={value}
                              fullWidth
                              disabled={true}
                              autoComplete="off"
                              type="number"
                              placeholder={"00"}
                              endAdornment={
                                <InputAdornment disableTypography={true} position="end">
                                  {UNITS.MONTHS}
                                </InputAdornment>
                              }
                              classes={{
                                input: classes.monetaryFields,
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormLabel>Data Prevista de Estágio Início</FormLabel>
                    <Controller
                      name="startDate"
                      control={methods.control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        validate: {
                          minDate: (value) => {
                            return (
                              !value ||
                              value >= moment().startOf("day") ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateGreaterThanTodayField)
                            );
                          },
                          isLessThanEndDate: (value) => {
                            if (!methods.getValues("endDate")) return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("endDate") &&
                                methods.getValues("endDate") instanceof moment &&
                                value.startOf("day") < methods.getValues("endDate")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateFromLessThanDateToField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setStartDateState(true);
                            onChange(e);
                            methods.trigger("endDate");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          isValidDate={(currentDate, _) => {
                            return currentDate >= moment().startOf("day");
                          }}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              value={startDateState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              disabled={dateInputProps.disabled}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setStartDateState(true);
                                handleValidDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["startDate"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["startDate"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["startDate"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormLabel>Data Prevista de Estágio Fim</FormLabel>
                    <Controller
                      name="endDate"
                      control={methods.control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        validate: {
                          maxDate: (value) => {
                            if (!value) return true;
                            return (
                              value >= moment().startOf("day") ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateGreaterThanTodayField)
                            );
                          },
                          isGreaterThanStartDate: (value) => {
                            if (!methods.getValues("startDate")) return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("startDate") &&
                                methods.getValues("startDate") instanceof moment &&
                                value.startOf("day") > methods.getValues("startDate")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateToGreaterThanDateFromField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setEndDateState(true);
                            onChange(e);
                            methods.trigger("startDate");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          isValidDate={(currentDate, _) => {
                            return currentDate >= moment().startOf("day");
                          }}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              disabled={!isEditableDataSection || dateInputProps.disabled}
                              value={endDateState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setEndDateState(true);
                                handleValidSecondDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["endDate"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["endDate"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["endDate"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FormInput
                name="opportunity_observations"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_Observations)}
                control={methods.control}
                disabled={!isEditableDataSection}
                multiline={true}
                rows={3}
                errors={methods.formState.errors}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={intl.formatMessage(labels.Label_Opportunity_OpportunityData_CandidateData)}
          action={
            <Grid container>
              {props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.APROVADA &&
                props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA &&
                !props.isCreationPage && (
                  <Grid item>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      onClick={() => {
                        handleEditDataSection();
                      }}
                    >
                      Editar
                    </GenericButton>
                  </Grid>
                )}
            </Grid>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Opportunity_OpportunityData_RequiredAcademicLevel)}
                </FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="academicLevel"
                  disabled={!isEditableDataSection}
                  selectOptions={getDatasetStateByName(props, DATASET.ACADEMIC_LEVEL)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["academicLevel"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["academicLevel"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormGroup>
                <FormLabel className={classes.boxNameCategory}>
                  {intl.formatMessage(labels.Label_Opportunity_OpportunityData_RequiredLanguages)}
                </FormLabel>
                <FormMultipleSelect
                  control={methods.control}
                  fieldName="requiredLanguages"
                  selectOptions={getDatasetStateByName(props, DATASET.LANGUAGES)}
                  initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                  disabled={!isEditableDataSection}
                  getValues={methods.getValues}
                  limitTags={1}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={methods.formState.errors}
                  classes={classes}
                />

                {methods.formState.errors["requiredLanguages"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["requiredLanguages"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="requiredKnowledge"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_RequiredKnowledge)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                tooltipLabel={intl.formatMessage(labels.Label_Opportunity_OpportunityData_RequiredKnowledge_Examples)}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <FormInput
                name="activeMonths"
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_ActiveMonths)}
                control={methods.control}
                disabled={!isEditableDataSection}
                isRequired
                numberType={true}
                errors={methods.formState.errors}
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value < 0)
                        return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Opportunity_OpportunityData_Gender)}</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="gender"
                  disabled={!isEditableDataSection}
                  selectOptions={getDatasetStateByName(props, DATASET.GENDER)}
                  getValues={methods.getValues}
                  setValue={methods.setValue}
                  required={false}
                  errors={methods.formState.errors}
                  classes={classes}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                name="minAge"
                disabled={!isEditableDataSection}
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_MinAge)}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                trigger={methods.trigger}
                getValues={methods.getValues}
                otherFieldName="maxAge"
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value <= 17 && value !== "") return intl.formatMessage(errorMessages.ErrorMessage_MinimumAge);
                      return true;
                    },
                    isLessThanMaxAge: (value) => {
                      return (
                        !methods.getValues("maxAge") ||
                        !value ||
                        (value !== "" && value <= methods.getValues("maxAge")) ||
                        intl.formatMessage(errorMessages.ErrorMessage_MinAgeShouldBeLowerThanMaxAge)
                      );
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                name="maxAge"
                disabled={!isEditableDataSection}
                label={intl.formatMessage(labels.Label_Opportunity_OpportunityData_MaxAge)}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                trigger={methods.trigger}
                getValues={methods.getValues}
                otherFieldName="minAge"
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value <= 17 && value !== "") return intl.formatMessage(errorMessages.ErrorMessage_MinimumAge);
                      return true;
                    },
                    isGreaterThanMinDate: (value) => {
                      return (
                        !methods.getValues("minAge") ||
                        !value ||
                        (value !== "" && value >= methods.getValues("minAge")) ||
                        intl.formatMessage(errorMessages.ErrorMessage_MaxAgeShouldBeGreaterThanMinAge)
                      );
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        {showQuestionsFields() && (
          <>
            {" "}
            <CardHeader
              classes={{
                action: classes.cardHeaderAction,
                title: classes.cardHeader,
              }}
              title={
                <Box component="span">
                  Questões para o candidato
                  <Box component="span" marginLeft="5px" fontWeight="100">
                    max.3
                  </Box>
                </Box>
              }
              action={
                <Grid container>
                  <Grid item>
                    {isEditableDataSection && props.opportunityQuestionsState?.length < 3 && (
                      <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="small"
                        disabled={addQuestionButtonState}
                        onClick={() => {
                          controlOpportunityQuestions();
                        }}
                      >
                        Adicionar
                      </GenericButton>
                    )}
                  </Grid>
                </Grid>
              }
            />
            {props.opportunityQuestionsState?.length > 0 && (
              <CardContent>
                {props.opportunityQuestionsState.map((q) => {
                  return (
                    <Grid container key={q.name}>
                      <Grid item xs={11} md={11}>
                        <FormInput
                          name={q.name}
                          label="Questão"
                          disabled={!isEditableDataSection}
                          isRequired
                          control={methods.control}
                          errors={methods.formState.errors}
                          multiline={true}
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        {isEditableDataSection && (
                          <Box marginTop="30px">
                            <IconButton
                              aria-label="delete"
                              style={{ marginLeft: "-12px" }}
                              onClick={() => {
                                removeQuestion(q.name);
                              }}
                            >
                              <DeleteIcon style={{ width: "19px", height: "19px" }} />
                            </IconButton>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </CardContent>
            )}
          </>
        )}
      </Card>

      {showPAPEInternshipFields() && (
        <DocumentSection
          isCreationPage={props.isCreationPage}
          isEditable={isEditableDocumentsSection}
          showEditButton={
            props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.SUBMETIDA ||
            props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA
          }
          handleEditSection={handleEditDocumentsSection}
          submetedDocuments={props.opportunityDetailsData?.opportunityDocuments}
        />
      )}

      {showStateMotiveArea() && (
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{
              action: classes.cardHeaderAction,
              title: classes.cardHeader,
            }}
            title={getCardStateMotiveTitle()}
            action={
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormInput
                    name="stateMotive"
                    label="Motivo"
                    disabled={true}
                    control={methods.control}
                    errors={methods.formState.errors}
                  />
                </Grid>
                {props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.APROVADA &&
                  props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA && (
                    <Grid item xs={12} md={12}>
                      <FormInput
                        name="observations"
                        label="Comentários"
                        disabled={true}
                        multiline={true}
                        rows={4}
                        control={methods.control}
                        errors={methods.formState.errors}
                      />
                    </Grid>
                  )}
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityData);
