import React from "react";
import GenericButton from "components/Buttons/GenericButton";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  getContractApprovalCertificateFromToken,
  getContractApprovalCertificateInfoFromToken,
  getContractApprovalContractFromToken,
} from "crud/contractApprovalWorkCenterEmployee.crud";
import useDownloadDocument from "hooks/useDownloadDocument";

import { Box, CircularProgress, Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ConditionGuard } from "guards/ConditionGuard";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "calc(110px + 2rem)",
    paddingBottom: "2rem",
  },
  paper: {
    padding: theme.spacing(4),
  },
}));

const ContractApprovalCertificate = () => {
  const classes = useStyles();

  const { id } = useParams();

  const { data, isLoading, error } = useQuery(
    ["contractApproval", "certificate", id],
    () => getContractApprovalCertificateInfoFromToken(id),
    {
      enabled: !!id,
    }
  );

  const [{ state: stateCertificate }, handleDownloadCertificate] = useDownloadDocument(
    getContractApprovalCertificateFromToken
  );
  const [{ state: stateContract }, handleDownloadContract] = useDownloadDocument(getContractApprovalContractFromToken);

  return (
    <>
      <Container maxWidth="md" className={classes.root}>
        <Box my={4}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
              Certificado Homologação Contrato{" "}
            </Typography>

            <ConditionGuard
              condition={!isLoading}
              fallback={
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              }
            >
              <ConditionGuard
                condition={!error}
                fallback={
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Alert severity="error">
                      Você não tem permissões para aceder ao certificado, ou este é inválido. Por favor, contacte o
                      administrador.
                    </Alert>
                  </Box>
                }
              >
                <ConditionGuard
                  condition={!!data}
                  fallback={
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                      <Alert severity="info">Nenhum dado disponível para este certificado.</Alert>
                    </Box>
                  }
                >
                  <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
                    O Instituto Nacional de Emprego e Formação Profissional (INEFOP), instituição oficial responsável
                    pela emissão do Certificado de Homologação de Contrato, certifica, por meio deste portal, a
                    autenticidade deste documento, emitido para o trabalhador <b>{data?.data?.employeeName}</b>, da
                    empresa <b>{data?.data?.workCenterName}</b> com um valor de salário expresso no contrato de{" "}
                    <b>{data?.data?.salary}</b>
                    Kwz.
                  </Typography>

                  <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
                    Este certificado foi emitido em conformidade com os procedimentos e critérios estabelecidos pela
                    nossa plataforma, garantindo que todas as informações nele contidas são verídicas e verificáveis. A
                    autenticidade do certificado pode ser confirmada exclusivamente através deste portal oficial, sendo
                    válido somente quando acompanhado deste código QR e validado diretamente na nossa plataforma.
                  </Typography>

                  <Box my={4} textAlign="center">
                    <Typography variant="h6" gutterBottom>
                      Para efetuar o download do seu certificado e do contrato, clique no botão abaixo:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "1rem",
                        textAlign: "center",
                      }}
                    >
                      <GenericButton
                        color="primary"
                        size="large"
                        onClick={() => handleDownloadCertificate(id)}
                        loading={stateCertificate.get(id)}
                      >
                        Download do Certificado
                      </GenericButton>
                      <GenericButton
                        color="primary"
                        size="large"
                        onClick={() => handleDownloadContract(id)}
                        loading={stateContract.get(id)}
                      >
                        Download do Contrato
                      </GenericButton>
                    </Box>
                  </Box>
                  <Typography variant="body1" paragraph>
                    Caso tenha alguma dúvida ou precise de mais informações, por favor, entre em contacto connosco
                    através dos nossos canais oficiais.
                  </Typography>
                </ConditionGuard>
              </ConditionGuard>
            </ConditionGuard>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default ContractApprovalCertificate;
