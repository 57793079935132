import React, { useEffect } from "react";
import { connect } from "react-redux";
import { YES_OR_NO_DROPDOWN } from "../../../../../constants/componentsDatasets";
import { Controller } from "react-hook-form";
import { getSubprogramComplementaryDataAction } from "redux/actions/applicationsAction";
import { getDataSetByIndexAction, getDataSetByNameAction } from "redux/actions/datasetsAction";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import TextField from "components/ComplementaryDataFields/Text";
import DropdownField from "components/ComplementaryDataFields/Dropdown";
import GenericButton from "components/Buttons/GenericButton";
import { COMPLEMENTARY_DATA_ANSWER_TYPE } from "utils/const";
import { getDatasetStateByName } from "utils/datasets";

function ComplementaryData(props) {
  function handleEdit() {
    props.setEditable(true);
  }

  useEffect(() => {
    if (
      props.pageState?.complementaryData?.length === 0 &&
      props.complementaryDataAnswers?.length !== 0 &&
      props.complementaryDataAnswers?.length !== undefined
    ) {
      setComplementaryData(props.complementaryDataAnswers);
    }
    //Check if we need to get the complementary-data from the API
    if (
      props.applicationsState.subprogramsComplementaryData === undefined ||
      (props.applicationsState.subprogramsComplementaryData !== undefined &&
        !props.applicationsState.subprogramsComplementaryData.some(
          (subprogram) => subprogram.id === props.getValues(props.pageState.subprogramsFieldName)?.code
        ))
    ) {
      props.getSubprogramComplementaryData(props.getValues(props.pageState.subprogramsFieldName)?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Load here my complementarydata page state
    if (
      props.pageState?.complementaryData?.length === 0 &&
      props.applicationsState.subprogramsComplementaryData?.some(
        (subprogram) => subprogram.id === props.getValues(props.pageState.subprogramsFieldName)?.code
      )
    ) {
      setComplementaryData(
        props.applicationsState.subprogramsComplementaryData?.find(
          (s) => s.id === props.getValues(props.pageState.subprogramsFieldName)?.code
        )?.complementaryData
      );
    }
    //Load all complementary-data datasets if needed
    props.applicationsState.subprogramsComplementaryData
      ?.find((s) => s.id === props.getValues(props.pageState.subprogramsFieldName)?.code)
      ?.complementaryData.forEach((cd) => {
        if (cd.answerTypeId === COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET) {
          if (props.datasetsState[cd.dataSetName] === undefined || props.datasetsState[cd.dataSetName] === []) {
            props.getDataSetByName(cd.dataSetName);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.subprogramsComplementaryData?.length]);

  const setComplementaryData = (_complementaryData) => {
    props.setComplementaryData(_complementaryData);
  };

  const showHideFieldsOnChange = (childs, parentField, parentFieldType) => {
    if (childs == null || childs === undefined || childs.length === 0) return;
    childs.forEach((ch) => {
      let toShow = showChildField(
        ch.conditionalChildQuestionId + "",
        parentField,
        parentFieldType,
        ch.conditionalValueS
      );

      if (!toShow) {
        props.unregister(ch.conditionalChildQuestionId + "");

        //se a filha é para esconder, ele tem de ir a cada filha e ver se essa filha tem filhas e meter a false para esconder tudo
        var aux = props.pageState?.complementaryData.filter((r) => r.id === ch.conditionalChildQuestionId)[0]
          ?.complementaryDataConditionsList;
        showHideFieldsOnChange(aux, ch.conditionalChildQuestionId + "", ch.fieldTypeId);
      }
      props.showHideFieldsOnChange(ch.conditionalChildQuestionId + "", toShow);
    });
  };

  const showChildField = (childField, parentField, parentFieldType, valueCondition) => {
    if (!childField || !parentField) return true;
    const value = props.getValues(parentField);

    switch (parentFieldType) {
      case COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET:
        return value !== undefined && value?.code + "" === valueCondition + "";
      case COMPLEMENTARY_DATA_ANSWER_TYPE.TEXT:
        return value !== undefined && value === valueCondition;
      default:
        return value !== undefined && value === valueCondition;
    }
  };

  const getCardHeaderTitle = () => {
    return (
      "Dados complementares: " +
      props.getValues(props.pageState.programsFieldName)?.label +
      " / " +
      props.getValues(props.pageState.subprogramsFieldName)?.label
    );
  };

  const getComplementaryDataFields = (key, complementaryDataField) => {
    switch (complementaryDataField.answerTypeId) {
      case COMPLEMENTARY_DATA_ANSWER_TYPE.TEXT:
        return (
          <TextField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={
              props.getValues(complementaryDataField.id) === complementaryDataField.answer && !props.isEditable
            }
            answerValue={props.getValues(complementaryDataField.id) ?? complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            showHideChildOnChange={showHideFieldsOnChange}
            classes={props.classes}
            theme={props.theme}
          />
        );
      case COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET:
        return (
          <DropdownField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={
              props.getValues(complementaryDataField.id)?.code.toString() === complementaryDataField.answer &&
              !props.isEditable
            }
            selectOptions={getDatasetStateByName(props, complementaryDataField.dataSetName)}
            answerValue={props.getValues(complementaryDataField.id)?.code.toString() ?? complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            showHideChildOnChange={showHideFieldsOnChange}
            classes={props.classes}
            theme={props.theme}
          />
        );
      case COMPLEMENTARY_DATA_ANSWER_TYPE.BOOLEAN:
        return (
          <DropdownField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={
              props.getValues(`complementaryDataList[${key}].answer`) === complementaryDataField.answer &&
              !props.isEditable
            }
            selectOptions={YES_OR_NO_DROPDOWN}
            answerValue={
              props.getValues(`complementaryDataList[${key}].answer`)?.code.toString() ?? complementaryDataField.answer
            }
            name={`complementaryDataList[${key}].answer`}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            showHideChildOnChange={showHideFieldsOnChange}
            classes={props.classes}
            theme={props.theme}
          />
        );
      default:
        return (
          <TextField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={
              props.getValues(complementaryDataField.id) === complementaryDataField.answer && !props.isEditable
            }
            answerValue={props.getValues(complementaryDataField.id) ?? complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            showHideChildOnChange={showHideFieldsOnChange}
            classes={props.classes}
            theme={props.theme}
          />
        );
    }
  };

  return (
    <>
      <Card classes={{ root: props.classes.cardRoot }}>
        <CardHeader
          classes={{
            action: props.classes.cardHeaderAction,
            title: props.classes.cardHeader,
          }}
          title={getCardHeaderTitle()}
          action={
            <>
              {props.isEditPage ? (
                <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={handleEdit}>
                  Editar
                </GenericButton>
              ) : (
                ""
              )}
            </>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            {props.pageState?.complementaryData?.map(
              (complementaryDataItem, key) =>
                complementaryDataItem.show && (
                  <Grid item xs={12} md={4} key={key}>
                    <FormGroup>
                      <FormLabel>{complementaryDataItem.question}</FormLabel>
                      {getComplementaryDataFields(key, complementaryDataItem)}
                      <Controller
                        name={`complementaryDataList[${key}].complementaryDataId`}
                        control={props.control}
                        component={Box}
                        defaultValue={complementaryDataItem.complementaryDataId}
                        render={({ field: { onChange, name, value } }) => (
                          <InputBase
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            autoComplete="off"
                            type="hidden"
                          />
                        )}
                      />
                      <Controller
                        name={`complementaryDataList[${key}].answerTypeId`}
                        control={props.control}
                        component={Box}
                        defaultValue={complementaryDataItem.answerTypeId}
                        render={({ field: { onChange, name, value } }) => (
                          <InputBase
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            autoComplete="off"
                            type="hidden"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                )
            )}
            {props.pageState?.complementaryData.length === 0 && (
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Este subprograma não tem dados complementares a serem preenchidos.</FormLabel>
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByIndex: (index, addEmptyField) => dispatch(getDataSetByIndexAction(index, addEmptyField)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getSubprogramComplementaryData: (subprogramid) => dispatch(getSubprogramComplementaryDataAction(subprogramid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplementaryData);
