import React, { useEffect } from "react";

import { createContext, useCallback, useMemo, useState } from "react";

export const StepContext = createContext({});

export function StepProvider({ children }) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    return () => {
      setActiveStep(0);
    };
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleReset = useCallback(() => {
    setActiveStep(0);
  }, []);

  const handleStep = useCallback((step) => {
    setActiveStep(step);
  }, []);

  const values = useMemo(
    () => ({ activeStep, handleNext, handleBack, handleReset, handleStep }),
    [activeStep, handleNext, handleBack, handleReset, handleStep]
  );

  return <StepContext.Provider value={values}>{children}</StepContext.Provider>;
}
