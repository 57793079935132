import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Box, FormGroup, FormHelperText, FormLabel, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { Clear, Help } from "@material-ui/icons";
import useGenericStyles from "hooks/useGenericStyles";
import clsx from "clsx";
import Chip from "components/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useController } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    listStyle: "none",
    padding: 0,
    margin: 0,
    gap: theme.spacing(1),
  },
  li: {
    margin: 0,
    maxWidth: "100%",
    "& .MuiChip-root": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

export default function DropzoneFieldV2({
  control,
  name,
  label = "Documento",
  inputLabel = "Adicionar documento",
  maxSize,
  maxFiles,
  required,
  tooltipLabel,
  rules,
  multiple = false,
  metadata,
  existingFiles = [],
  onUpdateExistingFiles,
  onRemovePreviewFiles, // Nova função para passar a lista de arquivos deletados
  ...rest
}) {
  const classes = { ...useGenericStyles(), ...useStyles() };
  const theme = useTheme();

  const [deletedFiles, setDeletedFiles] = useState([]); // Novo estado para armazenar arquivos deletados

  const labelClasses = clsx({
    [classes.labelError]: required,
  });

  const maxNumberOfFiles = React.useMemo(() => {
    if (multiple) {
      return maxFiles || 5;
    }
    return undefined;
  }, [maxFiles, multiple]);

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: {
        value: required,
        message: "Campo obrigatório",
      },
      validate: {
        maxFile: (files) => {
          if (maxFiles && files?.length > maxFiles) {
            return `Número máximo de ficheiros excedido, número máximo: ${maxFiles}`;
          }
          return true;
        },
        maxSize: (files) => {
          if (maxSize && files?.some((file) => file?.size > maxSize * 1048576)) {
            return `Tamanho máximo de ficheiro excedido, tamanho máximo: ${maxSize}MB`;
          }
          return true;
        },
      },
    },
  });

  const combinedFiles = React.useMemo(() => {
    return [...(existingFiles || []), ...(value || [])];
  }, [existingFiles, value]);

  // Função para remover arquivos
  function onRemoveFile(key, isExistingFile = false) {
    if (isExistingFile) {
      const fileToRemove = existingFiles.find(({ documentId }) => documentId === key);
      if (fileToRemove) {
        const updatedExistingFiles = existingFiles.filter(({ documentId }) => documentId !== key);

        setDeletedFiles((prev) => [...prev, fileToRemove]);

        onUpdateExistingFiles(updatedExistingFiles);
        onRemovePreviewFiles([...deletedFiles, fileToRemove]);
      }
    } else {
      const newValue = value.filter((_, i) => i !== key - existingFiles.length);
      onChange(newValue);
    }
  }

  function onDrop(files) {
    if (metadata) {
      const filesWithMetadata = files.map((file) => {
        const newFile = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        newFile.metadata = metadata;
        return newFile;
      });
      return onChange([...value, ...filesWithMetadata]);
    }
    if (value?.length) {
      onChange([...value, ...files]);
    } else {
      onChange(files);
    }
  }

  return (
    <FormGroup>
      <FormLabel className={labelClasses}>
        {label}
        {tooltipLabel && (
          <Tooltip title={tooltipLabel}>
            <Help className={classes.tooltipInfoIcon} />
          </Tooltip>
        )}
      </FormLabel>

      <Dropzone {...rest} multiple={multiple} maxSize={maxSize} maxFiles={maxNumberOfFiles} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: classes.documentDropzone })}>
            <input {...getInputProps()} onBlur={onBlur} />
            <p>{inputLabel}</p>
          </div>
        )}
      </Dropzone>

      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
        {error?.message}
      </FormHelperText>

      <Box className={clsx(classes.ul, classes.list)} component="ul" width="100%" padding={0} marginTop={2}>
        {combinedFiles?.map((file, index) => (
          <li className={classes.li} key={`key-${index}`}>
            <Chip
              className={classes.documentBadge}
              variant="outlined"
              color="primary"
              label={file?.documentName || file?.name}
              onDelete={() => onRemoveFile(file?.documentId || index, !!file?.documentId)}
              deleteIcon={
                <IconButton>
                  <Clear color="primary" />
                </IconButton>
              }
            />
          </li>
        ))}
      </Box>
    </FormGroup>
  );
}
