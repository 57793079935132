import React, { useMemo } from "react";
import { Box, CircularProgress, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { FormProvider, useForm } from "react-hook-form";
import { DATASET } from "utils/const";
import moment from "moment";
import { useDataset } from "hooks/useDataset";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FormInputV2 from "components/FormFields/FormInputV2";
import FormDropdown from "components/FormFields/FormDropdown";
import { getKeyValueByCode } from "utils/datasets";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID } from "utils/const";
import { ConditionGuard } from "guards/ConditionGuard";
import { getEmployeeDetails } from "crud/rent.crud";
import useTokenHelper from "hooks/useTokenHelper";
import { getMyWorkCenters } from "crud/workCentersEmployer.crud";
import EmployeesAreaDocuments from "views/admin/UserArea/UserColective/WorkCentersEmployees/WorkCentersEmployeesModal/EmployeesAreaDocuments";
import FormMaskedInput from "components/FormFields/FormMaskedInput";
import { INPUT_MASKS } from "utils/const";
import { COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES } from "utils/const";
import { jsonToFormData } from "utils/formData";
import { editEmployeeSnapshot } from "crud/rent.crud";
import useMessage from "hooks/useMessage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FormDatePickerV2 from "components/FormFields/FormDatePickerV2";
import HelpIcon from "@material-ui/icons/Help";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesUserProfile = makeStyles(componentStylesUserProfile);

export const EmployeeModal = ({ isOpen, closeModal, modalData }) => {
  const methods = useForm();
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };

  const id = useMemo(() => modalData, [modalData]);

  const { data: employee, isFetching } = useQuery(["rent", "getEmployeeDetails", id], () => getEmployeeDetails(id), {
    enabled: !!id,
    onSuccess: (data) => {
      methods.reset({
        ...data,
        dateOfBirth: moment(data?.dateOfBirth),
        identityDocumentIssueDate: moment(data?.identityDocumentIssueDate),
        identityDocumentExpiryDate: moment(data?.identityDocumentExpiryDate),
        addmissionDate: moment(data?.addmissionDate),
        documents:
          data?.documents?.map((doc) => ({
            documentId: doc.id,
            ...doc,
          })) || [],
      });
    },
  });

  const tokenHelper = useTokenHelper();

  const { data: WORK_CENTERS } = useQuery(["my-workcenters"], () => getMyWorkCenters(), {
    enabled: !!tokenHelper?.getUserId(),
    select: (data) => {
      if (!data) return [];

      return data.map((item) => ({
        code: item.id,
        label: item.value,
      }));
    },
  });

  const queryClient = useQueryClient();
  const { showSuccessMessage, showErrorMessage } = useMessage();

  const { id: rentId } = useParams();

  const { mutateAsync: editEmployeeSnapshotMutation } = useMutation(
    ["rent", "editEmployeeSnapshot", id],
    editEmployeeSnapshot,
    {
      onSuccess: () => {
        showSuccessMessage("Trabalhador actualizado com sucesso.", {
          okAction: () => {
            queryClient.invalidateQueries(["get-filtered-employees-snapshot", rentId]);

            closeModal();
          },
        });
      },
      onError: () => {
        showErrorMessage("Erro ao actualizar o trabalhador.");
      },
    }
  );

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });

  const provinceId = methods.watch("provinceId");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  const { data: MARITAL_STATUS } = useDataset({ name: DATASET.MARITAL_STATUS });
  const { data: GENDER } = useDataset({ name: DATASET.GENDER });
  const { data: ACADEMIC_LEVEL } = useDataset({ name: DATASET.ACADEMIC_LEVEL });
  const { data: NATIONALITIES } = useDataset({ name: DATASET.NATIONALITIES });
  const { data: PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_PROFESSIONAL_CATEGORY } = useDataset({ name: DATASET.EMPLOYMENT_PROFESSIONAL_CATEGORY });
  const { data: EMPLOYMENT_STATUS } = useDataset({ name: DATASET.EMPLOYMENT_STATUS });
  const { data: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES } = useDataset({ name: DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES });

  const documentTypeId = methods.watch("documentTypeId");

  const requestFormDocuments = useMemo(() => {
    switch (documentTypeId) {
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT,
            name: "Passaporte",
          },
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.VISA,
            name: "Visto",
          },
        ];
      }
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.RESIDENT_CARD,
            name: "Cartão de Residente",
          },
        ];
      }
      case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS: {
        return [
          {
            id: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS,
            keyValue: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.OTHERS,
            name: "Documento de Identificação",
          },
        ];
      }
      default:
        return [];
    }
  }, [documentTypeId]);

  const isPassport = documentTypeId === COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT;

  const onSubmit = async (data) => {
    try {
      const documentTypeId = data?.documentTypeId;

      let documents = [];
      let employeeDocuments = [];

      if (documentTypeId !== employee?.documentTypeId) {
        const requiredDocuments = requestFormDocuments.map((doc) => doc.keyValue);

        let hasError = false;
        requiredDocuments.forEach((docKey) => {
          if (!data[docKey] || data[docKey].length === 0) {
            methods.setError(docKey, {
              type: "required",
              message: `O campo ${docKey} é obrigatório.`,
            });
            hasError = true;
          }
        });

        if (hasError) return;
      }

      const getId = (id) => {
        return data?.documents?.find((item) => item.documentTypeId === id)?.documentId;
      };

      switch (documentTypeId) {
        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT]) {
            employeeDocuments.push(
              {
                documentIndex: 0,
                documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT,
                documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT) ?? null,
              },
              {
                documentIndex: 1,
                documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA,
                documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.VISA) ?? null,
              }
            );

            const [passport] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.PASSPORT];
            const [visa] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.VISA];

            documents.push(passport, visa);
          }
          break;
        }

        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.RESIDENT_CARD]) {
            employeeDocuments.push({
              documentIndex: 0,
              documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD,
              documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.RESIDENT_CARD) ?? null,
            });

            const [file] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES?.RESIDENT_CARD];

            documents.push(file);
          }
          break;
        }
        case COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS: {
          if (data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES.OTHERS]) {
            employeeDocuments.push({
              documentIndex: 0,
              documentTypeId: COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS,
              documentId: getId(COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.OTHERS) ?? null,
            });

            const [file] = data[COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_KEY_VALUES?.OTHERS];

            documents.push(file);
          }
          break;
        }

        default:
          break;
      }

      await editEmployeeSnapshotMutation(
        jsonToFormData({
          id: data?.employeeId,
          snapshotId: id,
          data: {
            ...data,
            dateOfBirth: moment.utc(data?.dateOfBirth).format(),
            identityDocumentIssueDate: moment.utc(data?.identityDocumentIssueDate).format(),
            identityDocumentExpiryDate: moment.utc(data?.identityDocumentExpiryDate).format(),
            addmissionDate: moment.utc(data?.addmissionDate).format(),
            employeeDocuments,
            workVisaNumber:
              documentTypeId === COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES_ID.PASSPORT ? data?.workVisaNumber : "",
            secondaryFunctionId: data?.secondaryFunctionId ?? null,
            tertiaryFunctionId: data?.tertiaryFunctionId ?? null,
          },
          documents,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const nationalityId = methods.watch("nationalityId");

  const angola = NATIONALITIES?.find((n) => n.keyValue === "Nationalities_06");

  const isAngola = nationalityId === (angola?.code ?? null);

  const selectedDocumentTypeId = methods.watch("documentTypeId");

  const isRequiredDocs = employee?.documentTypeId !== selectedDocumentTypeId;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <GenericDialog open={isOpen} onClose={closeModal} maxWidth="lg">
          <PageTitleHeader title={"Detalhes do Trabalhador"} />

          <Box>
            <Grid item xs={12} md={12}>
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                Ao editar o registo do trabalhador, o sistema irá actualizar na área de utilizador o trabalhador em
                questão.
              </Typography>
            </Grid>
          </Box>

          <ConditionGuard
            condition={!isFetching}
            fallback={
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          >
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Pessoais
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="name"
                  label="Nome do trabalhador"
                  placeholder="Insira o nome do trabalhador"
                  required
                  control={methods.control}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2 name="nif" label="NIF" placeholder="Insira o NIF" required control={methods.control} />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="ssn"
                  label={
                    <>
                      NISS
                      <Tooltip title="Caso o trabalhador não esteja inscrito no INSS, colocar o nº Visto Trabalho no campo NISS.">
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </>
                  }
                  placeholder="Insira o NISS"
                  required
                  control={methods.control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputV2
                  name="address"
                  label="Morada"
                  placeholder="Insira a morada do trabalhador"
                  required={isAngola}
                  control={methods.control}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Província"
                  name="provinceId"
                  options={PROVINCES}
                  required={isAngola}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  control={methods.control}
                  label="Município"
                  name="municipalityId"
                  options={MUNICIPALITIES}
                  required={isAngola}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDropdown
                  label="Nacionalidade"
                  control={methods.control}
                  name="nationalityId"
                  options={NATIONALITIES}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePickerV2
                  name="dateOfBirth"
                  label="Data de Nascimento"
                  control={methods.control}
                  setValue={methods.setValue}
                  errors={methods.errors}
                  classes={methods.classes}
                  trigger={methods.trigger}
                  getValues={methods.getValues}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Género"
                  placeholder="Seleccione o Género"
                  control={methods.control}
                  name="genderId"
                  options={GENDER}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Estado Civil"
                  placeholder="Seleccione o Estado Civil"
                  control={methods.control}
                  name="maritalStatusId"
                  options={MARITAL_STATUS}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Nível Académico"
                  placeholder="Seleccione o Nível Académico"
                  control={methods.control}
                  name="academicLevelId"
                  options={ACADEMIC_LEVEL}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Documento de Identificação"
                  placeholder="Seleccione o Documento de Identificação"
                  control={methods.control}
                  name="documentTypeId"
                  options={COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputV2
                  name="identityDocumentNumber"
                  label="N.º do Documento de Identificação"
                  placeholder="Insira n.º de documento"
                  type="number"
                  control={methods.control}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePickerV2
                  name="identityDocumentIssueDate"
                  label="Data de Emissão"
                  control={methods.control}
                  setValue={methods.setValue}
                  errors={methods.errors}
                  classes={methods.classes}
                  trigger={methods.trigger}
                  getValues={methods.getValues}
                  maxDateToday={true}
                  minDateToday={false}
                  endDatename="endDate"
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDatePickerV2
                  name="identityDocumentExpiryDate"
                  label="Data de Validade"
                  control={methods.control}
                  setValue={methods.setValue}
                  errors={methods.errors}
                  classes={methods.classes}
                  trigger={methods.trigger}
                  getValues={methods.getValues}
                  maxDateToday={false}
                  minDateToday={true}
                  endDatename="endDate"
                  isRequired={true}
                />
              </Grid>

              <ConditionGuard condition={isPassport}>
                <Grid item xs={12} md={3}>
                  <FormInputV2
                    name="workVisaNumber"
                    label="Nº Visto de Trabalho"
                    placeholder="Insira nº do Visto de Trabalho"
                    control={methods.control}
                    required
                  />
                </Grid>
              </ConditionGuard>

              <Grid item xs={12} md={12}>
                <EmployeesAreaDocuments
                  requestFormDocuments={requestFormDocuments}
                  isCreating
                  isRequired={isRequiredDocs}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Dados Profissionais
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormDatePickerV2
                  name="addmissionDate"
                  label="Data de Admissão"
                  control={methods.control}
                  setValue={methods.setValue}
                  errors={methods.errors}
                  classes={methods.classes}
                  trigger={methods.trigger}
                  getValues={methods.getValues}
                  maxDateToday={true}
                  minDateToday={false}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Centro de Trabalho"
                  placeholder="Seleccione o Centro de Trabalho"
                  control={methods.control}
                  name="workCenterId"
                  options={WORK_CENTERS}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Situação Profissional"
                  placeholder="Seleccione a Situação Profissional"
                  control={methods.control}
                  name="employmentStatusId"
                  options={EMPLOYMENT_STATUS}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Categoria Profissional"
                  placeholder="Seleccione a Categoria Profissional"
                  control={methods.control}
                  name="professionalCategoryId"
                  options={EMPLOYMENT_PROFESSIONAL_CATEGORY}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Primária"
                  placeholder="Seleccione a Profissão Primária"
                  control={methods.control}
                  name="primaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Secundária (opcional)"
                  placeholder="Seleccione a Profissão Secundária"
                  control={methods.control}
                  name="secondaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDropdown
                  label="Profissão Terciária (opcional)"
                  placeholder="Seleccione a profissão terciária"
                  control={methods.control}
                  name="tertiaryFunctionId"
                  options={PROFESSIONAL_CATEGORY}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  name="baseSalary"
                  label="Salário Expresso no Contrato"
                  placeholder="Insira o salário base"
                  required
                  control={methods.control}
                  mask={INPUT_MASKS.MONETARY}
                  rules={{
                    validate: {
                      minimumSalary: (value) => {
                        const numericValue = parseInt(value.replace(/[^0-9]/g, ""));
                        if (numericValue < 70000) {
                          return "Valor deverá ser igual ou superior ao salário mínimo";
                        }
                        return true;
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="h2" style={{ color: "#002e9e" }}>
                  Outros dados
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="normalWorkingHours"
                  label="Horas Normais (opcional)"
                  placeholder="Insira as horas normais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="weeklyWorkingHours"
                  label="Horas Semanais (opcional)"
                  placeholder="Insira as horas semanais"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="regularProvision"
                  label="Prestação Regular (opcional)"
                  placeholder="Insira a prestação regular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormMaskedInput
                  mask={INPUT_MASKS.NUMERIC}
                  name="irregularProvision"
                  label="Prestação Irregular (opcional)"
                  placeholder="Insira a prestação irregular"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  gap: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={closeModal}>
                  Cancelar
                </GenericButton>
                <GenericButton onClick={methods.handleSubmit(onSubmit)} size="medium" color="primary">
                  Editar Trabalhador
                </GenericButton>
              </Grid>
            </Grid>
          </ConditionGuard>
        </GenericDialog>
      </form>
    </FormProvider>
  );
};
