import { Box, Typography } from "@material-ui/core";
import { CompanyInfoData } from "views/admin/RENT/Create/Steps/CompanyInfo";
import StepContainer from "views/admin/RENT/Create/Steps/step-container";
import ConfirmationEmployeesTable from "./confirmation-employee-table";
import { ConfirmationWorkCenterTable } from "./confirmation-workcenter-table";

export default function ConfirmationTab() {
  return (
    <StepContainer title="Confirmação">
      <Box>
        <Typography color="primary" variant="h3">
          1. Informações da Empresa
        </Typography>

        <CompanyInfoData />
      </Box>

      <Box>
        <Typography color="primary" variant="h3">
          2. Dados de Centros de Trabalho
        </Typography>

        <ConfirmationWorkCenterTable />
      </Box>

      <Box>
        <Typography color="primary" variant="h3">
          3. Dados de Trabalhadores
        </Typography>

        <ConfirmationEmployeesTable />
      </Box>
    </StepContainer>
  );
}
