import { StepContext } from "contexts/stepper-context";
import { useContext, useEffect, useMemo, useRef } from "react";

export default function useStepper(steps, initialStep = null) {
  const initialized = useRef(false);

  const context = useContext(StepContext);

  const stepsRef = useRef(steps);
  const initialStepRef = useRef(initialStep);

  useEffect(() => {
    initialized.current = true;
  }, []);

  if (!context) {
    throw new Error("useStepper must be used within a StepProvider");
  }

  const { activeStep, handleStep } = context;

  const canGoNext = useMemo(() => activeStep < stepsRef.current.length - 1, [activeStep]);
  const canGoBack = useMemo(() => activeStep > 0, [activeStep]);

  useEffect(() => {
    if (initialized.current && initialStepRef.current) {
      handleStep(initialStepRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, initialStepRef]);

  return { ...context, canGoNext, canGoBack, handleStep, steps: stepsRef.current };
}
