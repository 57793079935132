import React, { useMemo } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { FormGroup, FormLabel, Tooltip, FormHelperText, Box, TextField, CircularProgress } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import useGenericStyles from "hooks/useGenericStyles";

export default function FormDropdown({
  control,
  name,
  required = false,
  requiredMessage = "Campo obrigatório",
  label,
  tooltipLabel,
  placeholder,
  rules,
  mappingTo = "code",
  options = [],
  loading = false,
  ...props
}) {
  const classes = useGenericStyles();
  const theme = useTheme();

  // Memoize options to avoid unnecessary re-renders
  const memoizedOptions = useMemo(() => options, [options]);

  return (
    <FormGroup>
      {label && (
        <FormLabel className={clsx({ [classes.labelError]: required })}>
          {label}
          {tooltipLabel && (
            <Tooltip title={tooltipLabel}>
              <HelpIcon className={classes.tooltipInfoIcon} />
            </Tooltip>
          )}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          required: required ? requiredMessage : false,
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <Autocomplete
              {...field}
              {...props}
              options={memoizedOptions}
              noOptionsText="Sem opções"
              getOptionLabel={(option) => option?.label || ""}
              getOptionSelected={(option, value) => option[mappingTo] === value}
              data-testid={`form-dropdown-${name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={placeholder || "Seleccione uma opção"}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      notchedOutline: clsx({ [classes.borderWarning]: !!error }),
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: "normal",
                      fontSize: "14px",
                      color: theme.palette.gray[600],
                    },
                  }}
                />
              )}
              onChange={(_, data) => field.onChange(data ? data[mappingTo] : null)}
              value={field.value ? memoizedOptions.find((option) => option[mappingTo] === field.value) || null : null}
            />
            {error && (
              <FormHelperText component={Box} color={`${theme.palette.warning.main}!important`}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormGroup>
  );
}
