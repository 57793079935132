import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyle = makeStyles(() => ({
  root: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  subtitle: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 10,
  },
}));

export default function PreviewData({
  title,
  subtitle,
  flexDirection = "column",
  justifyContent = "center",
  children,
  ...rest
}) {
  const classes = useStyle();

  return (
    <Box
      {...rest}
      display="flex"
      className={clsx(classes.root, rest.className)}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
    >
      {children && children({ title, subtitle })}

      {!children && (
        <>
          <Typography variant="subtitle1" style={{ fontWeight: 300, fontSize: "16px" }}>
            {title}
          </Typography>
          <Typography className={classes.subtitle} variant="body1" style={{ fontWeight: 600, fontSize: "14px" }}>
            {subtitle || "-"}
          </Typography>
        </>
      )}
    </Box>
  );
}
