import React, { useEffect, useState } from "react";
import { Grid, Tooltip, makeStyles, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import LocationHeader from "components/Headers/LocationHeader";
import FilterSection from "./FilterSection";
import ResultSection from "./ResultSection";
import GenericButton from "components/Buttons/GenericButton";
import { getCheckRENTSubmission } from "crud/rent.crud";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import PageTitleHeader from "components/Headers/PageTitleHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function RENTList() {
  const classes = useStylesGeneric();
  const [messages, setMessages] = useState([]);
  const form = useForm({
    defaultValues: {
      name: "",
      stateId: "",
      startDate: null,
      endDate: null,
      page: 1,
      pageSize: 15,
    },
  });

  const { data: canSubmitRENT } = useQuery(["getCheckRENTSubmission"], () => getCheckRENTSubmission());

  useEffect(() => {
    if (canSubmitRENT?.messages) {
      setMessages(canSubmitRENT.messages);
    }
  }, [canSubmitRENT]);

  const buttonContent = (
    <GenericButton
      size="medium"
      color="tertiary"
      component={Link}
      to="/admin/rent/create"
      disabled={!canSubmitRENT?.canSubmit}
      style={{
        marginBottom: "37px",
      }}
    >
      Criar Mapa RENT
    </GenericButton>
  );

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <LocationHeader section="Lista de Mapas RENT" />

      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
          <PageTitleHeader title="Lista de Mapas RENT" />
        {!canSubmitRENT?.canSubmit ? (
          <Tooltip title={messages.join(" ")}>
            <span>{buttonContent}</span>
          </Tooltip>
        ) : (
          buttonContent
        )}
      </Grid>

      <FormProvider {...form}>
        <Grid item xs={12} md={12}>
          <FilterSection />
        </Grid>
        <Grid item xs={12} md={12}>
        <Box classes={{ root: classes.tableResultBoxRoot }}>

          <ResultSection />
        </Box>
        </Grid>
      </FormProvider>
    </Grid>
  );
}
