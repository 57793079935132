import React, { useEffect, useMemo, useState } from "react";
import { Box, FormGroup, FormHelperText, FormLabel, Grid, Typography, useTheme } from "@material-ui/core";
import { errorMessages } from "resources/resources";
import PageTitleHeader from "components/Headers/PageTitleHeader";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import FormInput from "components/Forms/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import FormSelect from "components/FormFields/FormSelect";
import { getDatasetStateByName } from "utils/datasets";
import { DATASET } from "utils/const";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { makeStyles } from "@material-ui/core";
import FormDatePicker from "components/Forms/FormDatePicker";
import DropzoneFieldV2 from "components/FormFields/DropzoneFieldV2";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@material-ui/icons";
import SearchWorkerModal from "./SearchWorkerModal";
import moment from "moment";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { jsonToFormData } from "utils/formData";
import { createContractApprovalAction } from "redux/actions/contractApprovalAction";
import { WORK_CENTERS_EMPLOYER } from "utils/actionsConsts";
import { createContractApprovalWorkCenterEmployeeAction } from "redux/actions/contractApprovalWorkCenterEmployeeAction";
import { listContractApprovalWorkCenterEmployeeAction } from "redux/actions/contractApprovalWorkCenterEmployeeAction";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import useDownloadDocument from "hooks/useDownloadDocument";
import { getEmployeeDocumentById } from "crud/workCentersEmployer.crud";
import FormMaskedInput from "components/FormFields/FormMaskedInput";
import { INPUT_MASKS } from "utils/const";
import { DOCUMENT_AND_IMAGE_TYPES } from "utils/const";
import { getKeyValueByCode } from "utils/datasets";
import { useDataset } from "hooks/useDataset";
import FormDropdown from "components/FormFields/FormDropdown";
import FormDatePickerV2 from "components/FormFields/FormDatePickerV2";
import { ConditionGuard } from "guards/ConditionGuard";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const WorkCenterEmployerDialog = ({ openDialog, handleCloseDialog, props, showSearchModal }) => {
  const theme = useTheme();
  const classes = { ...useStylesGeneric() };
  const intl = useIntl();
  const [showModal, setShowModal] = useState(true);
  const [workerId, setWorkerId] = useState(null);

  const datasetsState = useSelector((state) => state.datasetsState);

  const dispatch = useDispatch();

  const [, downloadDocument] = useDownloadDocument(getEmployeeDocumentById);

  const { new_contract_approval } = useSelector((state) => state.contractApprovalState) || {};

  useEffect(() => {
    if (new_contract_approval !== null && showSearchModal === false) {
      setShowModal(false);
    }
  }, [new_contract_approval, showSearchModal]);

  useEffect(() => {
    const fetchDataSetIfNeeded = (datasetName) => {
      if (props.datasetsState[datasetName] === undefined || props.datasetsState[datasetName]?.length === 0) {
        props.getDataSetByName(datasetName);
      }
    };

    fetchDataSetIfNeeded(DATASET.MARITAL_STATUS);
    fetchDataSetIfNeeded(DATASET.GENDER);
    fetchDataSetIfNeeded(DATASET.ACADEMIC_LEVEL);
    fetchDataSetIfNeeded(DATASET.NATIONALITIES);
    fetchDataSetIfNeeded(DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES);
    fetchDataSetIfNeeded(DATASET.CONTRACT_TYPE);
    fetchDataSetIfNeeded(DATASET.COMPANY_ACTIVITY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    const workerData = jsonToFormData({
      worker: {
        WorkerId: workerId,
        ContractApprovalId: new_contract_approval?.id || null,
        ContractTypeId: data.workerContractType?.code,
        ContractDocument: data.workerContract,
        ContractStartDate: moment.utc(data?.contractStartDate).format(),
        ContractEndDate: data?.contractEndDate ? moment.utc(data?.contractEndDate).format() : null,
      },
    });
    if (!!new_contract_approval) {
      await dispatch(createContractApprovalWorkCenterEmployeeAction(workerData));
    } else {
      await dispatch(createContractApprovalAction(workerData));
    }
    dispatch({
      type: WORK_CENTERS_EMPLOYER.CLEAR_WORKER_SEARCH_BY_SSN,
    });
    await dispatch(listContractApprovalWorkCenterEmployeeAction(new_contract_approval?.id));
    handleCloseDialog();
  };

  const getFileInfoByDocumentType = (documentTypeId) => {
    const doc = props.workCenterEmployerState.result_getbyssn.data?.documents?.find(
      (d) => d.documentTypeId === documentTypeId
    );
    if (doc)
      return [
        {
          documentId: doc.documentId,
          documentName: doc.documentName,
        },
      ];
    return undefined;
  };

  const { data: PROVINCES } = useDataset({ name: DATASET.PROVINCES });
  const provinceId = methods.watch("workerProvince");
  const provinceKeyValue = useMemo(() => getKeyValueByCode(PROVINCES, provinceId), [PROVINCES, provinceId]);

  const { data: MUNICIPALITIES } = useDataset(
    { name: DATASET.MUNICIPALITIES, parentKeyValue: provinceKeyValue },
    {
      enabled: !!provinceKeyValue,
    }
  );

  async function fillData(workerInfo) {
    methods.setValue("workerName", workerInfo?.name);
    methods.setValue("workerNISS", workerInfo?.ssn);
    methods.setValue("workerNIF", workerInfo?.nif);
    methods.setValue("workerAddress", workerInfo?.address);
    methods.setValue("workerProvince", workerInfo?.provinceId);
    methods.setValue("workerMunicipality", workerInfo?.municipalityId);
    methods.setValue(
      "workerNationality",
      getSelectOptionsElementByCode(datasetsState.Nationalities, workerInfo?.nationalityId)
    );
    methods.setValue("workerBirthDate", moment(workerInfo?.dateOfBirth));
    methods.setValue("workerGender", getSelectOptionsElementByCode(datasetsState.Gender, workerInfo?.genderId));
    methods.setValue(
      "workerMaritalStatus",
      getSelectOptionsElementByCode(datasetsState.MaritalStatus, workerInfo?.maritalStatusId)
    );
    methods.setValue(
      "workerAcademicLevel",
      getSelectOptionsElementByCode(datasetsState.AcademicLevel, workerInfo?.academicLevelId)
    );
    methods.setValue(
      "workerDocumentType",
      getSelectOptionsElementByCode(datasetsState.CollectiveEmployeeDocumentTypes, workerInfo?.documentTypeId)
    );
    methods.setValue(
      "mainCompanyActivity",
      getSelectOptionsElementByCode(datasetsState.CompanyActivity, workerInfo?.mainCompanyActivity)
    );
    methods.setValue("workerDocumentNumber", workerInfo?.identityDocumentNumber);
    methods.setValue("workerDocumentIssueDate", moment(workerInfo?.identityDocumentIssueDate));
    methods.setValue("workerDocumentExpiryDate", moment(workerInfo?.identityDocumentExpiryDate));
    methods.setValue("workerBaseSalary", workerInfo?.baseSalary);
  }
  const openSearchWorkerModal = () => {
    dispatch({
      type: WORK_CENTERS_EMPLOYER.CLEAR_WORKER_SEARCH_BY_SSN,
    });
    setShowModal(true);
  };

  const handleClean = async () => {
    methods.reset();
    dispatch({
      type: WORK_CENTERS_EMPLOYER.CLEAR_WORKER_SEARCH_BY_SSN,
    });
  };

  const handleCancel = async () => {
    await handleClean();

    handleCloseDialog();
  };

  const contractType = methods.watch("workerContractType");

  const isIndefiniteContract = useMemo(() => {
    return contractType?.keyValue === "TempoIndeterminado";
  }, [contractType]);

  useEffect(() => {
    if (isIndefiniteContract) {
      methods.setValue("contractEndDate", null);
    }
  }, [isIndefiniteContract, methods]);

  return (
    <FormProvider {...methods}>
      <form>
        <GenericDialog open={openDialog} onClose={handleCancel} fullWidth={true} maxWidth="xl">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PageTitleHeader
              title={"Adicionar Trabalhador"}
              style={{
                marginLeft: "-22px",
              }}
            />
            <GenericButton
              onClick={() => openSearchWorkerModal()}
              startIcon={<Search />}
              color="ghost"
              style={{
                display: "flex",
                marginBottom: "37px",
              }}
            >
              Pesquisar
            </GenericButton>
          </Box>
          <Box>
            <Grid item xs={12} md={12}>
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                Ao adicionar um novo registo de trabalhador, o sistema irá actualizar na área de utilizador a lista de
                trabalhadores registados da sua empresa.
              </Typography>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" style={{ color: "#002e9e" }}>
                Informações do Trabalhador
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name="workerName"
                label="Nome do Trabalhador"
                placeholder="Insira o nome do trabalhador"
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={true}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                name="workerNISS"
                label="NISS"
                placeholder="Insira o NISS"
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={true}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                name="workerNIF"
                label="NIF"
                placeholder="Insira o NIF"
                required
                control={methods.control}
                errors={methods.formState.errors}
                disabled={true}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <FormInput
                name="workerAddress"
                label="Morada"
                placeholder="Insira a morada"
                control={methods.control}
                errors={methods.formState.errors}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                control={methods.control}
                name="workerProvince"
                label="Província"
                options={PROVINCES}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDropdown
                control={methods.control}
                name="workerMunicipality"
                label="Município"
                options={MUNICIPALITIES}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Nacionalidade</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerNationality"
                  selectOptions={getDatasetStateByName(props, DATASET.NATIONALITIES)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
                {methods.formState.errors["workerNationality"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerNationality"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDatePicker
                name="workerBirthDate"
                label="Data de Nascimento"
                control={methods.control}
                rules={{ required: false }}
                getValues={methods.getValues}
                setValue={methods.setValue}
                trigger={methods.trigger}
                maxDateToday={true}
                minDateToday={false}
                isRequired={true}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Género</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerGender"
                  selectOptions={getDatasetStateByName(props, DATASET.GENDER)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
                {methods.formState.errors["workerGender"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerGender"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Estado Civil</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerMaritalStatus"
                  selectOptions={getDatasetStateByName(props, DATASET.MARITAL_STATUS)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
                {methods.formState.errors["workerMaritalStatus"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerMaritalStatus"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Nivel Académico</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerAcademicLevel"
                  selectOptions={getDatasetStateByName(props, DATASET.ACADEMIC_LEVEL)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
                {methods.formState.errors["workerAcademicLevel"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerAcademicLevel"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Documento de Identificação</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerDocumentType"
                  selectOptions={getDatasetStateByName(props, DATASET.COLLECTIVE_EMPLOYEE_DOCUMENT_TYPES)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
                {methods.formState.errors["workerDocumentType"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerDocumentType"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                name="workerDocumentNumber"
                label="Nº de Documento"
                placeholder="Insira o Nº de documento"
                type="number"
                control={methods.control}
                errors={methods.formState.errors}
                disabled={true}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDatePicker
                name="workerDocumentIssueDate"
                label="Data de Emissão"
                control={methods.control}
                rules={{ required: false }}
                getValues={methods.getValues}
                setValue={methods.setValue}
                trigger={methods.trigger}
                maxDateToday={true}
                minDateToday={false}
                endDateFieldName="endDate"
                isRequired={true}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormDatePicker
                name="workerDocumentExpiryDate"
                label="Data de validade"
                control={methods.control}
                rules={{ required: false }}
                getValues={methods.getValues}
                setValue={methods.setValue}
                trigger={methods.trigger}
                maxDateToday={false}
                minDateToday={true}
                endDateFieldName="endDate"
                isRequired={true}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" style={{ color: "#002e9e" }}>
                Informações Sobre a Actividade
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Ramo de Actividade</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="mainCompanyActivity"
                  selectOptions={getDatasetStateByName(props, DATASET.COMPANY_ACTIVITY)}
                  getValues={methods.getValues}
                  required={false}
                  errors={methods.formState.errors}
                  classes={classes}
                  disabled={true}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Tipo de Contrato</FormLabel>
                <FormSelect
                  control={methods.control}
                  fieldName="workerContractType"
                  selectOptions={getDatasetStateByName(props, DATASET.CONTRACT_TYPE)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["workerContractType"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["workerContractType"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>{" "}
            <Grid item xs={12} md={3}>
              <FormDatePickerV2
                name="contractStartDate"
                label="Data de Início de Contrato"
                control={methods.control}
                maxDateToday={true}
                minDateToday={false}
                endDateFieldName="contractEndDate"
                errors={methods.errors}
                classes={classes}
                trigger={methods.trigger}
                getValues={methods.getValues}
                isRequired={true}
              />
            </Grid>
            <ConditionGuard condition={!isIndefiniteContract}>
              <Grid item xs={12} md={3}>
                <FormDatePickerV2
                  name="contractEndDate"
                  label="Data de Fim de Contrato"
                  control={methods.control}
                  maxDateToday={false}
                  minDateToday={true}
                  errors={methods.errors}
                  classes={classes}
                  trigger={methods.trigger}
                  getValues={methods.getValues}
                  isRequired={!isIndefiniteContract}
                  disabled={isIndefiniteContract}
                />
              </Grid>
            </ConditionGuard>
            <Grid item xs={12} md={3}>
              <FormMaskedInput
                name="workerBaseSalary"
                label="Salário Expresso no Contrato"
                placeholder="Insira o salário base"
                errors={methods.formState.errors}
                required
                control={methods.control}
                disabled={true}
                mask={INPUT_MASKS.MONETARY}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" style={{ color: "#002e9e" }}>
                Documentos
              </Typography>
            </Grid>
            {props.workCenterEmployerState?.result_getbyssn?.data?.documentTypeId != null &&
              props.workCenterEmployerState?.result_getbyssn?.data?.documents?.lenght > 0 && (
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel className={classes.boxNameCategory}>Documento de identificação</FormLabel>
                    <SubmitedDocumentsSection
                      files={getFileInfoByDocumentType(
                        props.workCenterEmployerState.result_getbyssn.data?.documentTypeId
                      )}
                      getDocument={downloadDocument}
                    />
                  </FormGroup>
                </Grid>
              )}
            <Grid item xs={12} md={3}>
              <FormGroup>
                <DropzoneFieldV2
                  name={"workerContract"}
                  label={"Contrato de Trabalho"}
                  required={true}
                  accept={DOCUMENT_AND_IMAGE_TYPES}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                gap: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GenericButton typeSubmit={false} size="medium" color="tertiary" onClick={() => handleCancel()}>
                Cancelar
              </GenericButton>
              <GenericButton size="medium" color="primary" onClick={methods.handleSubmit(onSubmit)}>
                {"Adicionar Trabalhador"}
              </GenericButton>
            </Grid>
          </Grid>
        </GenericDialog>
        {showModal && (
          <SearchWorkerModal
            newContractApprovalId={new_contract_approval?.id}
            open={showModal}
            onClose={() => setShowModal(false)}
            fillData={fillData}
            setWorkerId={setWorkerId}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default WorkCenterEmployerDialog;
