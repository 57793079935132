import React from "react";

import { Chip as MuiChip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useMemo } from "react";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "fit-content",
  },
  default: {
    borderColor: "#1B1E20",
    color: "#1B1E20",
    backgroundColor: "#E9EBEC",
  },
  primary: {
    borderColor: "#2F9842",
    color: "#2F9842",
    backgroundColor: "#E1F4E4",
  },
  secondary: {
    borderColor: "#761912",
    color: "#761912",
    backgroundColor: "#FDE0DE",
  },
  warning: {
    borderColor: "#D76605",
    color: "#D76605",
    backgroundColor: "#FFEBD9",
  },
});

export default function Chip({ color, className, ...rest }) {
  const classes = useStyles(rest);

  const colorClass = useMemo(() => {
    return classes[color] || classes.default;
  }, [color, classes]);

  return <MuiChip className={clsx([colorClass, classes.root, className])} {...rest} />;
}

Chip.defaultProps = {
  color: "default",
};

Chip.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "secondary", "warning"]),
};
