import Container from "components/Container";
import useStepper from "hooks/useStepper";
import { StepProvider } from "contexts/stepper-context";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import LocationHeader from "components/Headers/LocationHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import CompanyInfoTab from "../Create/Steps/CompanyInfo";
import Stepper from "components/Stepper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getRENTGetDetailsByName } from "crud/rent.crud";
import moment from "moment";
import NavigationButtons from "../Create/navigation-buttons";
import WorkCentersInfoTab from "./Steps/WorkCentersInfo";
import EmployeesInfoTab from "./Steps/EmployeesInfo";
import ConfirmationTab from "./Steps/Confirmation";
import { updateMapRent } from "crud/rent.crud";
import useMessage from "hooks/useMessage";
import PreviewObservations from "components/PreviewObservations";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
}));

const STEPS = {
  COMPANY_INFO: 0,
  WORK_CENTERS_INFO: 1,
  EMPLOYEES_INFO: 2,
  CONFIRMATION: 3,
};

function EditMapRENT() {
  const { id } = useParams();

  const classes = useStyles();

  const { steps, activeStep, canGoNext, handleNext, canGoBack, handleBack } = useStepper([
    {
      title: "Informações da empresa",
      subtitle: "Registo de dados da empresa",
    },
    {
      title: "Dados de centros",
      subtitle: "Informações sobre os centros de trabalho",
    },
    {
      title: "Dados de trabalhadores",
      subtitle: "Registo de dados dos trabalhadores",
    },
    {
      title: "Confirmação",
      subtitle: "Confirmação dos dados",
    },
  ]);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      user: {
        id: "",
        name: "",
        email: "",
        address: "",
        taxIdNumber: "",
        niss: "",
        phoneNumber: "",

        provinceId: "",
        municipalityId: "",
        legalForm: "",

        totalWorkersNumber: "",
        shareCapital: "",
        publicDivisionPercentage: "",
        privateNationalDivisionPercentage: "",
        activityStartYear: null,

        businessPurposeList: [],
      },
    },
  });

    const [observations, setObservations] = useState([]);
    useQuery(["getRENTGetDetailsByName", id], () => getRENTGetDetailsByName(id), {
    onSuccess: (data) => {
      setObservations(data.observations);
      form.reset({
        employmentCenterId: data.employmentCenterId,
        employmentCenterProvinceKeyValue: data.provinceKeyValue,

        user: {
          // id: data.id,
          name: data.companyName,
          email: data?.companyEmail,
          address: data?.companyAddress,
          taxIdNumber: data?.companyTaxIdNumber,
          niss: data?.companyNISS,
          phoneNumber: data?.companyPhoneNumber,

          companyActivityId: data?.companyActivityId,
          provinceId: data?.companyProvinceId,
          municipalityId: data?.companyMunicipalityId,
          legalFormId: data?.companyLegalFormId,

          totalWorkersNumber: data?.companyTotalWorkersNumber,
          shareCapital: data?.companyShareCapital,
          publicDivisionPercentage: data?.companyPublicDivisionPercentage,
          privateNationalDivisionPercentage: data?.companyPrivateNationalDivisionPercentage,
          activityStartYear: moment(data?.companyActivityStartYear, "YYYY"),
          corporateObject: data?.companyCorporateObject,

          businessPurposeList: data?.companyBusinessPurposeList,
        },
      });
    },
  });

  const { showSuccessMessage, showErrorMessage } = useMessage();

  const { mutateAsync } = useMutation(["update-map-rent", id], updateMapRent, {
    onSuccess: (data) => {
      if (data.succeeded) {
        showSuccessMessage("Mapa RENT actualizado com sucesso.", {
          goBack: true,
          onClose: "/admin/rent/details/" + id,
          isSuccess: data.succeeded,
        });
      } else {
        showErrorMessage("Ocorreu um erro ao actualizar o mapa RENT.");
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onSubmit = async (data) => {
    try {
      if (!canGoNext) {
        await mutateAsync({
          ...data.user,
          id,
          activityStartYear: data.user.activityStartYear.format("YYYY"),
          employmentCenterId: data.employmentCenterId,
        });

        return;
      }

      handleNext();
    } catch (error) {
      console.error(error);
    }
  };

  const nextLabel = useMemo(() => (!canGoNext ? "Editar Mapa Rent" : "Seguinte"), [canGoNext]);

  return (
    <FormProvider {...form}>
      <Container>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LocationHeader section="Editar Mapa RENT" subsection={id} subsectionLink={`/admin/rent/details/${id}`} />

          <header className={classes.header}>
            <Typography
              style={{
                textTransform: "uppercase",
              }}
              color="primary"
              variant="h1"
            >
              Editar Mapa RENT
            </Typography>
          </header>

          <Stepper steps={steps} activeStep={activeStep} showIndex>
            {(step) => {
              switch (step) {
                case STEPS.COMPANY_INFO:
                  return <CompanyInfoTab fetchDefaultValues={false} isEditing={true} />;
                case STEPS.WORK_CENTERS_INFO:
                  return <WorkCentersInfoTab />;
                case STEPS.EMPLOYEES_INFO:
                  return <EmployeesInfoTab />;
                case STEPS.CONFIRMATION:
                  return <ConfirmationTab />;
                default:
                  return null;
              }
            }}
          </Stepper>
          <section className={classes.section}>
            <Typography color="primary" variant="h2">
              Observações
            </Typography>

            <Card>
              <CardContent className={classes.content}>
                <PreviewObservations observations={observations} />
              </CardContent>
            </Card>
          </section>
          <NavigationButtons nextLabel={nextLabel} canGoBack={canGoBack} handleBack={handleBack} />
        </form>
      </Container>
    </FormProvider>
  );
}

export default function Page() {
  return (
    <StepProvider>
      <EditMapRENT />
    </StepProvider>
  );
}
