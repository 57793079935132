import { createWorkCenter, updateWorkCenter, listWorkCenters, getWorkCenter } from "crud/workCenters.crud";

import { WORK_CENTERS, DIALOG } from "utils/actionsConsts";

export const createWorkCenterAction = (data, onCloseAction) => async (dispatch) => {
  dispatch({
      type: WORK_CENTERS.CREATE_WORK_CENTERS_LOADING,
  });

  try {
      await createWorkCenter(data);

      dispatch({
          type: WORK_CENTERS.CREATE_WORK_CENTERS,
      });
      dispatch({
          type: DIALOG.SHOW,
          payload: {
              showGenericDialog: true,
              genericDialogData: {
                type: DIALOG.ONE_BUTTON_TYPE,
                isSuccess: true,
                buttonLabel: "Ok",
                message: "Centro de trabalho criado com sucesso.",
                onCloseAction: onCloseAction || undefined,
                okAction: onCloseAction || undefined,              },
          },
      });
  } catch (error) {
      const errorMessage =
          error.response?.data?.errors
              ? Object.values(error.response.data.errors).flat().join(", ")
              : "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.";

      dispatch({
          type: WORK_CENTERS.CREATE_WORK_CENTERS,
          payload: false,
      });
      dispatch({
          type: WORK_CENTERS.CREATE_WORK_CENTERS_LOADING,
          payload: false,
      });
      dispatch({
          type: DIALOG.SHOW,
          payload: {
              showGenericDialog: true,
              genericDialogData: {
                  type: DIALOG.ONE_BUTTON_TYPE,
                  isSuccess: false,
                  message: errorMessage,
              },
          },
      });
  }
};


export const getFilteredWorkCenterAction = (data) => async (dispatch) => {
  const dataFilters = {
    Name: data?.name,
    ProvinceId: data?.province,
    DistrictId: data?.district,
    MunicipalityId: data?.municipality,
    SecundaryActivityId: data?.secundaryActivity,
    TertiaryActivityId: data?.tertiaryActivity,
    PageIndex: data?.pageIndex,
    PageSize: data?.pageSize,
  };
  dispatch({
    type: WORK_CENTERS.LIST_WORK_CENTERS_LOADING,
    payload: true,
  });
  listWorkCenters(dataFilters)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          province: record.province,
          created: record.created,
          district: record.district,
          municipality: record.municipality,
          name: record.name,
          secundaryActivity: record.secundaryActivity,
          tertiaryActivity: record.tertiaryActivity,
        };
      });
      dispatch({
        type: WORK_CENTERS.LIST_WORK_CENTERS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: WORK_CENTERS.LIST_WORK_CENTERS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: WORK_CENTERS.LIST_WORK_CENTERS,
        result: false,
        payload: [],
      });
      dispatch({
        type: WORK_CENTERS.LIST_WORK_CENTERS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateWorkCenterAction = (data, redirect) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS.UPDATE_WORK_CENTERS_LOADING,
    payload: true,
  });

  return updateWorkCenter(data)
    .then((res) => {
      dispatch(
        getFilteredWorkCenterAction({
          pageIndex: 1,
          pageSize: 10,
        })
      );
      dispatch({
        type: WORK_CENTERS.UPDATE_WORK_CENTERS,
        payload: true,
      });
      dispatch({
        type: WORK_CENTERS.UPDATE_WORK_CENTERS_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.status === 200 ? true : false,
            goBack: redirect,
            buttonLabel: "Ok",
            onClose: redirect ? "/admin/user-profile" : null,
            message: res.status === 200
              ? "Centro de trabalho actualizado com sucesso."
              : "Ocorreu um erro ao actualizar o centro de trabalho.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: WORK_CENTERS.UPDATE_WORK_CENTERS,
        payload: false,
      });
      dispatch({
        type: WORK_CENTERS.UPDATE_WORK_CENTERS_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const getWorkCenterByIdAction = (id) => async (dispatch) => {
  dispatch({
    type: WORK_CENTERS.GET_WORK_CENTERS_LOADING,
    payload: true,
  });

  return getWorkCenter(id)
    .then((res) => {
      dispatch({
        type: WORK_CENTERS.GET_WORK_CENTERS_LOADING,
        payload: false,
      });

      return res.data;
    })
    .catch((error) => {
      dispatch({
        type: WORK_CENTERS.GET_WORK_CENTERS,
        result: false,
      });
      dispatch({
        type: WORK_CENTERS.GET_WORK_CENTERS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
