import { React } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import moment from "moment";
import { ConditionGuard } from "guards/ConditionGuard";
import { useQuery } from "@tanstack/react-query";
import usePaginate from "hooks/usePaginate";
import { listWorkCenterEmployers } from "crud/workCentersEmployer.crud";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export const EmployeesTable = () => {
  const classes = useStylesGeneric();

  const [page, setPage] = usePaginate();

  const { isLoading, data } = useQuery(["rent", "listWorkCenterEmployers", page], () =>
    listWorkCenterEmployers({
      pageIndex: page,
      pageSize: 15,
    })
  );

  const tableHead = [
    "Data de Registo",
    "Nome do Trabalhador",
    "NISS",
    "Centro de Trabalho",
    "Situação Profissional",
    "Categoria Profissional",
  ];

  return (
    <>
      <CardTableContainer title={"Lista de Trabalhadores Registados Na Sua Empresa"} count={data?.totalCount}>
        <TableContainer>
          <ConditionGuard condition={!isLoading} fallback={<TableBackdrop open />}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(prop.created).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.name}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.ssn}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.workCenterDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.employeeStatusDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.professionalCategoryDescription}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </ConditionGuard>
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

        <Pagination
          className={classes.alignPagination}
          count={data?.totalPages}
          page={page}
          onChange={setPage}
          color="primary"
        />
      </CardTableContainer>

      {!isLoading && data?.data?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
};
