import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDataSetByName } from "crud/datasets.crud";
import { useMemo } from "react";

/**
 * Custom hook to fetch and cache a dataset.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.name - The name of the dataset.
 * @param {Array} params.parentKeyValue - The parent key values to filter the dataset.
 * @param {Object} options - The options for the query.
 * @returns {Object} The query result containing the dataset.
 */
export const useDataset = ({ name, parentKeyValue }, options) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ["dataset", name, parentKeyValue], [name, parentKeyValue]);

  return useQuery(
    queryKey,
    async () => {
      const cachedData = queryClient.getQueryData(queryKey);

      if (cachedData) {
        return cachedData;
      }

      const response = await getDataSetByName(name, parentKeyValue);
      return response.data;
    },
    {
      ...options,
      staleTime: Infinity,
      cacheTime: 7 * 24 * 60 * 60 * 1000, // Cache the data for 7 days
      select: (data) => {
        if (!data) return [];

        return data.map((item) => ({
          code: item.id,
          label: item.value,
          keyValue: item.keyValue,
        }));
      },
    }
  );
};
