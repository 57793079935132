import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, createTheme, useTheme } from "@material-ui/core/styles";
import { Grid, InputAdornment, ThemeProvider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import GenericButton from "components/Buttons/GenericButton";
import { getAuthenticationContext } from "utils/auth";
import { LOGIN_PENDING, LOGIN_USER_DISABLED, USER_TYPE } from "utils/const";
import { labels, titles } from "resources/resources";
import { checkIfContextIsSSO } from "utils/auth";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import CustomDialogTitle from "components/Dialogs/CustomDialogTitle";

import componentStyles from "assets/theme/components/navbars/auth-navbar.js";

const useStyles = makeStyles(componentStyles);

function LoginModal(props) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const intl = useIntl();

  const iconTheme = createTheme({
    overrides: {
      MuiIconButton: {
        root: {
          // This may seem useless but the Icons would not
          // change their fontSize if we didnt do this
          // first.
          fontSize: "20px",
        },
      },
    },
  });

  const [shouldShowPassword, setShouldShowPassword] = React.useState(false);
  const [emailText, setEmailText] = React.useState("");

  const onClickShowPassword = () => setShouldShowPassword((shouldShowPassword) => !shouldShowPassword);

  const onClickMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangeEmailField = (e) => {
    props.onChange("email", e.target.value);
    setEmailText(() => e.target.value);
  };

  return (
    <Dialog
      open={props.loginModal}
      onClose={!checkIfContextIsSSO() ? props.handleClose : undefined}
      classes={{ paper: classes.modal }}
    >
      <CardContent classes={{ root: classes.cardContent }}>
        <form>
          <Grid container>
            <Grid item>
              <Box
                color={theme.palette.gray[600]}
                textAlign="center"
                marginBottom="1rem"
                marginTop="0px"
                fontSize="2rem"
              >
                <Box
                  style={{
                    fontSize: "2rem",
                    lineHeight: "3rem",
                    color: theme.palette.primary.greyBlue,
                  }}
                  classes={{ root: classes.modalHeader }}
                >
                  {intl.formatMessage(labels.Label_Auth_WelcomeToINEFOP).slice(0, 9)}
                  <br />
                  {intl.formatMessage(labels.Label_Auth_WelcomeToINEFOP).slice(10)}
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <CustomDialogTitle
                id="customized-dialog-title"
                onClose={!checkIfContextIsSSO() ? props.handleClose : undefined}
              />
            </Grid>
          </Grid>

          <Box color={theme.palette.gray[600]} textAlign="center" marginBottom="-5px" marginTop=".5rem" fontSize="1rem">
            <Box className={classes.loginHeader}>
              Ainda não tem conta?&nbsp;
              <Link
                onClick={props.handleClose}
                className={classes.registerButton}
                to={
                  props.authState?.targetPathAfterLoginUserType
                    ? props.authState?.targetPathAfterLoginUserType === USER_TYPE.COLLECTIVE
                      ? `/${getAuthenticationContext()}/collective-user-registration`
                      : `/${getAuthenticationContext()}/individual-user-registration`
                    : `/${getAuthenticationContext()}/register`
                }
              >
                Registe-se aqui
              </Link>
            </Box>
          </Box>
          <FormControl
            variant="filled"
            component={Box}
            width="100%"
            top="55px"
            marginBottom="1rem!important"
            className={classes.boxHeader}
          >
            <FormLabel className={classes.authenticationLabels}>
              {intl.formatMessage(titles.Label_LoginModal_EmailInputTitle)}
            </FormLabel>
            <OutlinedInput
              className="emailInput"
              placeholder={intl.formatMessage(titles.Label_LoginModal_EmailInputPlaceholder)}
              autoComplete="off"
              type="email"
              onChange={onChangeEmailField}
              value={emailText}
              endAdornment={
                <InputAdornment position="end">
                  <ThemeProvider theme={iconTheme}>
                    <IconButton onClick={() => setEmailText(() => "")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </ThemeProvider>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            variant="filled"
            component={Box}
            width="100%"
            marginBottom="1rem!important"
            top="70px"
            className={classes.boxHeader}
          >
            <FormLabel className={classes.authenticationLabels}>
              {intl.formatMessage(titles.Label_LoginModal_PasswordInputTitle)}
            </FormLabel>
            <OutlinedInput
              placeholder={intl.formatMessage(titles.Label_LoginModal_PasswordInputPlaceholder)}
              autoComplete="off"
              type={shouldShowPassword ? "text" : "password"}
              value={props.state.password}
              onChange={(e) => props.onChange("password", e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <ThemeProvider theme={iconTheme}>
                    <IconButton onClick={onClickShowPassword} onMouseDown={onClickMouseDownPassword} edge="end">
                      {shouldShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </ThemeProvider>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box marginTop="70px" textAlign="right">
            <Link
              onClick={function () {
                if (!checkIfContextIsSSO()) props.handleClose();
                props.handlerecoverModal();
              }}
              className={classes.loginRecover}
              to="#"
            >
              Esqueceu-se da palavra-passe?
            </Link>
          </Box>
          {/* Need changes to redirect for the correct page */}
          {/* Error Messages */}
          {props.authState.login_message === LOGIN_PENDING ? (
            <FormHelperText
              component={Box}
              color={theme.palette.error.main + "!important"}
              className={classes.invalidLogin}
            >
              O utilizador está pendente de aprovação!
            </FormHelperText>
          ) : (
            <></>
          )}
          {props.authState.login_message === LOGIN_USER_DISABLED ? (
            <FormHelperText
              component={Box}
              color={theme.palette.error.main + "!important"}
              className={classes.invalidLogin}
            >
              O utilizador está inactivo!
            </FormHelperText>
          ) : (
            <></>
          )}
          {props.authState.login_error &&
          props.authState.login_message !== LOGIN_PENDING &&
          props.authState.login_message !== LOGIN_USER_DISABLED ? (
            <FormHelperText
              component={Box}
              color={theme.palette.error.main + "!important"}
              className={classes.invalidLogin}
            >
              Telemóvel, E-mail ou Palavra-passe inválidos!
            </FormHelperText>
          ) : (
            <></>
          )}

          {/* Buttons */}
          <Box textAlign="right" marginTop="2.5rem" marginBottom="1.5rem" padding="5px">
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              onClick={!checkIfContextIsSSO() ? props.handleClose : undefined}
            >
              Cancelar
            </GenericButton>
            <GenericButton
              typeSubmit={true}
              color="primary"
              onClick={() => {
                props.loginAction(props.state.email, props.state.password, props.targetPath);
              }}
              style={{ marginLeft: "15px" }}
              disabled={props.authState.login_loading}
              loading={props.authState.login_loading}
            >
              {intl.formatMessage(labels.Label_General_Enter_Button)}
            </GenericButton>
          </Box>
        </form>
      </CardContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(LoginModal);
