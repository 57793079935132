import { makeStyles, Typography } from "@material-ui/core";
import { ConditionGuard } from "guards/ConditionGuard";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  list: {
    padding: 0,
    margin: 0,
    listStyleType: "none",

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    // add scrollbar if content is too long
    maxHeight: "450px",
    overflow: "auto",
  },
  listItem: {
    padding: 0,
    margin: 0,

    "& p": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 4,
    },
  },
}));

const PreviewObservations = ({ observations }) => {
  const classes = useStyles();

  return (
    <ConditionGuard
      condition={observations?.length > 0}
      fallback={
        <Typography
          variant="body1"
          color="textPrimary"
          style={{
            marginBottom: 0,
            marginLeft: "1rem",
          }}
        >
          Sem observações
        </Typography>
      }
    >
      <ul className={classes.list}>
        {observations?.map((observations, index) => (
          <li className={classes.listItem} key={index} title={observations?.comment}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{
                marginBottom: 0,
              }}
            >
              {moment(observations?.commentDate).format("DD/MM/YYYY HH:mm")}
            </Typography>
            <Typography variant="body1" color="textPrimary" style={{ marginLeft: "1rem" }}>
              {observations?.comment}
            </Typography>
          </li>
        ))}
      </ul>
    </ConditionGuard>
  );
};

export default PreviewObservations;
